import React from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import { Box, Typography } from "@material-ui/core";
import { LeftCard } from "../../step1/left/SurveyLeftCard";

import { MdOutlineAccessTime } from "react-icons/md";
import { BiDockLeft } from "react-icons/bi";
import { useSelector } from "react-redux";

const basicDataFirstTab = [
  {
    id: 1,
    mainHead: "Completed Survey Sections",
    subHead: "Survey section",
  },
];

function Step4Left() {
  const { step3Sections, selectedVASurvey } = useSelector(
    (state) => state.vendorReducer
  );

  return (
    <StepLeft>
      <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
        Sections
      </Typography>
      <Box
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "flex-start",
          gap: 10,
        }}
      >
        <MdOutlineAccessTime size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {selectedVASurvey?.frequencyType}
        </Typography>
        <BiDockLeft size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {step3Sections?.sections?.length} Section(s)
        </Typography>
      </Box>
      <LeftCard toMap={basicDataFirstTab} subTabNo={1} />
    </StepLeft>
  );
}

export default Step4Left;
