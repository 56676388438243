import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Box,
  Typography,
  Avatar,
  ListItemAvatar,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { ListItem } from "../left/SurveyList";
import CustomButton from "../../../../../UI/button/button";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_all_stakeholders } from "../../../../../../redux/actions/vendorActions/vendorAction";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    fontSize: 12,
    marginBottom: 6,
    backgroundColor: theme.palette.background.paper,
    "& .Mui-selected": {
      backgroundColor: "#EBF1F8", // Change this to the desired color
      borderRadius: "8px",
      marginBottom: "5px",
      marginTop: "5px",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#EBF1F8", // Change this to the desired color on selected hover
      borderRadius: "8px",
    },
    "& :hover": {
      backgroundColor: "#EBF1F8", // Change this to the desired color on selected hover
      borderRadius: "8px",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "& .Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  selected: {
    backgroundColor: "red", // Change this to the desired color
  },

  checkIcon: {
    fontSize: "22px",
    color: "#afafaf",
  },
}));

function noImage(name) {
  const modifyName = name
    .split(" ")
    .map((el) => el[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();

  return modifyName;
}

function SelectedStakeHolder({
  headingTitle,
  selectList = [],
  handleUser,
  handleDelete,
}) {
  const classes = useStyles();

  return (
    <Box
      style={{
        height: "48%",
        border: "1px solid #E9E9E9",
        borderRadius: "8px",
      }}
    >
      {/* header */}
      <Box
        style={{
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            // width: "20%",
            color: "#505050",
          }}
        >
          {`${headingTitle}(${selectList?.length})`}
        </Typography>
        <CustomButton
          style={{ textTransform: "capitalize" }}
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => handleUser()}
        >
          {`Add Selection to ${headingTitle}`}
        </CustomButton>
      </Box>
      {/* body */}
      <Box style={{ height: "70%" }}>
        <List
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
          }}
          style={{ overflow: "scroll", marginTop: "10px", height: "85%" }}
        >
          {selectList.map((value, index) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem
                key={value?.id}
                role={undefined}
                dense={true}
                button
                selected={value?.isCheck}
                style={{
                  //   border: "1px solid #E9E9E9",
                  //   borderRadius: "8px",
                  //   marginBottom: "10px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${value + 1}`}
                    style={{ backgroundColor: "blueviolet" }}
                    // src={`/static/images/avatar/${value + 1}.jpg`}
                  >
                    {noImage(value.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={value?.name}
                  secondary={value?.email}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(value?._id)}
                  >
                    <RemoveIcon style={{ color: "#3374B9" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}

export default function DrawerStep2({
  listData = [],
  handleToggle,
  handleApprovers,
  handleContributers,
  contributors,
  approvers,
  handleDeleteContributers,
  handleDeleteApprovers,
}) {
  const classes = useStyles();

  const { login } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gql_get_all_stakeholders(login?.companyId));
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        // overflow: "scroll",
        height: "70%",
        marginTop: "10px",
        justifyContent: "space-between",
      }}
    >
      <Box
        style={{
          overflow: "hidden",
          height: "100%",
          marginTop: "10px",
          width: "48%",
        }}
      >
        {/* header */}
        <Box
          style={{
            backgroundColor: "#EBF1F8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 500,
              // width: "20%",
              color: "#505050",
            }}
          >
            Stakeholder Name
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 500,
              // width: "20%",
              color: "#505050",
            }}
          >
            No. Of Section
          </Typography>
        </Box>
        {/* body */}
        <List style={{ overflow: "scroll", marginTop: "10px", height: "85%" }}>
          {listData.map((value, index) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <Box>
                <ListItem
                  key={value?._id}
                  role={undefined}
                  dense={true}
                  button
                  selected={value?.isCheck}
                  style={{
                    border: "1px solid #E9E9E9",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  onClick={() => handleToggle(value?._id)}
                >
                  <ListItemAvatar
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {value?.isCheck && (
                      <CheckIcon className={classes.checkIcon} />
                    )}
                    <Avatar
                      alt={`Avatar n°${value + 1}`}
                      style={{
                        backgroundColor: "blueviolet",
                        marginRight: "15px",
                      }}
                      // src={`/static/images/avatar/${value + 1}.jpg`}
                    >
                      {noImage(value.name)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={value?.name}
                    secondary={value?.email}
                  />
                  <ListItemText
                    style={{ textAlign: "right" }}
                    id={labelId}
                    primary={` ${index + 1} question`}
                  />
                </ListItem>
              </Box>
            );
          })}
        </List>
      </Box>
      <Box
        style={{
          marginTop: "10px",
          width: "48%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <SelectedStakeHolder
          headingTitle={"Contributors"}
          handleUser={handleContributers}
          selectList={contributors}
          handleDelete={handleDeleteContributers}
        />
        <SelectedStakeHolder
          headingTitle={"Approvers"}
          handleUser={handleApprovers}
          selectList={approvers}
          handleDelete={handleDeleteApprovers}
        />
      </Box>
    </Box>
  );
}
