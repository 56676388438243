import React, { useEffect, useRef, useState } from "react";
import RadioButton from "../../UI/radioButton/radioButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { brmRightStyles } from "../riskAssessment/brmDataRight.js/brmDataRight";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import useGeoLocation from "../../../customHooks/getGeoLocation";
import GoogleMaps from "./googleMaps";
import {
  ViewSurveyDrawer,
  ViewSurveySelect,
} from "../controverciesQuestions/controverciesQuestions";
import ListIcon from "@material-ui/icons/List";
import {
  Question,
  Wrapper,
  survey,
} from "../selfAssessment/selfAssessmentResponse";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getQuestion,
//   getSurveySectionQuestion,
// } from "../../../redux/actions/riskdashboard/riskDashboardActions";
import {
  dummyCompanyProfileQuestions,
  dummyInformationSecurityQuestions,
  surveyDummyQuestions,
  surveySections,
  initialData2,
} from "../../../utils/riskUtils/riskUtils";

import { sectionWiseData as defaultData } from "../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";

import clipboard from "../../../images/clipboard.png";
import {
  gql_get_all_stakeholders,
  gql_get_bank_admin_single_survey,
  gql_get_bank_admin_surveys,
} from "../../../redux/actions/vendorActions/vendorAction";
import { SET_SHOW_SELECTED_SURVEY } from "../../../constants/brmConstants";

function SurveyQuestions(props) {
  const {
    getSurveyeQuestions,
    addSurveyeQuestions,
    login,
    brmData,
    setShareDialogOpen,
    corporateSurveyQuestions,
    selectedSurveyData,
    setSelectedSurveyData,
    shareStatus,
  } = props;

  const classes = brmRightStyles();
  const dispatch = useDispatch();
  const { sectionWiseData, selectedSection } = useSelector(
    (state) => state.riskDashboard
  );

  // const { sectionWiseData, selectedSection, sectionWiseQuestion } = useSelector(
  //   (state) => state.riskDashboard
  // );
  // console.log(sectionWiseData)
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  // console.log(questions);
  const [controversiesQuestions, setControversiesQuestions] = useState(
    corporateSurveyQuestions || []
  );
  const [viewListing, setViewListing] = useState(false);
  const [sectionSelect, setSectionSelect] = useState("");

  const [data, setData] = useState({
    type: "",
    question: "",
    options: [["", 0]],
  });
  const [value, setValue] = React.useState({});
  const [selectSurvey, setSurveySelect] = useState("");

  const listSurvey = brmData?.showSurveys;

  const location = useGeoLocation();
  const scroll = useRef();

  const vendorId = brmData?.vendorBasicDetails?._id;

  useEffect(() => {
    setControversiesQuestions(corporateSurveyQuestions);
    dispatch(gql_get_bank_admin_surveys(login?.bankId, vendorId));
  }, []);

  useEffect(() => {
    const surveyVersion = parseFloat(brmData?.showSurveys[0]?.publishedVersion);

    if (brmData?.showSurveys?.length > 0) {
      dispatch(
        gql_get_bank_admin_single_survey(
          brmData?.showSurveys[0]?._id,
          surveyVersion,
          vendorId
        )
      );
    }

    setSurveySelect(brmData?.showSurveys[0]?._id);
  }, [brmData?.showSurveys]);

  // useEffect(() => {}, [selectSurvey]);

  useEffect(() => {
    setSelectedSurveyData(brmData?.showSelectedSurvey);
    const settingSection = brmData?.showSelectedSurvey?.sections?.sections[0];

    setSectionSelect({
      title: settingSection?.title,
      id: settingSection?._id,
      description: settingSection?.description,
    });

    const defaultQuestion =
      brmData?.showSelectedSurvey?.sections?.sections[0]?.questions;

    setQuestions(defaultQuestion);
  }, [brmData?.showSelectedSurvey]);

  // console.log(selectedSurveyData);

  // useEffect(() => {
  //   sectionWiseData.length > 0 &&
  //     dispatch(
  //       getQuestion(
  //         login?.token,
  //         sectionWiseData.find((s) => s.title === selectedSection)?._id
  //       )
  //     );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSection, sectionWiseData.length]);

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [sectionSelect]);
  const handleChange = (event, id) => {
    setValue((preValue) => ({ ...preValue, [id]: event.target.value }));
  };

  const handleAddquestion = () => {
    let question = {
      order: brmData?.surveyQuestions.length + 1,
      category: "custom question",
      type: data.type,
      question: data.question,
      answers: data.options,
    };
    // const copy = [...brmData?.surveyQuestions];
    // copy.push({
    //   order: brmData?.surveyQuestions.length + 1,
    //   category: "",
    //   type: data.type,
    //   question: data.question,
    //   answers: data.options,
    // });
    addSurveyeQuestions(
      login.token,
      brmData?.organizationDetails[0]?._id ||
        localStorage.getItem("organization_id"),
      question
    );
    // setQuestions(copy);
    setIsAddQuestionOpen(false);
    setData({
      type: "",
      question: "",
      options: [["", 0]],
    });
  };

  const addQuestionDialog = () => {
    return (
      <Dialog fullWidth open={isAddQuestionOpen} minWidth="md" maxWidth="md">
        <DialogTitle>
          <Grid
            style={{ display: "flex", justifyContent: "space-between" }}
            container
            justifyContent="center"
            className={classes.mainGrid}
          >
            {/* <Grid item xs={11}> */}
            <Typography style={{ fontWeight: 500 }}>Add Question</Typography>
            {/* </Grid> */}
            {/* <Grid  item xs={1}> */}
            <CloseOutlinedIcon onClick={() => setIsAddQuestionOpen(false)} />
            {/* </Grid> */}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}></Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Type of Question
              </Typography>

              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={data.type}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, type: e.target.value };
                  });
                }}
              >
                {[
                  "radio",
                  "textField",
                  "multi select",
                  "file",
                  "location",
                  "date",
                ].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid4}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Enter Question
              </Typography>
              <CustomTextfield
                name="userName"
                label="Question"
                variant="outlined"
                size="small"
                fullWidth
                value={data.question}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, question: e.target.value };
                  });
                }}
              />
            </Grid>
            {/* {data.type !== "textField" && (
              <Grid item xs={12} md={12} className={classes.grid5}>
                <Typography>option 1</Typography>
                <CustomTextfield
                  name="userRole"
                  label="option"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={data.options}
                  onChange={(e) => {
                    setData((prev, j) => {
                      const opt =[...prev.options]
                      return {
                        ...prev,
                        options: opt.push([e.target.value, j + 1]),
                      };
                    });
                  }}
                />
              </Grid>

            )} */}
            {(!data.type ||
              data.type === "radio" ||
              data.type === "multi select") &&
              data.options.map((option, index) => (
                <Grid item xs={12} md={12} className={classes.grid5}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#9A9A9A",
                    }}
                  >
                    option {`${index + 1}`}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomTextfield
                      name="userRole"
                      label="option"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={option[0]}
                      onChange={(e) => {
                        const copy = { ...data };
                        const copyOptions = [...copy.options];
                        copyOptions[index][0] = e.target.value;
                        setData(copy);
                      }}
                    />
                    <RemoveCircleOutlineOutlinedIcon
                      style={{
                        color: "#3374b9",
                        display: "inline",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const copy = { ...data };
                        copy?.options.splice(index, 1);
                        setData(copy);
                      }}
                    />
                  </div>
                </Grid>
              ))}

            {(!data.type ||
              data.type === "radio" ||
              data.type === "multi select") && (
              <Button
                onClick={() => {
                  const copy = { ...data };
                  copy?.options.push(["", copy.options.length]);
                  setData(copy);
                }}
              >
                add option
              </Button>
            )}

            {data.type === "file" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Select File
                </Typography>
                <CustomTextfield
                  name="userName"
                  variant="outlined"
                  type="file"
                  size="small"
                  fullWidth
                  value={""}
                />
              </Grid>
            )}
            {data.type === "date" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Select File
                </Typography>
                <CustomTextfield
                  name="userName"
                  variant="outlined"
                  type="date"
                  size="small"
                  fullWidth
                  value={""}
                />
              </Grid>
            )}
            {data.type === "location" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Grant permission to access location
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  latitude: {location.coords.latitude}, longitude:{" "}
                  {location.coords.longitude}
                </Typography>
              </Grid>
            )}

            {/* <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography>option 1</Typography>
              <CustomTextfield
                name="userRole"
                label="option"
                variant="outlined"
                size="small"
                fullWidth
                // value={role}
                // onChange={(e) => setRole(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            {/* <Grid item xs={12} md={12} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Grid> */}

            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setIsAddQuestionOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleAddquestion}
                disabled={!data.question}
              >
                Add Question
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const handleListSelect = (id, version) => {
    dispatch(gql_get_bank_admin_single_survey(id, version, vendorId));
  };

  const handleSelectedSection = (id) => {
    const singleSection = selectedSurveyData?.sections?.sections.find(
      (section) => section._id === id
    );

    setQuestions(singleSection?.questions);
    // console.log(singleSection);
  };

  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {isAddQuestionOpen && addQuestionDialog()}
      <ViewSurveySelect
        viewListing={viewListing}
        setViewListing={setViewListing}
        list={listSurvey}
        handleListSelect={handleListSelect}
        surveySelect={selectSurvey}
        setSurveySelect={setSurveySelect}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Survey
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Button
            // variant="outlined"
            size="small"
            // startIcon={<Add />}
            // color="primary"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => setIsAddQuestionOpen(!isAddQuestionOpen)}
          >
            Add Question
          </Button> */}
          <Button
            size="small"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "5px",
            }}
            onClick={() => setViewListing(!viewListing)}
          >
            <img
              src={clipboard}
              alt="docIcon"
              style={{ width: 22, marginRight: 5 }}
            />
            Survey Select
          </Button>
          <Button
            size="small"
            style={{
              color:
                selectedSurveyData?.isShared ||
                shareStatus === selectedSurveyData?._id
                  ? "#999999"
                  : "#3374b9",
              textTransform: "none",
            }}
            disabled={selectedSurveyData?.isShared}
            onClick={() => setShareDialogOpen(true)}
          >
            {/* <Icon style={{ color: "#3374b9", cursor: "pointer" }}> */}
            <ShareOutlinedIcon
              style={{
                marginRight: "5px",
              }}
            />{" "}
            {selectedSurveyData?.isShared ||
            shareStatus === selectedSurveyData?._id
              ? "Already Shared"
              : "Share Survey"}
            {/* </Icon> */}
          </Button>
        </div>
      </div>
      {/* {brmData?.showSurveys.map(
        (singleSurvey) =>
          singleSurvey._id === selectSurvey && (
            <Wrapper
              users={initialData2[selectSurvey]}
              sectionSelect={sectionSelect}
              setSectionSelect={setSectionSelect}
              hidesearch
            >
              <div
                className="scroll"
                ref={scroll}
                style={{ width: "78%", height: "59vh" }}
              >
               
                <>
                  <BlueBackgroundCard
                    heading={sectionSelect}
                    subHeading="Navigate the complexities of corporate controversies with precision through our survey, delving into nuanced perspectives and ensuring a comprehensive understanding of potential environmental, social, and governance challenges."
                  />
                  {questions.map((question) => (
                    <Question
                      question={question.question}
                      options={question.answers}
                      type={question.question_type}
                      weightage={question?.weightage}
                    />
                  ))}
                </>
              </div>
            </Wrapper>
          )
      )} */}
      {selectedSurveyData &&
        selectedSurveyData?.sections?.sections?.length > 0 && (
          <Wrapper
            users={selectedSurveyData?.sections?.sections}
            sectionSelect={sectionSelect}
            setSectionSelect={setSectionSelect}
            hidesearch
            handleSelectedSection={handleSelectedSection}
          >
            <div
              className="scroll"
              ref={scroll}
              style={{ width: "78%", height: "59vh" }}
            >
              <>
                <BlueBackgroundCard
                  heading={sectionSelect?.title}
                  subHeading={sectionSelect?.description}
                />
                {questions?.map((question) => (
                  <Question
                    question={question?.question}
                    options={question?.answers}
                    type={question?.questionType}
                    weightage={question?.weightage}
                    comment={question?.showComment}
                    tableHeadersApi={question?.tableHeaders}
                    isFrom={"publishedSurvey"}
                  />
                ))}
              </>
            </div>
          </Wrapper>
        )}
      <br /> <br />
      {/* </div> */}
    </div>
  );
}

export default SurveyQuestions;
