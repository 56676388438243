import { gql } from "@apollo/client";

// export const UPDATE_USER = gql`
//   mutation UpdateUser(
//     $active: Boolean
//     $designation: String
//     $is_premium: Boolean!
//     $name: String!
//   ) {
//     updateUser(
//       updateUserInput: {
//         active: $active
//         designation: $designation
//         is_premium: $is_premium
//         name: $name
//       }
//     ) {
//       name
//       is_premium
//       designation
//       active
//     }
//   }
// `;
export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserResolverInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      name
      is_premium
      designation
      active
      profileImg {
        name
        type
        url
      }
    }
  }
`;

export const GQL_RESET_PASSWORD = gql`
  mutation ResetPassword($resetPasswordInput: ChangePasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_COMPANY_DETAILS = gql`
  mutation UpdateBank($updateBankInput: UpdateBankInput!) {
    updateBank(updateBankInput: $updateBankInput) {
      _id
      active
      name
      permanentEmployeeCount
      verified
      website
      headOfficeAddress {
        _id
        country
        district
        landmark
        location
        pincode
        state
      }
    }
  }
`;

export const GQL_GET_COMPANY_DETAILS = gql`
  query BankDetails {
    bankDetails {
      _id
      active
      headOfficeAddress {
        _id
        country
        district
        landmark
        location
        pincode
        state
      }
      name
      website
      verified
      permanentEmployeeCount
      logo {
        name
        type
        url
      }
    }
  }
`;

export const GQL_UPDATE_VA_COMPANY_DETAILS = gql`
  mutation UpdateVendor($id: String!, $updateVendorInput: UpdateVendorInput!) {
    updateVendor(id: $id, updateVendorInput: $updateVendorInput) {
      _id
      createdAt
      createdBy
      isin
      employeesCount
      logo {
        name
        type
        url
      }
      name
      updatedAt
      updatedBy
    }
  }
`;

export const GQL_GET_VA_COMPANY_DETAILS = gql`
  query Company($id: String!) {
    company(id: $id) {
      _id
      employeesCount
      name
      operation
      type
      website
      sector {
        sector {
          _id
          sector
        }
        industry {
          industry
          _id
        }
      }
      logo {
        name
        type
        url
      }
      address {
        state
        pincode
        location
        landmark
        country
      }
    }
  }
`;

export const GQL_ADD_USER = gql`
  mutation AddMember($addMemberInput: AddMemberInput!) {
    addMember(addMemberInput: $addMemberInput) {
      message
      success
    }
  }
`;

export const GQL_GET_USERS = gql`
  query Members {
    members {
      _id
      name
      user_type
      email
      profileImg {
        name
        type
        url
      }
    }
  }
`;

export const GQL_REMOVE_USER = gql`
  mutation RemoveMember($userId: String!) {
    removeMember(userId: $userId) {
      message
      success
    }
  }
`;

export const GQL_CHANGE_USER_POSITION = gql`
  mutation ChangeUserType($input: ChangeUserTypeInput!) {
    changeUserType(changeUserTypeInput: $input) {
      message
      success
    }
  }
`;
