import React, { useState } from "react";
import { Box, Icon, IconButton, Typography } from "@material-ui/core";

import StepRight from "../../stepLayout/right/StepRight";
import AuditQuestionTable from "./AufitQuestionTable";
import { NoDataComponent } from "../../step1/right/SurveyStakeHolderList";
import noUser from "../../../../../../images/Profile_Analysis.png";
import NoData from "../../../../NoData/NoData";

function Step3Right({
  selectedSection,
  tabValue,
  sectionQuestions,
  handleWidgetsStatusChange,
}) {
  const [hasExplore, setHasExplore] = useState(false);

  const handleExploreQuestion = (data) => {
    setHasExplore(true);
  };

  return (
    <StepRight>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasExplore && (
            <IconButton size="small">
              <Icon
                style={{ color: "#3374B9" }}
                onClick={() => setHasExplore(false)}
                fontSize="small"
              >
                arrow_back
              </Icon>
            </IconButton>
          )}
          {tabValue === 0 ? "Company Profile" : "Blockchain data record"}
        </Typography>
      </Box>

      {/* tab 0 render data */}

      {tabValue === 0 && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 20,
          }}
        >
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {sectionQuestions?.questions?.length} Questions
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {sectionQuestions?.totalAssignedCount} Questions Assigned
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {sectionQuestions?.totalCompletedCount} Questions Completed
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {sectionQuestions?.overallStatus}
          </Typography>
        </Box>
      )}

      {tabValue === 0 &&
        (!selectedSection ? (
          <NoData message={"Please Select Section"} />
        ) : sectionQuestions?.questions?.length > 0 ? (
          <Box style={{ overflow: "scroll", height: "80%" }}>
            <AuditQuestionTable
              selectedSection={selectedSection}
              sectionQuestions={sectionQuestions?.questions}
              handleExploreQuestion={handleExploreQuestion}
              handleWidgetsStatusChange={handleWidgetsStatusChange}
            />
          </Box>
        ) : (
          <NoData message={"No Available Questions"} />
        ))}

      {/* tab 1 render data */}

      {tabValue === 1 && (
        <NoDataComponent
          handleButtonClick={() => console.log("click")}
          imgSrc={noUser}
          buttonTex={"Freeze this data"}
          mainTitle={
            "Once the Data is Frozen the corresponding Hash Link will appear over here. Each revision will have its own Hash link."
          }
        />
      )}
    </StepRight>
  );
}

export default Step3Right;
