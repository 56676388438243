import React from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import { Box, Typography } from "@material-ui/core";
import { LeftCard } from "./SurveyLeftCard";

import { FaRegCircleUser } from "react-icons/fa6";
import { HiOutlineUsers } from "react-icons/hi";

const basicDataFirstTab = [
  {
    id: 1,
    mainHead: "Stakeholders List",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
];

function Step1Left() {
  return (
    <StepLeft>
      <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
        Add Stakeholder
      </Typography>
      <Box
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "flex-start",
          gap: 10,
          alignItems: "center",
        }}
      >
        <FaRegCircleUser size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          0 Section
        </Typography>
        <HiOutlineUsers  size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          0 Employees
        </Typography>
      </Box>
      <LeftCard toMap={basicDataFirstTab} subTabNo={1} />
    </StepLeft>
  );
}

export default Step1Left;
