import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../../../../UI/button/button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useEffect, useState } from "react";
import { gql_get_room_members } from "../../../../../../../redux/actions/threadActions/ThreadActions";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { useDispatch, useSelector } from "react-redux";

const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "Approved") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  } else if (status === "yet to assign") {
    colorToUse = "#676767";
    bgColorToUse = "#E9E9E9";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 7,
    textTransform: "capitalize",
    padding: "8px 12px 8px 6px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    // gap: "6px",
    borderBottom: "none",
  };
};

const DrawerHeader = ({
  classes,
  selectedQues,
  setIsDrawerOpen,
  contributor,
  setContributor,
  setRoomMembers,
  members,
  roomId,
}) => {
  const { roomMembers } = useSelector((state) => state.threadReducer);
  const { taskQuestions } = useSelector((state) => state.taskReducer);
  const [updatedQuestion, setUpdatedQuestion] = useState({});

  const dispatch = useDispatch();

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleBack = () => {
    setContributor(null);
  };

  useEffect(() => {
    dispatch(gql_get_room_members(roomId));
  }, [roomId]);

  useEffect(() => {
    setUpdatedQuestion(() => {
      return taskQuestions?.find(
        (q) => q?.questionId === selectedQues?.questionId
      );
    });
  }, [taskQuestions]);

  useEffect(() => {
    if (roomMembers && roomMembers.length > 0) {
      // console.log("setting Room Members", roomMembers);
      setRoomMembers(roomMembers);
    }
  }, [roomMembers]);

  // const handleStatus = () => {
  //   return !contributor
  //     ? "In Progress"
  //     : contributor && contributor?.isApproved === false
  //     ? "Awaiting Approval"
  //     : "Approved";
  // };

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerInner}>
          <Box style={{ width: 50 }}>
            <Typography variant="body2" className={classes.headerPrimaryText}>
              Que. No
            </Typography>
            <Typography
              variant="body2"
              className={classes.headerSecondaryText}
              align="center"
            >
              {updatedQuestion?.order}
            </Typography>
          </Box>
          <Box style={{ width: 800 }}>
            <Typography variant="body2" className={classes.headerPrimaryText}>
              Question
            </Typography>
            <Typography variant="body2" className={classes.headerSecondaryText}>
              {updatedQuestion?.question}
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.headerInner}
          style={{ alignItems: "center", gap: "10px" }}
        >
          <Box>
            <Typography variant="body2" className={classes.headerPrimaryText}>
              Status
            </Typography>
            <Box style={statusStyle(updatedQuestion?.status)}>
              <DotIcon
                style={{
                  height: "12px",
                  fontWeight: 500,
                }}
              />
              {updatedQuestion?.status}
            </Box>
          </Box>

          {/* <CustomButton
            color="primary"
            size="small"
            className={classes.smallerButton}
          >
            <MoreVertIcon className={classes.customButtom} />
          </CustomButton> */}

          <CustomButton
            color="primary"
            size="small"
            className={classes.smallerButton}
            onClick={handleCloseDrawer}
          >
            <CloseIcon className={classes.customButtom} />
          </CustomButton>
        </Box>
      </Box>

      {/*-------- SUB-HEADER ---------*/}

      {contributor ? (
        <Box className={classes.subHeading} style={{ gap: "5px" }}>
          <KeyboardArrowLeftIcon
            className={classes.icon}
            onClick={handleBack}
            style={{ cursor: "pointer" }}
          />
          <FormatListBulletedIcon className={classes.icon} />

          <Avatar
            alt={contributor?.sentBy?.name}
            src={contributor?.sentBy?.url}
            className={classes.avatar}
          />
          <Box>
            <Typography variant="h3" className={classes.name}>
              {contributor?.sentBy?.name}
            </Typography>
            <Typography variant="body1" className={classes.message}>
              {contributor?.text}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={classes.subHeading}>
          <Box className={classes.avatarContainer}>
            <AvatarGroup max={3} spacing={9}>
              {members?.map((member, i) => (
                <Avatar
                  key={i}
                  alt={member?.name}
                  src={member?.profileImg?.url}
                  className={classes.avatar}
                />
              ))}
            </AvatarGroup>
          </Box>

          <Box>
            <Typography variant="body2">
              <strong>{members.length} </strong>Members
            </Typography>
          </Box>

          {/* <Box>
            <Box>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "180px" }}
              >
                <InputLabel id="menu" style={{ fontSize: "14px" }}>
                  View All Responses
                </InputLabel>
                <Select
                  name="drop-down"
                  labelId="menu"
                  label="drop-down"
                  style={{ fontSize: "14px" }}
                >
                  <MenuItem value="mongodb">
                    <Typography variant="body2">MongoDB</Typography>
                  </MenuItem>
                  <MenuItem value="node">
                    <Typography variant="body2">Node</Typography>
                  </MenuItem>
                  <MenuItem value="react">
                    <Typography variant="body2">React</Typography>
                  </MenuItem>
                  <MenuItem value="express">
                    <Typography variant="body2">Express</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box> */}
        </Box>
      )}
    </>
  );
};
export default DrawerHeader;
