import React, { useEffect, useState } from "react";
import SelfAssessmentResponse from "../selfAssessment/selfAssessmentResponse";
import ControversyResponse from "../selfAssessment/controversyResponse";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  InputAdornment,
  MenuItem,
  MuiThemeProvider,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { appTheme } from "../riskAssessment/brmDetaails";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import TabPanel from "../../UI/tabPanel/tabPanel";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import { UserCard } from "../riskAssessment/riskUtilComponents";
import CloseIcon from "@material-ui/icons/Close";
import git from "../../../images/git-commit.png";
import calendar from "../../../images/calendar.png";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_completed_questions,
  gql_get_frequencies,
} from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_COMPLETED_QUESTIONS } from "../../../constants/esgDiagnosisConstant";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { toDateYear } from "../../../utils/dateTime/convertTimestamp";

function SurveyResponses({ vendorTabNo }) {
  const classes = appbarStyles();
  const [responseTabNo, setResponseTabNo] = useState(0);
  const [viewVersion, setViewVersion] = useState(false);

  const { bankId } = useSelector((state) => state.login);
  const { showSurveys, vendorBasicDetails } = useSelector(
    (state) => state.brmData
  );

  const { frequencies, completedQuestions } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const [questions, setQuestions] = useState([]);

  const [frequencyId, setFrequencyId] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");

  useEffect(() => {
    if (frequencies && frequencies?.length > 0) {
      setDropdownValue(
        `${toDateYear(frequencies[0]?.startDate)} - ${toDateYear(
          frequencies[0]?.endDate
        )}`
      );
      setFrequencyId(frequencies[0]?._id);
    }
  }, [frequencies]);

  const noOfQuestions = completedQuestions?.reduce(
    (cur, acc) => acc?.questions?.length + cur,
    0
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const survey = showSurveys.find(
      (survey) => survey.name.toLowerCase() === "default survey"
    );
    const surveyResponseFrequenciesInput = {
      bankId,
      companyId: vendorBasicDetails?._id,
      surveyId: survey?._id,
    };

    dispatch(gql_get_frequencies(surveyResponseFrequenciesInput));
  }, []);

  useEffect(() => {
    const survey = showSurveys.find(
      (survey) => survey.name.toLowerCase() === "default survey"
    );
    const getCompletedQuestionsInput = {
      frequencyId: frequencies[0]?._id,
      surveyId: survey?._id,
      bankId,
      companyId: vendorBasicDetails?._id,
    };

    if (frequencies && frequencies?.length > 0) {
      dispatch(gql_get_completed_questions(getCompletedQuestionsInput));
    }

    return () => {
      dispatch({ type: SET_COMPLETED_QUESTIONS, payload: [] });
    };
  }, [frequencies]);

  useEffect(() => {
    if (completedQuestions?.length > 0) {
      setQuestions(completedQuestions[0]?.questions);
    }
  }, [completedQuestions]);

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const handleFrequencyChange = (freqId) => {
    setFrequencyId(freqId);
    // console.log(freqId);
    const survey = showSurveys?.find(
      (survey) => survey?.name?.toLowerCase() === "default survey"
    );
    const getCompletedQuestionsInput = {
      frequencyId: freqId,
      surveyId: survey?._id,
      bankId,
      companyId: vendorBasicDetails?._id,
    };

    if (frequencies?.length > 0) {
      dispatch(gql_get_completed_questions(getCompletedQuestionsInput));
    }
  };

  const handleSelectedSection = (id) => {
    const singleSection = completedQuestions.find(
      (section) => section.sectionId === id
    );

    setQuestions(singleSection?.questions);
  };

  const versionList = (type, name, isCurrent, id) => {
    return (
      <Grid
        key={id}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 10px",
          border: "1px solid #E0E0E0",
          backgroundColor: isCurrent && "#EBF1F8",
          borderRadius: 8,
          margin: "6px 0px",
        }}
      >
        <Grid container md={5}>
          <img src={git} alt="" style={{ height: "20px" }} />
          <Typography
            style={{ fontWeight: 500, paddingLeft: 10, fontSize: 14 }}
          >
            {type}
          </Typography>
        </Grid>
        <Grid container md={4}>
          <img src={calendar} alt="" style={{ height: "20px" }} />
          <Typography
            style={{ fontSize: 14, fontWeight: 400, paddingLeft: 10 }}
          >
            {/* {isCurrent ? "Current Version" : date} */}
            {name}
          </Typography>
        </Grid>
        <Grid md={2} style={{ padding: isCurrent ? 18 : 0 }}>
          {!isCurrent && (
            <Button
              style={{
                textTransform: "none",
                color: "#3374B9",
              }}
              // onClick={() => {
              //   // setViewVersion(!viewVersion);
              //   handleViewFrequency(id);
              // }}
            >
              View
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };
  const version = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => setViewVersion(false)}
        // variant="persistent"
        width="lg"
        open={viewVersion}
        style={{ position: "relative" }}
      >
        <div style={{ width: "550px", position: "relative", padding: 8 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "fit-content",
            }}
          >
            <Typography
              style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
            >
              Frequencies
            </Typography>
            <Typography
              onClick={() => setViewVersion(!viewVersion)}
              style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
            </Typography>
          </div>

          <div className="scroll" style={{ marginTop: "1%", height: "85vh" }}>
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search"
              onChange={(e) => {
                // setSearchCompanyText(e.target.value);
                // onSearchHandler(e);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#3374B9" }} />
                  </InputAdornment>
                ),
              }}
              // value={searchCompanyText}
            />
            {frequencies?.map((list) =>
              versionList(list.type, list.name, list.isCurrent, list?._id)
            )}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 5,
            right: 5,
          }}
        >
          <Button
            style={{
              textTransform: "none",
              color: "#3374B9",
            }}
            onClick={() => setViewVersion(!viewVersion)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#3374B9",
              color: "white",
              padding: "3px 14px",
            }}
            onClick={() => setViewVersion(!viewVersion)}
          >
            Select Version
          </Button>
        </div>
      </Drawer>
    );
  };

  const MakeCard = ({ heading, value, type }) => {
    const key = `${heading}-${value}`;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      <Box
        key={key}
        sx={{
          m: 1,
          width: "100%",
          height: "96px",
          borderRadius: "8px",
          padding: "16px 0px 16px 0px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            marginBottom: 10,
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "21px",
            color: "#15314E",
          }}
        >
          {heading}
        </Typography>
        {type === "status" ? (
          <Box
            sx={{
              padding: "4px 8px 4px 8px",
              borderRadius: "80px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              height: "24px",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: value ? "#154328" : "#8C7038",
              backgroundColor: value ? "#BFE2CD" : "#FFEFD0",
            }}
          >
            <FiberManualRecordIcon
              style={{ width: 8, height: 8, marginRight: 5 }}
            />
            {value ? "Completed" : "Incomplete"}
          </Box>
        ) : type === "common" ? (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        ) : type === "popup-quoter" || type === "popup-monthly" ? (
          <Select
            value={dropdownValue}
            onChange={(e) => {
              handleDropdownChange(e);
            }}
            variant="outlined"
            classes={{ root: classes.rootSelect }}
            style={{
              width: "80%",
              maxHeight: "30px",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#15314E",
              boxShadow: "#C0D4E9",
              backgroundColor: "#FEFEFE",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  padding: "0px 6px",
                },
              },
            }}
          >
            {value?.map((freq) => (
              <MenuItem
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    dropdownValue ===
                    `${toDateYear(freq?.startDate)} - ${toDateYear(
                      freq?.endDate
                    )}`
                      ? "#EBF1F8"
                      : "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                onClick={() => handleFrequencyChange(freq?._id)}
                key={freq?._id}
                value={`${toDateYear(freq?.startDate)} - ${toDateYear(
                  freq?.endDate
                )}`}
              >
                {toDateYear(freq?.startDate)} - {toDateYear(freq?.endDate)}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    );
  };
  return (
    <div style={{ height: "63vh" }}>
      {viewVersion && version()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            {"Default Survey"}
          </Typography>
        </div>
        {/* <div>
          <CustomButton
            color="primary"
            style={{ textTransform: "none", fontSize: 13 }}
            onClick={() => {
              setViewVersion(true);
            }}
          >
            View Frequencies
          </CustomButton> */}
        {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          />
          <ShareOutlinedIcon
            style={{ color: "#3374b9", marginRight: "5px", cursor: "pointer" }}
          /> */}
        {/* </div> */}
      </div>

      <div>
        {/* <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={responseTabNo}
              onChange={(e, newVal) => {
                setResponseTabNo(newVal);
              }}
              // onChange={handleTabChange}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>
                    Self Assessment Survey
                  </span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>
                    Controversies Survey{" "}
                  </span>
                }
              />

              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}> Dimension Chat</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Overall</span>}
              /> 
            </Tabs>
          </AppBar>
        </MuiThemeProvider> */}

        {frequencies?.length > 0 && completedQuestions?.length > 0 && (
          <Box
            sx={{
              m: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MakeCard
              heading="Survey status"
              value={noOfQuestions}
              type={"status"}
            />
            <MakeCard
              heading="No. of Survey Questions"
              value={noOfQuestions}
              type={"common"}
            />
            <MakeCard
              heading={`Frequency: ${frequencies[0]?.type}`}
              value={frequencies}
              type={"popup-monthly"}
            />
          </Box>
        )}

        <TabPanel value={responseTabNo} index={0}>
          <SelfAssessmentResponse
            questions={questions}
            completedQuestions={completedQuestions}
            frequencies={frequencies}
            handleSelectedSection={handleSelectedSection}
          />
        </TabPanel>
      </div>
    </div>
  );
}

export default SurveyResponses;
