import { CardContent, Paper, Typography } from "@material-ui/core";
import React from "react";
import RadarChart from "../../../UI/radarChart/radarChart";

function DimentionCharts({ scores }) {
  const environment = scores && scores?.categories?.["Environment"];
  const governance = scores && scores?.categories?.["Leadership & Governance"];
  const social = scores && scores?.categories?.["Social Capital"];
  const human = scores && scores?.categories?.["Human Capital"];
  const business =
    scores && scores?.categories?.["Business Model & Innovation"];

  return (
    <div>
      {scores ? (
        <div>
          {/* // <div className="scroll" style={{ height: "61vh" }}> */}
          <Paper>
            <RadarChart
              lab="OverAll"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Environment",
                "Governance",
                "Social",
                "Business",
                "Human Capital",
              ]}
              chartEsgData={[
                environment || 0,
                governance || 0,
                social || 0,
                business || 0,
                human || 0,
              ]}
              // {...props}
              InputLabels
            />
          </Paper>
          {/* {!isProfile && (
            <>
              <Paper>
                <RadarChart
                  lab="Environment"
                  backgroundColor="#3374b933"
                  borderColor="#3374b9"
                  labels={[
                    "Energy",
                    "Water",
                    "Waste",
                    "Analytic CO2",
                    "Green revenues",
                  ]}
                  chartEsgData={Object.values(environment?.data_points) || []}
                  {...props}
                />
              </Paper>
              <Paper>
                <RadarChart
                  lab="Social"
                  backgroundColor="#3374b933"
                  borderColor="#3374b9"
                  labels={[
                    "Diversity and Inclusion",
                    "Human Rights",
                    "Career development and training",
                    "Health and Safety",
                    "Data privacy",
                    "Responsible Marketing",
                  ]}
                  chartEsgData={Object.values(social?.data_points) || []}
                  {...props}
                />
              </Paper>
              <Paper>
                <RadarChart
                  lab="Governance"
                  labels={["Structure", "Compensation", ""]}
                  backgroundColor="#3374b933"
                  borderColor="#3374b9"
                  chartEsgData={Object.values(governance?.data_points) || []}
                  {...props}
                />
              </Paper>
            </>
          )} */}
        </div>
      ) : (
        <div className="scroll" style={{ height: "61vh" }}>
          <Paper>
            <RadarChart
              lab="OverAll"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={["Shareholders", "Management", "Workforce"]}
              chartEsgData={[0, 0, 0]}
              // {...props}
            />
          </Paper>
          {/* <Paper>
            <RadarChart
              lab="Environment"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Energy",
                "Water",
                "Waste",
                "Analytic CO2",
                "Green revenues",
              ]}
              chartEsgData={[]}
              // {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Social"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Diversity and Inclusion",
                "Human Rights",
                "Career development and training",
                "Health and Safety",
                "Data privacy",
                "Responsible Marketing",
              ]}
              chartEsgData={[]}
              // {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Governance"
              labels={["Structure", "Compensation", ""]}
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              chartEsgData={[]}
              // {...props}
            />
          </Paper> */}
        </div>
      )}
    </div>
  );
}

export default DimentionCharts;
