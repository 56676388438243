import { gql } from "@apollo/client";

// export const GQL_GET_VENDOR_RISK = gql`
//   query GetVendorRisk($vendorId: String!) {
//     getVendorRisk(vendorId: $vendorId) {
//       comment
//       risk
//     }
//   }
// `;

export const GQL_GET_ESG_RISK = gql`
  query GetEsgRisk($companyId: String!) {
    getEsgRisk(companyId: $companyId) {
      comment
      risk
    }
  }
`;

// export const GQL_UPDATE_VENDOR_RISK = gql`
//   mutation SetVendorRisk($comment: String, $risk: String, $companyId: String!) {
//     setVendorRisk(comment: $comment, risk: $risk, companyId: $companyId)
//   }
// `;

export const GQL_UPDATE_ESG_RISK = gql`
  mutation SetEsgRisk($comment: String, $risk: String, $companyId: String!) {
    setEsgRisk(comment: $comment, risk: $risk, companyId: $companyId)
  }
`;
