import React, { useEffect } from "react";
import { Grid, IconButton, Typography, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import leaf from "../../../images/Icon.png";
import InsigntsIcon from "../../../images/insights.svg";
import network from "../../../images/Icon-1.png";
import piece from "../../../images/Icon-3.png";
import document from "../../../images/Document.png";
import AlertTriangle from "../../../images/alert_red.png";
import Check from "../../../images/check_green.png";
import ArrowUp from "../../../images/improve_blue.png";
import {
  dummyInsights,
  financialInsights,
  getColoredLogo,
  improvementAreas,
  riskArea,
  strongAreas,
} from "../../../utils/riskUtils/riskUtils";
import { EsgInsightCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyInsights } from "../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import {
  deleteInsightWidget,
  editInsightWidget,
} from "../../../redux/actions/riskdashboard/riskDashboardActions";
import EditInsightWidget from "./EditInsightWidget";
import { rest_get_esg_insights } from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import NoData from "../NoData/NoData";

export const OverallRiskCard = ({
  topic,
  heading,
  description,
  gpt = false,
  arr,
  insight,
  handleDeleteWidget,
  area,
  login,
  handleOpenEditInsight,
}) => {
  return (
    // <Grid item xs={4}>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        margin: "30px 0px",
      }}
    >
      {insight &&
        insight.map(({ content, heading, logo, _id, dontShowIcon }) => {
          // const { content, heading, logo, _id } = value.content

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",

                // justifyContent: "space-between",
                // border:"solid",
                width: gpt ? "32%" : "48%",
                // boxShadow: "#DFDFE6 0px 0px 3px",
                borderRadius: 8,
              }}
              key={_id}
            >
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px",
                  gap: "20px",
                }}
              >
                {logo ? (
                  <img
                    style={{
                      width: 45,
                      height: 45,
                    }}
                    src={logo}
                    alt="logo"
                  />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                      borderBottom: "1px solid #EBF1F8",
                    }}
                  >
                    {!dontShowIcon && (
                      <img
                        style={{
                          width: 45,
                          height: 45,
                        }}
                        src={InsigntsIcon}
                        alt="logo"
                      />
                    )}
                    {heading && (
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        {heading}
                      </Typography>
                    )}
                  </Box>
                )}
                {login?.user_type === "RA" && (
                  <IconButton
                    onClick={() =>
                      handleOpenEditInsight(heading, content, logo, _id, area)
                    }
                    style={{ cursor: "pointer", marginLeft: "auto" }}
                  >
                    <EditIcon color="action" />
                  </IconButton>
                )}
                {login?.user_type === "RA" && (
                  <IconButton
                    onClick={() => handleDeleteWidget(area, _id)}
                    style={{ cursor: "pointer" }}
                  >
                    <DeleteIcon color="action" />
                  </IconButton>
                )}
              </div>
              <div style={{ marginTop: "5px", padding: "0px 5px 6px 8px" }}>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontWeight: 400,
                    fontSize: "13px",
                  }}
                >
                  {content}
                </Typography>
              </div>
            </div>
          );
        })}
    </div>

    // </Grid>
  );
};

function OverallProfile(props) {
  const {
    riskEvaluator,
    getCompanyInsights,
    brmData,
    setProfileTabNo,
    login,
    location,
  } = props;

  const { esgInsights } = useSelector((state) => state.esgDiagnosisReducer);
  const dispatch = useDispatch();
  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { token } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(rest_get_esg_insights(vendorBasicDetails?.isin, token));
  }, []);

  // const {} = riskEvaluator?.overallData;

  const [openEditInsight, setOpenEditInsight] = React.useState(false);

  const [selectedWidget, setSelectedWidget] = React.useState({});

  const [selectedArea, setSelectedArea] = React.useState("");

  const handleOpenEditInsight = (heading, content, logo, _id, area) => {
    setSelectedWidget({ heading, content, logo, _id });
    setSelectedArea(area);
    setOpenEditInsight(true);
  };

  const handleCloseEditInsight = () => {
    setOpenEditInsight(false);
    setSelectedWidget({});
  };

  const handleUpdateEditInsight = (updatedInsight, insightLogo) => {
    // dispatch(
    //   editInsightWidget(
    //     login?.token,
    //     riskEvaluator?.overallData?._id,
    //     updatedInsight,
    //     selectedArea,
    //     location?.state?.isin,
    //     location?.state?.year,
    //     insightLogo
    //   )
    // );

    setOpenEditInsight(false);
    setSelectedWidget({});
  };

  useEffect(() => {
    dispatch(setCompanyInsights(dummyInsights));
    // getCompanyInsights(
    //   login.token,
    //   brmData?.organizationDetails[0]?.isin || location?.state?.isin,
    //   brmData?.organizationDetails[0]?.year_of_assessment ||
    //     location?.state?.year
    // );
    return () => {
      setProfileTabNo && setProfileTabNo(0);
      dispatch(setCompanyInsights([]));
    };
  }, [brmData?.organizationDetails]);

  // return this when insight process is in progress

  if (Array.isArray(riskEvaluator.overallData)) {
    return (
      <div style={{ textAlign: "center", padding: "100px" }}>
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Please click on generate insights
        </Typography>
      </div>
    );
  } else if (!riskEvaluator?.overallData?.is_proccess_completed) {
    return (
      <div style={{ textAlign: "center", padding: "100px" }}>
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          The process is in progress
        </Typography>
      </div>
    );
  }

  const FinancialInsightsCard = ({ heading, description, year }) => {
    return (
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img style={{ width: "80px" }} src={document} alt="logo" />
          </div>
          <div style={{ marginTop: "5px" }}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              {`${heading}  ${year}`}
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: "13px" }}>
              {description}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  const userType = localStorage.getItem("user_type");

  // handel delete insight widget
  const handleDeleteWidget = (areaObject, selectedProperty) => {
    // const selectedAreaObject = riskEvaluator?.overallData?.[areaObject];
    // const removeSelectedInsight = selectedAreaObject.filter(({ _id }) => _id !== selectedProperty);

    dispatch(
      deleteInsightWidget(
        login?.token,
        riskEvaluator?.overallData?._id,
        selectedProperty,
        areaObject,
        location?.state?.isin,
        location?.state?.year
      )
    );
  };

  return (
    <>
      {esgInsights ? (
        <div
          className="scroll"
          style={{
            height: userType === "RA" ? "80vh" : "56vh",
            paddingBottom: 24,
          }}
        >
          {false && (
            <div>
              <div>
                <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                  ESG Risk Area
                </Typography>
                <Typography
                  style={{ fontWeight: 400, fontSize: "14px", paddingTop: 8 }}
                >
                  These are the area of risks & identification of emerging ESG
                  risks and potential regulatory changes. With impact percentage
                  total of
                </Typography>
              </div>
              {esgInsights?.["weak_areas_summary"] && (
                <OverallRiskCard
                  insight={esgInsights && esgInsights?.weak_areas_summary}
                  handleDeleteWidget={handleDeleteWidget}
                  area={"risk_areas_summary"}
                  login={login}
                  handleOpenEditInsight={handleOpenEditInsight}
                />
              )}
            </div>
          )}
          <div style={{ marginTop: 15 }}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  borderBottom: "1px solid #EBF1F8",
                  paddingBottom: "20px",
                }}
              >
                <img src={AlertTriangle} alt="esg_risk_areas" />

                <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                  ESG Risk Areas
                </Typography>
              </div>
              <Typography
                style={{ fontWeight: 400, fontSize: "14px", paddingTop: 8 }}
              >
                These are the area of risks & identification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>
            {esgInsights?.["weak_areas_summary"] && (
              <OverallRiskCard
                insight={esgInsights && esgInsights?.weak_areas_summary}
                handleDeleteWidget={handleDeleteWidget}
                area={"weak_areas_summary"}
                login={login}
                handleOpenEditInsight={handleOpenEditInsight}
              />
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <img src={Check} alt="strong_areas_summary" />

                <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                  Strong Areas
                </Typography>
              </div>
              <Typography
                style={{ fontWeight: 400, fontSize: "14px", paddingTop: 8 }}
              >
                These are the area of Strong & identification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>
            {esgInsights?.["strong_areas_summary"] && (
              <OverallRiskCard
                insight={esgInsights && esgInsights?.strong_areas_summary}
                handleDeleteWidget={handleDeleteWidget}
                area={"strong_areas_summary"}
                login={login}
                handleOpenEditInsight={handleOpenEditInsight}
              />
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <img src={ArrowUp} alt="improvement_areas_summary" />

                <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                  Improvement Areas
                </Typography>
              </div>
              <Typography
                style={{ fontWeight: 400, fontSize: "14px", paddingTop: 8 }}
              >
                These are the area of risk & identification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>
            {esgInsights?.["improvement_areas_summary"] && (
              <OverallRiskCard
                insight={esgInsights && esgInsights?.improvement_areas_summary}
                handleDeleteWidget={handleDeleteWidget}
                area={"improvement_areas_summary"}
                login={login}
                handleOpenEditInsight={handleOpenEditInsight}
              />
            )}
          </div>
          {false && (
            <div style={{ marginTop: 15 }}>
              <div>
                <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                  Financial/Repayment Insights
                </Typography>
              </div>

              <Grid
                md={12}
                sm={12}
                style={{ display: "flex", spacing: 2, marginTop: 10 }}
                container
              >
                <Grid container spacing={1}>
                  {financialInsights.map((topic, index) => (
                    <FinancialInsightsCard
                      heading={topic.heading}
                      description={topic.description}
                      year={
                        (riskEvaluator?.overallData &&
                          riskEvaluator?.overallData.Year) ||
                        0
                      }
                      key={index}
                    />
                  ))}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <NoData message={"No Insights Found"} />
      )}

      {openEditInsight && (
        <EditInsightWidget
          handleUpdateEditInsight={handleUpdateEditInsight}
          selectedWidget={selectedWidget}
          openEditInsight={openEditInsight}
          handleCloseEditInsight={handleCloseEditInsight}
        />
      )}
    </>
  );
}

export default OverallProfile;
