import React, { useEffect } from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import ControversyChart from "./ChartSection";
import InterestMap from "./MapSection";
import { useDispatch, useSelector } from "react-redux";
import { rest_get_contro_insights } from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { Box, Typography } from "@material-ui/core";

export default function Insights() {
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);
  const { controInsights } = useSelector((state) => state?.esgDiagnosisReducer);
  const dispatch = useDispatch();

  //   console.log(controInsights);

  useEffect(() => {
    dispatch(rest_get_contro_insights(vendorBasicDetails?.isin));
  }, []);

  return (
    <div>
      {/* chart section */}
      <BlueBackgroundCard heading={"Controversy over Time"} />
      {controInsights?.final_dates?.length > 0 ? (
        <ControversyChart finalData={controInsights?.final_dates} />
      ) : (
        <Box
          style={{
            height: "300px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>No data found</Typography>
        </Box>
      )}

      {/* map section */}
      <BlueBackgroundCard heading={"Interest by Region"} />
      {controInsights?.countries?.length > 0 ? (
        <InterestMap countries={controInsights?.countries} />
      ) : (
        <Box
          style={{
            height: "300px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>No data found</Typography>
        </Box>
      )}
    </div>
  );
}
