import {
  IS_APPROVED,
  RECEIVE_MESSAGE,
  ROOM_MEMBERS,
} from "../../../constants/threadConstants";
import {
  APPROVED_ANSWER,
  GET_ROOM_MEMBERS,
  GET_THREAD_MESSAGES,
  UNAPPROVED_ANSWER,
} from "../../graphql/gql_SocketQueries";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";

const setReceivedMessages = (messages) => {
  return { type: RECEIVE_MESSAGE, payload: messages };
};

export function getReceivedMessages(roomId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const objQuery = {
        query: GET_THREAD_MESSAGES,
        variables: { roomId },
      };

      const result = await client.query(objQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(objQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      console.log(result);
      dispatch(setReceivedMessages(result.data.messages));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_approve_answer(approvedId, submissionId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const objQuery = {
        mutation: APPROVED_ANSWER,
        variables: { approvedId, submissionId },
      };

      const result = await client.mutate(objQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(objQuery, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: IS_APPROVED,
        payload: result?.data?.approveAnswer?.message,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_unapprove_answer(submissionId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const objQuery = {
        mutation: UNAPPROVED_ANSWER,
        variables: { submissionId },
      };

      const result = await client.mutate(objQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(objQuery, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: IS_APPROVED,
        payload: result?.data?.unApproveAnswer?.message,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

const setRoomMembers = (members) => {
  return { type: ROOM_MEMBERS, payload: members };
};

export function gql_get_room_members(roomId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const objQuery = {
        query: GET_ROOM_MEMBERS,
        variables: { roomId },
      };

      const result = await client.query(objQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(objQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);
      dispatch(setRoomMembers(result.data.roomMembers));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}
