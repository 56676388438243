import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CustomButton from "../../../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  getReceivedMessages,
  gql_approve_answer,
  gql_unapprove_answer,
} from "../../../../../../../redux/actions/threadActions/ThreadActions";
import { IS_APPROVED } from "../../../../../../../constants/threadConstants";

const useStyles = makeStyles((theme) => ({
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },
  cardContainerDefault: {
    width: "100%",
    height: "100px",
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },

  cardTypography: {
    fontWeight: "600",
  },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { display: "flex", justifyContent: "flex-end" },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12, textTransform: "none" },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typographyshare: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid8: { width: "100%" },
  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    //   padding: theme.spacing(1),
    //   color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  onListHover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },

  name: {
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },

  message: {
    fontSize: "12px",
  },

  icon: {
    color: "#3374B9",
    // marginRight: "10px",
  },

  subHeading: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "10px 0",
  },
}));

function ApproveDialog({
  isOpenDialog,
  setIsOpenDialog,
  contributor,
  selectedQues,
  setContributor,
  socket,
  socketData,
  roomId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleApprovedAnswer = async () => {
    if (contributor?.isApproved === false) {
      await dispatch(
        gql_approve_answer(socketData?.userId, contributor?.next?.submissionId)
      );
      socket.emit("approveVersion", socketData);

      dispatch({ type: IS_APPROVED, payload: true });
    } else {
      await dispatch(gql_unapprove_answer(contributor?.next?.submissionId));
      socket.emit("unApproveVersion", socketData);
      await dispatch({ type: IS_APPROVED, payload: false });
    }

    await dispatch(getReceivedMessages(roomId));

    setContributor(null);

    setIsOpenDialog(false);
  };

  return (
    <Dialog open={isOpenDialog}>
      <DialogTitle>
        <Grid container justifyContent="center" className={classes.mainGrid}>
          <Grid item>
            <Typography className={classes.Typography3}>
              Approve This Contribution
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box className={classes.subHeading}>
          <FormatListBulletedIcon className={classes.icon} />
          <Avatar
            alt={contributor?.sentBy?.name}
            src={contributor?.sentBy?.url}
            className={classes.avatar}
          />
          <Box>
            <Typography variant="h3" className={classes.name}>
              {contributor?.sentBy?.name}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.subHeading}>
          <Box>
            <Typography variant="h3" className={classes.name}>
              {`Are you sure you want to ${
                contributor?.isApproved === true ? "unapprove" : "approve"
              } 
              ${contributor?.sentBy?.name} contribution to Question
              ${selectedQues?.order} ?`}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2} className={classes.grid7}>
          <Grid item>
            <CustomButton
              name="cancelBtn"
              color="primary"
              variant="outlined"
              onClick={() => setIsOpenDialog(false)}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              name="addNewBtn"
              color="primary"
              variant="contained"
              className={classes.CustomButton3}
              onClick={handleApprovedAnswer}
              style={{ whiteSpace: "nowrap", padding: "5px 110px" }}
            >
              {contributor?.isApproved === true
                ? "CONTINUE TO UNAPPROVE"
                : "CONTINUE TO APPROVE"}
            </CustomButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ApproveDialog;
