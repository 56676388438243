import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { RiListCheck3 } from "react-icons/ri";

import SearchTextField from "../../UI/textfield/searchTextField";
import DynamicPagination from "../../UI/pagination/DynamicPagination";

const TaskHeader = () => {
  const handleSearchClick = (value) => {
    console.log(value);
  };

  const handlePageChange = (page) => {
    console.log(page);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {/* My Task Text */}
      <Typography
        style={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#15314E",
        }}
      >
        My Task
      </Typography>

      {/* Search Field */}
      <Box display={"flex"} sx={{ gap: 20 }} alignItems={"center"}>
        <SearchTextField searchClick={handleSearchClick} />

        {/* Pagination */}
        <DynamicPagination totalCount={20} onPageChange={handlePageChange} />
      </Box>
    </Box>
  );
};

export default TaskHeader;
