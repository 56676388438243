import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import CustomButton from "../../../../../../UI/button/button";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { sendMessage } from "../../../../../../../redux/actions/threadActions/ThreadActions";
import ApproveDialog from "./ApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 0),
    gap: "15px",
    marginTop: "auto",
  },

  outlinedInput: {
    "&$focused $notchedOutline": {
      borderColor: "#3374B9 !important",
    },
  },
  focused: {},
  notchedOutline: {},
}));

const DrawerFooter = ({
  socket,
  userId,
  roomId,
  contributor,
  setContributor,
  selectedQues,
}) => {
  const classes = useStyles();

  const [messageInput, setMessageInput] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(null);

  const socketData = {
    userId,
    roomId,
    submitMsgId: contributor?._id,
    prev: contributor?.prev,
  };

  const handleMessage = () => {
    // dispatch(sendMessage(messageInput));
    console.log(socket);
    if (messageInput.trim() !== "") {
      socket.emit("message", { userId, roomId, message: messageInput });
    }
    setMessageInput("");
  };

  return (
    <Box className={classes.root}>
      <TextField
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        className={classes.inputField}
        placeholder="Message from this disclosuer"
        variant="outlined"
        multiline
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="attach file" size="small">
                <AttachFileIcon />
              </IconButton>
            </InputAdornment>
          ),
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          },
        }}
      />

      {/* <IconButton aria-label="send">
        <SendIcon />
      </IconButton> */}
      <CustomButton
        color="primary"
        size="small"
        onClick={handleMessage}
        style={{ whiteSpace: "nowrap", padding: "5px 30px" }}
        disabled={messageInput === ""}
      >
        {contributor ? "Send Comment" : "Send"}
      </CustomButton>

      {contributor && (
        <>
          <CustomButton
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setIsOpenDialog(true)}
            style={{ whiteSpace: "nowrap", padding: "5px 30px" }}
            disabled={selectedQues?.markedAsComplete}
          >
            {contributor?.isApproved === true
              ? "Unapprove Version"
              : "Approve Version"}
          </CustomButton>

          {isOpenDialog && (
            <ApproveDialog
              socketData={socketData}
              contributor={contributor}
              selectedQues={selectedQues}
              setIsOpenDialog={setIsOpenDialog}
              isOpenDialog={isOpenDialog}
              setContributor={setContributor}
              socket={socket}
              roomId={roomId}
            />
          )}
        </>
      )}
    </Box>
  );
};
export default DrawerFooter;
