import {
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";

// icons
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// redux imports
import { useDispatch, useSelector } from "react-redux";

import { useStyles } from "../../QuestionTabComponents/SectionList";
import CustomButton from "../../../../../../../UI/button/button";
import {
  gql_add_new_section,
  gql_edit_survey_section_settings,
  gql_get_survey_sections,
  gql_remove_survey_section,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import { useEffect, useRef, useState } from "react";
import LeftSectionDrawer from "./LeftSectionDrawer";
import EditPopperMenu from "./EditPopperMenu";
const LeftSection = ({ selectedSection, setSelectedSection }) => {
  const classes = useStyles();
  const [editPopper, setEditPopper] = useState(null);
  const [OpenDrawer, setOpenDrawer] = useState(false);
  const [sectionValue, setSectionValue] = useState({});
  const [editName, setEditName] = useState(null);
  const [title, setTitle] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (!editName && inputRef.current) {
      // Focus on the input element when showInput is true
      inputRef.current.focus();
    }
  }, [editName]);

  // from redux
  const dispatch = useDispatch();
  const { selectedSurveyData, sectionWiseData } = useSelector(
    (state) => state.riskDashboard
  );

  // ---------- Inner Functions ----------
  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  const handleCreateSection = async () => {
    // dummy question data for creating new section
    const createQuestionInput = {
      answers: [
        { option: "Very dissatisfied", weightage: 1 },
        { option: "Dissatisfied", weightage: 2 },
        { option: "Somewhat dissatisfied", weightage: 3 },
        { option: "Neither satisfied nor dissatisfied", weightage: 4 },
        { option: "Somewhat satisfied", weightage: 5 },
        { option: "Satisfied", weightage: 6 },
        { option: "Very satisfied", weightage: 7 },
      ],
      order: 1,
      question: "Please provide a question",
      questionType: "SATISFACTION",
      sectionId: null, // setting sectionId as null would create new section in backend
      version: selectedSurveyData?.latestVersion,
      weightage: 2,
    };

    await dispatch(
      gql_add_new_section(
        createQuestionInput,
        selectedSurveyData?._id,
        setSelectedSection
      )
    );
  };

  const handleRemoveSection = (secId) => {
    dispatch(gql_remove_survey_section(secId, selectedSurveyData?._id));
    setEditPopper(null);
  };

  // Handle Edit Section Settings (Title, Description...)

  function handleClick(e) {
    setEditPopper(e.currentTarget);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      dispatch(gql_edit_survey_section_settings(selectedSection?._id, title));
      dispatch(gql_get_survey_sections(selectedSurveyData?._id));
      setEditName(null);
    }
  }

  // function handleEditTitle() {}

  function handleEditName(id) {
    setEditName(id);
    setEditPopper(null);
  }

  function handleCloseEdit() {
    setEditPopper(null);
  }

  const handleDrawer = (value) => {
    setOpenDrawer(value);
    setSectionValue({});
    setEditPopper(null);
  };

  // -------------------------------------

  return (
    <Grid
      item
      md={2}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "90%",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {/* List Mapping */}
      <List
        disablePadding
        classes={{
          root: classes.root,
        }}
        component="nav"
        aria-label="main mailbox folders"
        style={{ position: "relative" }}
      >
        {sectionWiseData?.map((section) => (
          <ListItem
            key={section?._id}
            button
            selected={section?._id === selectedSection?._id}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
            onClick={() => handleSelectSection(section)}
          >
            {editName === section?._id ? (
              <FormControlLabel
                control={
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Rename"
                    defaultValue={section?.title}
                    variant="outlined"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() => setEditName(null)}
                    ref={inputRef}
                  />
                }
                // label="Make Section Mandatory"
              />
            ) : (
              <ListItemText
                primary={
                  <Typography
                    component="div"
                    variant="body1"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // width: "85%",
                      wordWrap: "break-word",
                    }}
                  >
                    {section?.title}

                    {!section?.default &&
                      selectedSection?._id === section?._id && (
                        <IconButton
                          edge="end"
                          aria-label="more"
                          disableRipple
                          // disabled={selectedSection?._id !== section?._id}
                        >
                          <MoreVertIcon
                            style={{ fontSize: "16px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClick(e);
                            }}
                          />
                        </IconButton>
                      )}
                  </Typography>
                }
                primaryTypographyProps={{
                  style: { width: "100px", whiteSpace: "pre-wrap" },
                }}
              />
            )}
          </ListItem>
        ))}

        {/* Add Section Button */}
        {/* {selectedSection && !selectedSection?.default && ( */}
        <CustomButton
          style={{
            textTransform: "none",
            marginRight: "auto",
            marginLeft: "auto",
            width: "100%",
            borderRadius: "8px",
          }}
          size="small"
          color="primary"
          variant="text"
          onClick={handleCreateSection}
        >
          <AddIcon fontSize={"10px"} />
          <Typography>Add Section</Typography>
        </CustomButton>
        {/* )} */}

        {editPopper && (
          <EditPopperMenu
            anchorEl={editPopper}
            handleClose={handleCloseEdit}
            handleClick={handleDrawer}
            handleEditName={(id) => handleEditName(id)}
            handleRemoveSection={handleRemoveSection}
            sectionId={selectedSection?._id}
          />
        )}
        {OpenDrawer && (
          <LeftSectionDrawer
            OpenDrawer={OpenDrawer}
            handleDrawer={handleDrawer}
            setSectionValue={setSectionValue}
            sectionValue={sectionValue}
            selectedSection={selectedSection}
            surveyId={selectedSurveyData?._id}
          />
        )}
      </List>
    </Grid>
  );
};
export default LeftSection;
