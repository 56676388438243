import { Avatar, Box, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { formattedDate } from "./DateFormatter";

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0.5),
    margin: "20px 0",
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  name: {
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },

  message: {
    fontSize: "12px",
  },

  chip: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: "13px",
    fontWeight: 500,
  },
  time: {
    fontSize: "12px",
    fontWeight: "400",
    marginLeft: "10px",
    color: "#afafaf",
  },
}));

const MessageBox = ({ data, id, userId }) => {
  const classes = useStyles();

  const userName = userId === data?.sentBy?._id ? "You" : data?.sentBy?.name;

  return (
    <Box key={id} className={classes.messageContainer}>
      <Avatar
        alt={data?.sentBy?.name}
        src={data?.sentBy?.profileImg?.url}
        className={classes.avatar}
      />
      <Box>
        <Typography variant="h3" className={classes.name}>
          {userName}
          <span className={classes.time}>{formattedDate(data?.sentAt)}</span>
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {data.text}
        </Typography>
      </Box>
    </Box>
  );
};
export default MessageBox;
