import React, { useEffect, useRef, useState } from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";
import CustomTextfield from "../../UI/textfield/textfield";

import DataBreakdown from "../riskAssessment/DataBreakdown";
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";

import CalendarIcon from "@material-ui/icons/DateRange";

import Analytics from "../../../images/Analytics.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomButton from "../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { UserCard } from "../riskAssessment/riskUtilComponents";
import { appTheme } from "../riskAssessment/brmDetaails";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import YearRangePicker from "./documentsElements/YearRangePicker";
import { rest_get_company_uploaded_files_in_survey } from "../../../redux/actions/vendorActions/vendorAction";
import PublicReports from "./publicReports";

const docStyles = makeStyles((theme) => ({
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },

  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },

  endButton: {
    position: "fixed",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    textTransform: "capitalize",
    marginRight: "5px",
    fontSize: "14px",
    padding: 10,
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374D9",
      boxShadow: "1px 1px 3px #3374B9",
    },
  },
}));

function SurveyDocuments() {
  const { uploadedFilesInSurvey } = useSelector((state) => state.vendorReducer);
  const { token } = useSelector((state) => state.login);

  const { vendorBasicDetails } = useSelector((state) => state.brmData);

  const classes = docStyles();

  const dispatch = useDispatch();

  const [reportsTab, setReportsTab] = useState(0);

  useEffect(() => {
    dispatch(
      rest_get_company_uploaded_files_in_survey(vendorBasicDetails?._id)
    );
  }, []);

  return (
    <div style={{ height: "63vh" }}>
      {uploadedFilesInSurvey && uploadedFilesInSurvey.length > 0 ? (
        <>
          <TabPanel value={reportsTab} index={0}>
            <PublicReports publicDocuments={uploadedFilesInSurvey} />
          </TabPanel>
        </>
      ) : (
        <div className={classes.emptyCont}>
          <img className={classes.emptyImg} src={Analytics} alt="analytics" />
          <Typography className={classes.text}>
            Currently No File has Been Uploaded To Showcase
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SurveyDocuments;
