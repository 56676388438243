import React, { useEffect, useState } from "react";
import { Box, TableRow, TableCell, IconButton } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DynamicTable from "../../../../../../UI/dynamicTable/DynamicTable";
import ThreadDrawer from "../../../../../vendorAdmin/surveySteps/step2/threadDrawer/ThreadDrawer";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_task_questions_for_approver } from "../../../../../../../redux/actions/taskActions/TaskAction";

const textStyle = (type) => {
  let width = "2em";
  if (type === "main") {
    width = "50em";
  }

  return {
    color: "#15314E",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    width: width,
    borderBottom: "none",
    padding: "10px",
  };
};

const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "Approved") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  } else if (status === "yet to assign") {
    colorToUse = "#676767";
    bgColorToUse = "#E9E9E9";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    // gap: "6px",
    borderBottom: "none",
  };
};
function ApproverQuestions({ taskQuestions, frequencyId, activeSectionId }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const dispatch = useDispatch();

  const handleClick = (rowData) => {
    setIsDrawerOpen(true);
    setSelectedQuestion(rowData);
  };

  const handleCloseDrawer = () => {
    // dispatch(gql_get_task_questions_for_approver(frequencyId, activeSectionId));
    setIsDrawerOpen(false);
  };

  const headings = [
    { name: "Que.No", showSort: false },
    { name: "Questions", showSort: false },
    { name: "", showSort: false },
    { name: "", showSort: false },
    { name: "", showSort: false },
    { name: "Status", showSort: false },
    { name: "Explore", showSort: false },
  ];
  return (
    <>
      {taskQuestions?.length > 0 && (
        <DynamicTable headings={headings}>
          {taskQuestions?.map((rowData, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell align="center" style={textStyle()}>
                {rowIndex + 1}
              </TableCell>
              <TableCell style={textStyle("main")}>
                {rowData?.question}
              </TableCell>
              <TableCell style={{ border: "none" }}></TableCell>
              <TableCell style={{ border: "none" }}></TableCell>
              <TableCell style={{ border: "none" }}></TableCell>
              <TableCell style={{ border: "none", padding: "0px" }}>
                <Box style={statusStyle(rowData?.status)}>
                  <DotIcon
                    style={{
                      height: "10px",
                      fontWeight: 500,
                    }}
                  />
                  {rowData?.status}
                </Box>
              </TableCell>
              <TableCell
                align="center"
                style={{ border: "none", padding: "0px" }}
              >
                <IconButton size="small" onClick={() => handleClick(rowData)}>
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={{ color: "#3374B9" }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </DynamicTable>
      )}
      {isDrawerOpen && (
        <ThreadDrawer
          isDrawerOpen={isDrawerOpen}
          selectedQues={selectedQuestion}
          handleCloseDrawer={handleCloseDrawer}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}
    </>
  );
}

export default ApproverQuestions;
