// !------------- (searchClick) of type function needs to be passed --------------!

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Textfield from "./textfield";
import { FiSearch } from "react-icons/fi";

function SearchTextField({ searchClick }) {
  const [searchChange, setSearchChange] = React.useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchChange !== "") {
        searchClick(searchChange);
        setSearchChange("");
      }
    }
  };

  const handleSearchClick = () => {
    if (searchChange !== "") {
      searchClick(searchChange);
      setSearchChange("");
    }
  };

  return (
    <Textfield
      variant={"outlined"}
      color={"primary"}
      label={"Search"}
      value={searchChange}
      style={{ width: "400px" }}
      size={"small"}
      onChange={(e) => setSearchChange(e.target.value)}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleSearchClick}>
            <FiSearch
              style={{ color: "#3374B9", cursor: "pointer" }}
              size={18}
            />
          </IconButton>
        ),
        style: {
          borderRadius: 10,
        },
      }}
      InputLabelProps={{
        style: {
          color: "#3374B9",
        },
      }}
    />
  );
}
export default React.memo(SearchTextField);
