import React from "react";

import StepRight from "../../stepLayout/right/StepRight";
import { Box } from "@material-ui/core";
import { BlueBackgroundCard } from "../../../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question } from "../../../../selfAssessment/selfAssessmentResponse";
import OptimizeInitialRender from "../../../../riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/OptimizeInitialRender";
import NoData from "../../../../NoData/NoData";
import SubmissionQues from "./SubmissionQues";

function Step4Right({ completedSecQues }) {
  const nonClickableStyle = {
    pointerEvents: "none",
  };
  return (
    <StepRight>
      <>
        <Box style={{ overflow: "scroll", height: "90%" }}>
          {completedSecQues?.length === 0 ? (
            <NoData message={"No Completed Sections/Questions"} />
          ) : (
            <OptimizeInitialRender chunkSize={1}>
              {completedSecQues?.length > 0 &&
                completedSecQues?.map((section) => (
                  <div key={section?.sectionId} style={nonClickableStyle}>
                    <BlueBackgroundCard
                      heading={section?.sectionTitle}
                      subHeading={section?.sectionDescription}
                    />

                    <OptimizeInitialRender chunkSize={1}>
                      {section?.questions?.length > 0 &&
                        section?.questions?.map((question) => (
                          <SubmissionQues
                            key={question?.questionId}
                            question={question?.question}
                            answers={question?.answers}
                            approvedAnswer={
                              question?.questionType === "TABLE"
                                ? question?.approvedTableRows
                                : question?.approvedAnswer
                            }
                            questionType={question?.questionType}
                            tableHeadersApi={question?.tableHeaders}
                          />
                        ))}
                    </OptimizeInitialRender>
                  </div>
                ))}
              {/* {Object.keys(response).map((question, index) => {
              return (
                <div key={index} style={nonClickableStyle}>
                  <BlueBackgroundCard heading={question} />
                  <OptimizeInitialRender chunkSize={1}>
                    {response[question].map((question) => (
                      <Question
                        key={question?._id}
                        question={question.question}
                        options={question.answers}
                        type={question.question_type}
                        weightage={question.weightage}
                        submittedAnswer={question.submitted_answer}
                        removeEditOption={true}
                        comment={question?.comment}
                      />
                    ))}
                  </OptimizeInitialRender>
                </div>
              );
            })} */}
            </OptimizeInitialRender>
          )}
        </Box>
      </>
    </StepRight>
  );
}

export default Step4Right;
