import { combineReducers } from "redux";

import brmData from "../reducers/brmReducer/BrmReducer";
import login from "../reducers/login/login_reducer";
import corporateSurvey from "../reducers/corporateReducer/corporateSurveyReducer";
import riskEvaluator from "../reducers/riskEvaluatorReducer/riskEvaluatorReducer";
import loader from "../reducers/loader/loaderReducer";
import snackbar from "../reducers/snackbar/snackbar_reducer";
import riskDashboard from "../reducers/riskDashboardReducer/riskDashboardReducer";
import esgGptReducer from "./gpt/esgGptReducer";
import vendorReducer from "./vendor/vendorReducer";
import generalSettings from "./generalSettings/generalSettingsReducer";
import taskReducer from "../reducers/taskReducer/TaskReducer";
import threadReducer from "./threadReducer/ThreadReducer";
import esgDiagnosisReducer from "./esgDiagnosisReducer/esgDiagnosisReducer";
import vendorDashboardReducer from "./vendorDashboardReducer/vendorDashboardReducer";

export default combineReducers({
  login,
  loader,
  snackbar,
  brmData: brmData,
  corporateSurvey,
  riskEvaluator,
  riskDashboard,
  esgGptReducer,
  vendorReducer,
  vendorDashboardReducer,
  generalSettings,
  taskReducer,
  threadReducer,
  esgDiagnosisReducer,
});
