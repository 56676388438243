import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";

// other imports
import {
  getSubmittedResponse,
  setResponseCompany,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";
import ResponseSectionList from "./ResponseSectionList";
import ResponseSectionTopCard from "./ResponseSectionTopCard";
import ResponseSectionTopHeading from "./ResponseSectionTopHeading";
import { response1, response2 } from "../../QuestionTabComponents/QuestionData";
import { gql_get_completed_questions } from "../../../../../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_COMPLETED_QUESTIONS } from "../../../../../../../../constants/esgDiagnosisConstant";

const DrawerRight = ({ vendor }) => {
  // from redux

  const dispatch = useDispatch();
  const {
    selectedResCompany,
    submittedQuestions,
    total_no_of_questions,
    surveyStatus,
  } = useSelector((state) => state.riskDashboard);

  const [frequencyId, setFrequencyId] = useState("");
  const { frequencies } = useSelector((state) => state.esgDiagnosisReducer);

  const { selectedSurveyData } = useSelector((state) => state.riskDashboard);
  const { bankId } = useSelector((state) => state.login);

  useEffect(() => {
    const getCompletedQuestionsInput = {
      frequencyId: frequencies[0]?._id,
      surveyId: selectedSurveyData?._id,
      companyId: vendor?._id,
      bankId: bankId,
    };

    if (frequencies?.length > 0) {
      dispatch(gql_get_completed_questions(getCompletedQuestionsInput));
    }

    return () => {
      dispatch({ type: SET_COMPLETED_QUESTIONS, payload: [] });
    };
  }, [frequencies]);

  useEffect(() => {
    return () => {
      dispatch(setResponseCompany(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // console.log(isRender);

  const handleFrequencyChange = (freqId) => {
    const getCompletedQuestionsInput = {
      frequencyId: freqId,
      surveyId: selectedSurveyData?._id,
      companyId: vendor?._id,
      bankId: bankId,
    };

    dispatch(gql_get_completed_questions(getCompletedQuestionsInput));
  };

  return (
    <Box sx={{ height: "90%", overflow: "hidden", width: "100%" }}>
      <Box sx={{ pl: 2, height: "100%", overflow: "hidden", width: "100%" }}>
        {/* top section */}

        {/* <ResponseSectionTopHeading selectedResCompany={selectedResCompany} /> */}

        {/* top cards */}

        <ResponseSectionTopCard
          selectedResCompany={selectedResCompany}
          total_no_of_questions={total_no_of_questions}
          surveyStatus={surveyStatus}
          setFrequencyId={setFrequencyId}
          handleFrequencyChange={handleFrequencyChange}
        />

        {/* question list */}

        <ResponseSectionList
          submittedQuestions={submittedQuestions}
          frequencyId={frequencyId}
          vendor={vendor}
        />

        {/* {isRender ? (
          <ResponseSectionList submittedQuestions={submittedQuestions} />
        ) : (
          <CircularProgress />
        )} */}
      </Box>
    </Box>
  );
};

export default DrawerRight;
