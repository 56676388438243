import React, { useEffect } from "react";

import { Box, Grid } from "@material-ui/core";
import OptimizeInitialRender from "../../widgets/riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/OptimizeInitialRender";
import { response1 } from "../../widgets/riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import { BlueBackgroundCard } from "../../widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question } from "../../widgets/selfAssessment/selfAssessmentResponse";
import MainHeader from "./MainHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_completed_section_questions,
  setCompletedSecQues,
} from "../../../redux/actions/vendorActions/vendorAction";
import SurveyStep4 from "../../widgets/vendorAdmin/surveySteps/step4";
import StepHeader from "../../widgets/vendorAdmin/surveySteps/stepHeader/StepHeader";

function SurveyCompletedSteps() {
  const nonClickableStyle = {
    pointerEvents: "none",
  };

  const { completedSecQues, selectedVASurvey } = useSelector(
    (state) => state.vendorReducer
  );
  const { vendorBasicDetails, selectedBankDetailsForVa } = useSelector(
    (state) => state.brmData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      gql_get_completed_section_questions(
        selectedVASurvey?.surveyId,
        selectedVASurvey?.frequencyId,
        vendorBasicDetails?._id,
        selectedBankDetailsForVa?._id
      )
    );

    return () => {
      dispatch(setCompletedSecQues([]));
    };
  }, []);

  const steps = [
    "Add Stakeholder",
    "View & Assign Survey",
    "Audit & Freeze",
    "Submission",
  ];

  return (
    <>
      {/*<MainHeader />
      <Grid
        container
        style={{ height: "85vh", padding: "10px" }}
        justifyContent="space-between"
      >
        <Grid
          item
          md={12}
          style={{
            height: "100%",
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "0px 0px 8px 0px rgba(51, 116, 185, 0.25)",
          }}
        >
          <Box style={{ overflow: "scroll", height: "90%" }}>
            <OptimizeInitialRender chunkSize={1}>
              {completedSecQues.map((section, index) => {
                return (
                  <div key={index} style={nonClickableStyle}>
                    <BlueBackgroundCard heading={section?.sectionTitle} />
                    <OptimizeInitialRender chunkSize={1}>
                      {section?.questions.map((question) => (
                        <Question
                          key={question?._id}
                          question={question?.question}
                          options={question?.answers}
                          type={question?.questionType}
                          weightage={question?.weightage}
                          submittedAnswer={question?.approvedAnswer}
                          removeEditOption={true}
                          comment={
                            question?.approvedAnswer &&
                            question?.approvedAnswer[0]?.comment !== null &&
                            question?.approvedAnswer[0]?.comment !== ""
                          }
                        />
                      ))}
                    </OptimizeInitialRender>
                  </div>
                );
              })}
            </OptimizeInitialRender>
          </Box>
        </Grid>
      </Grid>*/}

      <StepHeader steps={steps} activeStep={4} hidden={true} />
      <SurveyStep4 />
    </>
  );
}
export default SurveyCompletedSteps;
