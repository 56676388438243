import { gql } from "@apollo/client";

export const GET_DEFAULT_SURVEY = gql`
  query Surveys($bankId: String!) {
    surveys(bankId: $bankId) {
      surveys {
        _id
        name
      }
    }
  }
`;

export const GET_SELF_ASSESSMENT_SCORE = gql`
  query SelfAssessmentScore($getScoreInput: GetScoreInput!) {
    selfAssessmentScore(getScoreInput: $getScoreInput)
  }
`;

export const GET_CONTROVERCIES_SCORE = gql`
  query ControversyScore($getScoreInput: GetScoreInput!) {
    controversyScore(getScoreInput: $getScoreInput)
  }
`;

export const GQL_GET_FREQUENCIES = gql`
  query SurveyResponseFrequencies(
    $surveyResponseFrequenciesInput: SurveyResponseFrequenciesInput!
  ) {
    surveyResponseFrequencies(
      surveyResponseFrequenciesInput: $surveyResponseFrequenciesInput
    ) {
      _id
      startDate
      endDate
      type
    }
  }
`;

export const GQL_GET_COMPLETED_QUESTIONS = gql`
  query GetCompletedQuestions(
    $getCompletedQuestionsInput: GetCompletedQuestionsInput!
  ) {
    getCompletedQuestions(
      getCompletedQuestionsInput: $getCompletedQuestionsInput
    ) {
      questions {
        order
        question
        questionId
        questionType
        weightage
        tableHeaders {
          title
          type
        }
        approvedTableRows {
          option
          documentUrl {
            type
            name
            url
          }
          weightage
          comment
        }
        answers {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
        }
        approvedAnswer {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
          comment
          startDate
          endDate
        }
      }
      sectionDescription
      sectionId
      sectionTitle
    }
  }
`;

export const GQL_GET_TPRM_SCORE = gql`
  query TprmSurveyScores($getScoreInput: GetScoreInput!) {
    tprmSurveyScores(getScoreInput: $getScoreInput)
  }
`;
