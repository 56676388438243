import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#5C90C7",
      "&:hover": {
        backgroundColor: "#3374B9",
      },
    },
    "& .MuiPaginationItem-root": {
      "&:hover": {
        color: "#fff",
        backgroundColor: "#A1BFDF",
      },
    },
  },
}));

const DynamicPagination = ({ totalCount, onPageChange }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      onPageChange(page);
    }
  };

  return (
    <div>
      <Pagination
        count={Math.ceil(totalCount / 10)} // 10 items per page
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        classes={{ root: classes.root }}
      />
    </div>
  );
};

export default DynamicPagination;
