import React from "react";
import { Doughnut } from "react-chartjs-2";
import DonutChart from "react-donut-chart";
import "./donut.css";

function PercentageDoughnutChart({
  percentage,
  color,
  emptyColor,
  footPrint,
  is_premium = true,
  customSize,
}) {
  const d = 4;
  const labels = ["I", "Me", "Main"];

  const options = {
    cutout: d === 4 && "80%",
    rotation: d === 4 && 0,
    circumference: d === 4 && 360,
    borderRadius: 30,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: d === 4 ? false : true,
          align: "center",
        },
        grid: {
          display: d === 4 ? false : true,
          drawOnChartArea: false,
        },
      },

      y: {
        grid: {
          display: d === 4 ? false : true,
          drawBorder: false,
        },
        ticks: {
          display: d === 4 ? false : true,
          maxTicksLimit: 5,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: false,
        position: "bottom",
        text: "tectxl",
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const doughnutLabel = {
    id: "doughnutLabel",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`${data.datasets[0].data[0]} %`, xCoor, yCoor);
    },
  };

  const plugins = [doughnutLabel];
  const data = {
    labels: ["", ""],
    datasets: [
      {
        fill: true,
        data: [percentage, 100 - percentage],
        borderWidth: 1,
        borderRadius: "30px",
        borderColor: [color || "rgb(255, 99, 132)", "#EBF6EF"],
        backgroundColor: [color || "rgb(255, 99, 132)", "#EBF6EF"],
        tension: 0.4,
      },
    ],
  };

  let circle = {
    id: "circle",
    beforeDatasetsDraw(chart, args) {
      const { ctx } = chart;
      ctx.save();

      const { x, y, innerRadius, outerRadius } =
        chart.getDatasetMeta(0).data[0];
      ctx.beginPath();
      const angle = Math.PI / 180;
      ctx.arc(x, y, outerRadius, 0, angle, false);
    },
  };
  return (
    <div
      style={{ width: "100%", filter: is_premium ? "blur(0px)" : "blur(5px)" }}
    >
      {
        <div className="main">
          <DonutChart
            // outerRadius={0.5}
            // innerRadius={0.9}
            // plugins={[circle]}
            className="donutchart-innertext-value"
            height={footPrint ? 150 : customSize}
            width={footPrint ? 150 : customSize}
            legend={false}
            emptyOffset={0.01}
            emptyColor={emptyColor}
            formatValues={(values, total) =>
              percentage ? `${percentage}/100` : "0%"
            }
            colors={[color]}
            data={[
              {
                label: "",
                value: percentage || 0,
              },
              {
                label: "",
                value: 100 - (percentage || 0),
                isEmpty: true,
              },
            ]}
          />
          {/* <Doughnut data={data} options={options} plugins={plugins} /> */}
        </div>
      }
    </div>
  );
}

export default PercentageDoughnutChart;
