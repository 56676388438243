import React, { useEffect } from "react";

import { Grid, Paper, Typography } from "@material-ui/core";
import ScoreChart from "./ScoreChart";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";
import { green } from "@material-ui/core/colors";
import { TopicData, TopicHeader } from "./esgComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_default_surveys,
  rest_get_esg_score,
} from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import NoData from "../NoData/NoData";

function EsgScore(props) {
  // const { scores } = props;
  // const { environment, social, governance } = scores?.scoreObject;
  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { token } = useSelector((state) => state.login);
  const { esgScore } = useSelector((state) => state.esgDiagnosisReducer);

  const dispatch = useDispatch();

  const { bankId } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(rest_get_esg_score(vendorBasicDetails?.isin, token));

    dispatch(gql_get_default_surveys(bankId));
  }, []);
  // const { environment, social, governance } = scores?.scoreObject;

  // const TopicHeader = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         borderBottom: "1px solid lightblue",
  //         marginTop: "10px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <img src={getColoredLogo(topic)} alt="leaf" />
  //         <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  // const TopicData = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         marginTop: "8px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Typography
  //           style={{ fontSize: "14px", marginLeft: "5px", fontWeight: 400 }}
  //         >
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };

  const dataNew = [
    {
      scoreObject: {
        environment: {
          score: 0,
          sum_of_weighted_score: 0,
          final_weighted_score: 0,
          average: {
            energy: 0,
            water: 0,
            waste: 0,
            analytic: 0,
            green: 0,
          },
          weighted_score: {
            energy: 0,
            water: 0,
            waste: 0,
            analytic: 0,
            green: 0,
          },
          data_points: {
            energy: 0,
            water: 0,
            waste: 0,
            analytic: 0,
            green: 0,
          },
        },
        social: {
          score: 0,
          sum_of_weighted_score: 0,
          final_weighted_score: 0,
          average: {
            career: 0,
            dataPrivacy: 0,
            diversity: 0,
            health: 0,
            humanRights: 0,
            product: 0,
          },
          weighted_score: {
            career: 0,
            dataPrivacy: 0,
            diversity: 0,
            health: 0,
            humanRights: 0,
            product: 0,
          },
          data_points: {
            career: 0,
            dataPrivacy: 0,
            diversity: 0,
            health: 0,
            humanRights: 0,
            product: 0,
          },
        },
        governance: {
          score: 0,
          sum_of_weighted_score: 0,
          final_weighted_score: 0,
          average: {
            compensation: 0,
            structure: 0,
          },
          weighted_score: {
            compensation: 0,
            structure: 0,
          },
          data_points: {
            compensation: 0,
            structure: 0,
          },
        },
      },
      esgScore: 0,
      esgcScore: 0,
      finalEsg: 0,
      energy_kpis: {
        "Total electricity consumption in Gigajoules  from renewable sources": 0,
        "Total fuel consumption in Gigajoules  from renewable sources": 0,
        "Total Energy consumption  in Gigajoules  from renewable sources ": 0,
        "Total electricity consumption in Gigajoules  from non renewable sources": 0,
        "Total fuel consumption in Gigajoules  fromnon renewable sources": 0,
        "Total Energy consumption  in Gigajoules  from non renewable sources ": 0,
      },
      water_kpis: {
        "Water withdrawal by source (in kilolitres)  from surface water": 0,
        "Water withdrawal by source (in kilolitres)  from ground water": 0,
        "Water withdrawal by source (in kilolitres)  from third party water ": 0,
        "Amount of rainwater was utilized  (in kilolitres) ": 0,
        " Volume of water withdrawal  (in kilolitres)": 0,
        "Total volume of water consumption (in kilolitres)  ": 0,
        " Water intensity per rupee of turnover (Water consumed / turnover) ": 0,
      },
    },
  ];

  return (
    <div style={{ paddingBottom: "18px", marginTop: "30px", width: "100%" }}>
      <Grid container md={12}>
        <Grid item md={7} sm={12}>
          {/* <Typography
            style={{ fontSize: "13px", padding: "5px" }}
            component={Paper}
          >
            The score reflects the company's ESG performance and can guide
            stakeholders in making informed decisions. (Out of 100) 54
          </Typography> */}
          <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {esgScore && esgScore?.length > 0
              ? Object.entries(esgScore[0].scoreObject)?.map((data, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: "49%",
                        borderRadius: 8,
                        backgroundColor: "#FBFBFB",
                        paddingBottom: 5,
                      }}
                    >
                      <TopicHeader topic={data[0]} score={data[1]?.score} />
                      {Object.entries(data[1]?.data_points)?.map((sub, i) => (
                        <TopicData key={i} topic={sub[0]} score={sub[1]} />
                      ))}
                    </div>
                  );
                })
              : Object.entries(dataNew[0].scoreObject)?.map((data, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: "49%",
                        borderRadius: 8,
                        backgroundColor: "#FBFBFB",
                        paddingBottom: 5,
                      }}
                    >
                      <TopicHeader topic={data[0]} score={data[1]?.score} />
                      {Object.entries(data[1]?.data_points)?.map((sub, i) => (
                        <TopicData key={i} topic={sub[0]} score={sub[1]} />
                      ))}
                    </div>
                  );
                })}
          </div>
        </Grid>
        <Grid item md={5} sm={12}>
          {<ScoreChart scores={esgScore ? esgScore[0] : {}} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default EsgScore;
