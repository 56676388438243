import { gql } from "@apollo/client";

export const GET_BANK_TASKS = gql`
  query Tasks($active: Boolean!, $bankId: ID!) {
    tasks(active: $active, bankId: $bankId) {
      date
      tasks {
        _id
        actionType
        assignedOn
        frequencyId
        frequencyType
        ids
        isCompleted
        latestVersion
        originalId
        type
        survey {
          _id
          name
        }
        assignedBy {
          _id
          designation
          name
          user_type
        }
      }
    }
  }
`;

export const GET_TASK_SECTIONS = gql`
  query GetAssignedSectionsForContributors(
    $getAssignedSectionsInput: GetAssignedSectionsInput!
  ) {
    getAssignedSectionsForContributors(
      getAssignedSectionsInput: $getAssignedSectionsInput
    ) {
      _id
      title
      version
      questions {
        showComment
      }
    }
  }
`;

export const SUBMIT_ANSWER = gql`
  mutation SubmitQuestion($submitQuestionInput: SubmitQuestionInput!) {
    submitQuestion(submitQuestionInput: $submitQuestionInput) {
      _id
    }
  }
`;

export const GQL_REDO_ENTIRE_SECTION = gql`
  mutation RedoEntireSectionQuestions(
    $redoEntireSectionQuestionsInput: RedoEntireSectionQuestionsInput!
  ) {
    redoEntireSectionQuestions(
      redoEntireSection: $redoEntireSectionQuestionsInput
    ) {
      _id
    }
  }
`;

export const GET_TASK_QUESTIONS = gql`
  query GetAssignedQuestionsForContributors(
    $getAssignedQuestionsInput: GetAssignedQuestionsInput!
  ) {
    getAssignedQuestionsForContributors(
      getAssignedQuestionsInput: $getAssignedQuestionsInput
    ) {
      totalSubmittedQuestions
      questions {
        approverIds
        chatRoomId
        question
        questionId
        questionType
        version
        weightage
        isApproved
        markedAsComplete
        submittedAnswer {
          option
          weightage
          startDate
          endDate
          comment
        }
        answers {
          description
          documentUpload
          documentUrl {
            name
            type
            url
          }
          justification
          justificationText
          option
          weightage
          comment
        }
        tableHeaders {
          title
          type
        }
      }
    }
  }
`;

export const GET_TASK_QUESTIONS_FOR_APPROVER = gql`
  query GetAssignedQuestionsForContributors(
    $getAssignedQuestionsInput: GetAssignedQuestionsInput!
  ) {
    getAssignedQuestionsForContributors(
      getAssignedQuestionsInput: $getAssignedQuestionsInput
    ) {
      questions {
        approverIds
        answers {
          option
          weightage
          justification
          justificationText
          documentUpload
          documentUrl {
            name
            type
            url
          }
          startDate
          endDate
          comment
        }
        submittedAnswer {
          option
          weightage
          justification
          justificationText
          documentUpload
          documentUrl {
            name
            type
            url
          }
          startDate
          endDate
          comment
        }
        chatRoomId
        order
        question
        questionId
        questionType
        status
        markedAsComplete
      }
    }
  }
`;
