import {
  AppBar,
  Box,
  Card,
  CardContent,
  createTheme,
  Grid,
  IconButton,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../UI/button/button";
import TabPanel from "../../../UI/tabPanel/tabPanel";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../../UI/textfield/textfield";
import countryList from "../../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import tickGreen from "../../../../images/TickGreen.png";

const basicDataFirstTab = [
  {
    id: 0,
    mainHead: "Basic Assessment Details",
    subHead:
      "Evaluate and improve your environmental, social, and governance performance with our comprehensive assessment tools and customized recommendations.",
  },
  {
    id: 1,
    mainHead: "Stakeholders List",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
  // {
  //   id: 2,
  //   mainHead: "Self Assessment Survey",
  //   subHead:
  //     "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  // },
];

const basicDataThirdTab = [
  {
    id: 0,
    mainHead: "Self Assessment Survey",
    subHead:
      "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
  },
  {
    id: 1,
    mainHead: "Controversies Survey Questions",
    subHead:
      "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
  },
];

// const rmSurveyTabs = [
//   {
//     id: 0,
//     mainHead: "Self Assessment Survey",
//     subHead:
//       "Identify potential controversies related to your ESG practices through out targeted survey questions and proactively address them.",
//   },
//   {
//     id: 1,
//     mainHead: "Controversies Survey Questions",
//     subHead:
//       "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
//   },
// ];

const rmBasicDataFirstTab = [
  {
    id: 0,
    mainHead: "Basic Assessment Details",
    subHead:
      "Evaluate and improve your environmental, social, and governance performance with our comprehensive assessment tools and customized recommendations.",
  },
  {
    id: 1,
    mainHead: "Stakeholders List",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
  {
    id: 2,
    mainHead: "Self Assessment Survey",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
];
const rmSurveyTabs = [
  {
    id: 0,
    mainHead: "Stakeholders List",
    subHead:
      "Identify potential controversies related to your ESG practices through out targeted survey questions and proactively address them",
  },
];
const esgProfileTab = [
  {
    id: 0,
    mainHead: "ESG Profile",
    subHead:
      "Demonstrate your commitment to sustainable business practices by creating and publishing an ESG declaration outlining your company's values, goals, and performance metrics",
    // "Showcase your ESG performance and progress through a eco-friendly company profile that highlights key metrics, initiatives, and achievements in ESG areas.",
  },
  {
    id: 1,
    mainHead: "ESG KPIs",
    subHead:
      "Monitor and analyze media and public sentiment related to your company's ESG practices and address potential controversies in a timely and effective manner.",
  },
  {
    id: 2,
    mainHead: "Peer Analysis",
    subHead:
      "Benchmark your company's ESG performance against peers in your industry and identify best practices to improve your overall sustainability profile.",
  },
  {
    id: 3,
    mainHead: "Controversies",
    subHead:
      "Monitor and analyze media and public sentiment related to your company's ESG practices and address potential controversies in a timely and effective manner.",
  },
  {
    id: 4,
    mainHead: "Survey Responses",
    subHead:
      "Showcase your ESG performance and progress through a user-friendly company profile that highlights key metrics, initiatives, and achievements",
    // "Demonstrate your commitment to sustainable business practices by creating and publishing an ESG declaration outlining your company's values, goals, and performance metrics",
  },
];

const esgRisk = [
  {
    id: 0,
    mainHead: "ESG Risk Rating",
    subHead:
      "Navigate the corporate landscape with foresight and resilience, addressing potential ESG risks through our comprehensive risk management solutions.",
    // "Showcase your ESG performance and progress through a eco-friendly company profile that highlights key metrics, initiatives, and achievements in ESG areas.",
  },
];

const basicDataSecondTab = [
  {
    id: 0,
    mainHead: "API Connection",
    subHead:
      "Seamlessly integrate ESG assessment data with your existing systems through our flexible and secure API connection",
  },
  {
    id: 1,
    mainHead: "Company Documents",
    subHead:
      "Share your ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
  },
  {
    id: 2,
    mainHead: "Peer Document",
    subHead:
      "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
  },
  {
    id: 3,
    mainHead: "Survey",
    subHead:
      "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
  },
];

export const LeftCard = ({ toMap = [], handleTabChange, subTabNo }) => {
  const classes = brmLeftStyles();
  return toMap.map((data, index) => (
    <Card
      variant="outlined"
      className={classes.cardContainerDefault}
      onClick={() => handleTabChange(index)}
      key={data.id}
      style={{
        background: data.id === subTabNo ? "#EBF1F8" : "#FDFDFD",
        cursor: "pointer",
      }}
    >
      <CardContent className={classes.cardContent}>
        <div style={{ display: "flex" }}>
          <h1
            className={classes.mainHeadingDefault}
            style={{
              borderLeft: data.id === subTabNo && "4px solid #3374B9",
              paddingLeft: data.id === subTabNo && 5,
            }}
          >
            {data.mainHead}
          </h1>
          {/* {data.id < 1 && (
            <img
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "auto",
              }}
              src={tickGreen}
              alt=""
            />
          )} */}
        </div>
        <div style={{ overflowX: "scroll" }}>
          <h3
            className={classes.subHeadingDefault}
            style={{
              color: data.id === subTabNo ? "#3374b9" : "#BBBBBB",
            }}
          >
            {data.subHead}
          </h3>
        </div>
      </CardContent>
    </Card>
  ));
};

export const brmLeftStyles = makeStyles(() => ({
  cardContainerDefault: {
    width: "100%",
    height: "8.4rem",
    // height: "125px" || '8.4rem',
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },

  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },
}));

function BrmDataLeft(props) {
  const { setBrmMainTabNo, mainTabNo, setBrmSubTabNo, brmData } = props;
  const classes = brmLeftStyles();
  const handleTabChange = (index) => {
    setBrmSubTabNo(index);
  };

  const leftCard = (toMap) => {
    return toMap.map((data, index) => (
      <Card
        variant="outlined"
        className={classes.cardContainerDefault}
        onClick={() => handleTabChange(index)}
        key={data.id}
        style={{
          background: data.id === brmData.brmSubTabNo ? "#EBF1F8" : "#FDFDFD",
          cursor: "pointer",
        }}
      >
        <CardContent className={classes.cardContent}>
          <div style={{ display: "flex" }}>
            <h1
              className={classes.mainHeadingDefault}
              style={{
                borderLeft:
                  data.id === brmData.brmSubTabNo && "4px solid #3374B9",
                paddingLeft: data.id === brmData.brmSubTabNo && 5,
              }}
            >
              {data.mainHead}
            </h1>
            {/* {data.id < 1 && (
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "auto",
                }}
                src={tickGreen}
                alt=""
              />
            )} */}
          </div>
          <div style={{ overflowX: "scroll" }}>
            <h3
              className={classes.subHeadingDefault}
              style={{
                color: data.id === brmData.brmSubTabNo ? "#3374b9" : "#BBBBBB",
              }}
            >
              {data.subHead}
            </h3>
          </div>
        </CardContent>
      </Card>
    ));
  };

  return (
    <div>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        {" "}
        {props.isEvaluator && (
          <CardContent className={classes.cardContent}>
            {/* <div className="scroll" style={{ marginTop: 18 }}> */}
            <TabPanel value={brmData.brmMainTabNo} index={0}>
              <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
                {leftCard(basicDataFirstTab)}
              </div>
            </TabPanel>

            {/* <TabPanel value={brmData.brmMainTabNo} index={1}>
              <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
                {leftCard(basicDataThirdTab)}
              </div>
            </TabPanel> */}
            <TabPanel value={brmData.brmMainTabNo} index={1}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {leftCard(basicDataSecondTab)}
              </div>
            </TabPanel>
            <TabPanel value={brmData.brmMainTabNo} index={2}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {leftCard(esgProfileTab)}
              </div>
            </TabPanel>
            <TabPanel value={brmData.brmMainTabNo} index={3}>
              <div
                className="scroll"
                style={{ marginTop: 0, height: "62.5vh" }}
              >
                {leftCard(esgRisk)}
              </div>
            </TabPanel>
            {/* </div> */}
          </CardContent>
        )}
        {!props.isEvaluator && (
          <CardContent className={classes.cardContent}>
            {/* <div className="scroll" style={{ marginTop: 18 }}> */}
            <TabPanel value={brmData.brmMainTabNo} index={0}>
              <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
                {leftCard(rmBasicDataFirstTab)}
              </div>
            </TabPanel>

            <TabPanel value={brmData.brmMainTabNo} index={1}>
              <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
                {leftCard(rmSurveyTabs)}
              </div>
            </TabPanel>
            {/* </div> */}
          </CardContent>
        )}
      </Card>
    </div>
  );
}

export default BrmDataLeft;
