import { useState } from "react";

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";

import RiskSurveyTop from "../../widgets/riskSurveyComponents/Main/RiskSurveyTop";
import RiskSurveyMain from "../../widgets/riskSurveyComponents/Main/RiskSurveyMain";
import RiskSurveyDrawer from "../../widgets/riskSurveyComponents/Main/RiskSurveyDrawer";

const RiskSurveyContainer = () => {
  // state for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  // handling drawer open and close
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        px: 5,
      }}
    >
      {/* Main areas */}
      <RiskSurveyTop handleDrawer={handleDrawer} />
      <RiskSurveyMain />

      {/* Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawer}>
        <RiskSurveyDrawer handleDrawer={handleDrawer} />
      </Drawer>
    </Box>
  );
};
export default RiskSurveyContainer;
