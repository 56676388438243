import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FilledInput,
  FormControl,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Table,
  Step,
  StepLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Stepper,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import Doc from "../../../images/DocBlue.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomChip from "../../UI/chip/chip";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
// import countryList from "../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { valueOrDefault } from "../../../../node_modules/chart.js/dist/helpers.mjs";

import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import useDebounce from "../../../customHooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardPage } from "../../../redux/actions/riskdashboard/riskDashboardActions";
import Currency from "../../../utils/currency/currency.json";
import {
  addVendorToList,
  gql_create_company,
  gql_get_companies_by_name,
  gql_get_companies_list,
  gql_get_vendors_by_isin,
  selectVendor,
  setVendorOrganizationDetails,
  upload_create_vendor_img,
} from "../../../redux/actions/vendorActions/vendorAction";
import { dummyIndustries } from "../../../utils/riskUtils/riskUtils";

import debounce from "lodash/debounce";
import {
  SET_SEARCH_COMPANIES_BY_NAME,
  SET_VENDORS_BY_ISIN,
} from "../../../constants/brmConstants";

// import VendorDrawer from "./vendorDrawer";
export const TurnoverYearPickerDialog = ({
  isOpen,
  onCancel,
  onSet,
  start,
  end,
  onYearBack,
  onYearForward,
  onYearClick,
  onClear,
  range,
  yearRange,
}) => {
  return (
    <>
      <Dialog maxWidth="lg" open={isOpen}>
        <DialogContent style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`${start || ""}-${end || ""}`}</Typography>
            <Typography
              onClick={onYearBack}
              style={{
                fontSize: "22px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`<`}</Typography>
            <Typography
              onClick={onYearForward}
              style={{
                fontSize: "22px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`>`}</Typography>
            <Typography
              onClick={onClear}
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
                float: "right",
              }}
            >{`Clear`}</Typography>
          </div>
          <div>
            <Grid
              container
              xs={12}
              md={12}
              gap={1}
              spacing={2}
              style={{
                padding: "2% 1% 4% 1%",
                // // marginLeft: '2%',
              }}
            >
              {yearRange.map((year) => {
                return (
                  <Grid
                    item
                    xs={4}
                    md={4}
                    align="center"
                    style={{
                      // margin:'6px 8px',
                      // border: "1px solid blue",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      onClick={() => onYearClick(year)}
                      style={{
                        fontWeight: 500,
                        padding: "8px 5px",
                        border: range(year) ? "1px solid #3374B9" : "none",
                        borderRadius: "5px",
                        backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                      }}
                    >
                      {year}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            // variant="outlined"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={onCancel}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            style={{ padding: "6px 20px", textTransform: "none" }}
            disabled={!start || !end}
            onClick={onSet}
          >
            Set Period
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
function VendorOrganizations(props) {
  const useStyles = makeStyles(() => ({
    mainDiv: {
      padding: "5px 20px",
      minWidth: 700,
      maxWidth: 1080,
      overflowY: "hidden",
    },
    icon: {
      color: "red",
      marginLeft: 10,
    },
    headerText: {
      fontWeight: "600",
    },
    headerGrid: {
      padding: "10px 0px",
      // boxShadow: "0px 4px 4px #3374B926",
    },
    cardTypography: {
      fontWeight: "600",
    },
    logoSelecter: {
      border: "2px dashed #DADBE6",
      width: 120,
      height: 120,
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        "& $addIcon": {
          color: "#3374B9",
        },
      },
    },
    addIcon: {
      color: "#DADBE6",
    },
    companyLogo: {
      border: "2px solid #DADBE6",
      width: 120,
      height: 120,
      borderRadius: 5,
      zIndex: 1,
      "& img": {
        width: "100%",
        height: "100%",
      },
      position: "relative",
    },
    ClearImage: {
      position: "absolute",
      right: 0,
      top: 0,
      padding: 2,
    },
    addSectorContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
    sectorChip: {
      justifyContent: "flex-start",
      fontWeight: 600,
      color: "#15314E",
      backgroundColor: "#EBF1F8",
      borderRadius: "5%",
      border: "0px",
      margin: "4px 8px 4px 0px",
      "&&:hover": {
        "& $removeChipIcon": {
          display: "inline",
        },
      },
      "&&:focus": {
        color: "#77787B",
        backgroundColor: "#F5F6F8",
      },
    },
    selectedSectorTitle: {
      color: "#77787B",
      fontSize: 14,
    },
    removeChipIcon: {
      display: "none",
    },
    tableHeader: {
      marginTop: "10px",
      backgroundColor: "#EBF1F8",
      padding: "0px 5px",
      borderRadius: "8px",
      height: "15px",
    },
    tableData: {
      padding: "6px",
    },
    tableheadData: {
      padding: "0px 5px",
    },
    statusOrange: {
      padding: "3px",
      borderRadius: "50%",
      backgroundColor: "orange",
      width: "8px",
      height: "8px",
    },
    downloadButton: {
      textTransform: "none",
      float: "right",
      marginRight: "10px",
      color: "#3374B9",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
        boxShadow: "1px 1px 3px #c8c0c0a1",
      },
    },
    createButton: {
      textTransform: "none",
      float: "right",
      marginRight: "20px",
      paddingRight: "20px",
      color: "#fff",
      backgroundColor: "#3374B9",
      "&:hover": {
        backgroundColor: "#3374B9",
        boxShadow: "1px 1px 3px #c8c0c0a1",
      },
    },
    searchCont: {
      marginRight: 10,
    },
    headerIcon: { color: "#fff", paddingLeft: "5px" },
    box: {
      border: "1px solid #00000050",
      padding: 12,
      borderRadius: 5,
      cursor: "pointer",
      minHeight: 45,
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const {
    generalSettings,

    login,
    brmData,
    isVendor = false,
  } = props;

  const dispatch = useDispatch();

  const [vendorDetails, setVendorDetails] = useState({
    vendorId: "",
    vendorName: "",
    vendorWebsite: "",
    // vendorType: "",
    userName: "",
    userDesignation: "",
    isin: "",
    address: { street: "", landmark: "", country: "", state: "", pinCode: "" },
    no_of_employees: "",
    operation: "",
    sector: {
      industry: {
        inductry: "",
        industryId: "",
      },
      sector: {
        sector: "",
        sectorId: "",
      },
    },
  });

  // console.log("vendorDetails", vendorDetails);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const datas = [
    {
      name: "compant name",
      date: "12/3/2023",
      userName: "userName",
      status: "in progress",
    },
  ];
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [sectors, setSectors] = useState(dummyIndustries);
  const [isSelectedSector, setIsSelectedSector] = useState("");

  const [isAddcompanyDrawer, setIsAddCompanyDrawer] = useState(false); //""

  const [isHover, setHover] = useState(false);

  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");

  const isSectorSelected = () => {
    let isSectorSelected = false;
    if (sectors.length > 0) {
      for (let i = 0; i < sectors.length; i++) {
        let count =
          sectors[i].industry_data?.filter((s) => s?.isSelected === true) || [];
        if (count.length > 0) {
          isSectorSelected = true;
          break;
        }
      }
    }
    return isSectorSelected;
  };

  const [turnoverData, setTurnoverData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });

  // console.log(turnoverData, revenueData);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      // console.log("year", year, tstartYear, endYear);
      setTStartYear(year);
      setTEndYear("");
      // console.log("setTEndYear 2");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
      // console.log("setTEndYear 3");
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
      // console.log("setEndYear 4");
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    // console.log('revenue',revenue)
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  // console.log(revenueData,turnoverData)
  const handleSetYear = () => {
    !currentIndex.current.revenue &&
      setTurnoverData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });

    currentIndex.current.revenue &&
      setRevenueData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });

    // setVendorDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };
  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  // company drawer
  const [activeSteps, setActiveSteps] = useState(0);

  const tableCellStyle = {
    borderRight: "1px solid #ddd",
  };

  const [url, setUrl] = useState();
  const [showURLHelper, setShowURLHelper] = useState(false);

  const validURL = (value) => {
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    return urlRegex.test(value);
  };

  // console.log(vendorDetails?.isin);

  const handleWebsiteChange = (e) => {
    setUrl(e.target.value);

    if (validURL(url)) {
      setVendorDetails((prev) => ({
        ...prev,
        vendorWebsite: e.target.value,
      }));
    }
  };

  useEffect(() => {
    if (url && url !== "") {
      if (validURL(url)) {
        setShowURLHelper(false);
      } else {
        setShowURLHelper(true);
      }
    } else {
      setShowURLHelper(false);
    }
  }, [url]);

  useEffect(() => {
    // Scroll to the top whenever activeSteps changes
    const targetId = activeSteps === 0 ? "step1" : "step2";
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "instant" });
    }
  }, [activeSteps]);

  const [inputValue, setInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [existingVendor, setExistingVendor] = useState({});

  const fetchIsinResults = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        dispatch(gql_get_vendors_by_isin(searchQuery.toUpperCase()));
      }
    }, 500),
    []
  );

  // console.log(isSelectedSector);

  // const filteredSector = sectors?.map((e) => {
  //   let indData = [];
  //   e?.industry_data?.map((ind) => {
  //     if (ind?.isSelected === true) {
  //       indData.push(ind._id);
  //     }
  //     return ind;
  //   });

  //   if (indData.length > 0)
  //     return {
  //       sector: e._id,
  //       industry: indData, // set industry to indData only for multiple industry selection
  //     };
  //   return false;
  // });

  // const formattedSector = filteredSector?.filter((e) => e);
  // const sectorObj = {
  //   sector: formattedSector[0]?.sector,
  //   industry: formattedSector[0]?.industry[0],
  // };

  // console.log(formattedSector);

  // console.log(existingVendor);

  const handleIsin = (isinValue) => {
    fetchIsinResults(isinValue);
  };

  const handleVendorDetails = (vendor) => {
    if (vendor?.name !== "") {
      setVendorDetails((prev) => ({
        ...prev,
        vendorName: vendor?.name,
        vendorWebsite: vendor?.website,
        // vendorType: "Existing Vendor",
        isin: vendor?.isin,
        address: {
          street: vendor?.address?.location,
          landmark: vendor?.address?.landmark,
          country: vendor?.address?.country,
          state: vendor?.address?.state,
          pinCode: vendor?.address?.pincode,
        },
        no_of_employees: vendor?.employeesCount,
        operation: vendor?.operation,
        sector: {
          industry: {
            industry: vendor?.sector?.industry?.industry,
            industryId: vendor?.sector?.industry?._id,
          },
          sector: {
            sector: vendor?.sector?.sector?.sector,
            sectorId: vendor?.sector?.sector?._id,
          },
        },
      }));

      setCompanyLogoURI(vendor?.logo?.url);
      setExistingVendor(vendor);
      setUrl(vendor?.website);
    }

    if (vendor?.revenue?.length > 0 && vendor?.turnover?.length > 0) {
      setRevenueData(vendor?.revenue);
      setTurnoverData(vendor?.turnover);
    }
  };

  const addCompanyDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={isAddcompanyDrawer}
        onClose={handleAddCompanyDialogClose}
      >
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />
        <div className={classes.mainDiv}>
          {/* drawer header */}
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            className={classes.headerGrid}
          >
            <Grid item>
              <Typography className={classes.cardTypography}>
                Create Company
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsAddCompanyDrawer(false);
                }}
              />
            </Grid>
          </Grid>

          {/* drawer steps */}
          {/* <div
            style={{
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stepper activeStep={activeSteps} style={{ width: "50em" }}>
              {["Basics", "Real-Time Details"].map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div> */}

          <div className="scroll" style={{ marginTop: 18, height: "85vh" }}>
            {activeSteps === 0 ? (
              <Grid
                container
                style={{ overscrollBehavior: "contain" }}
                id="step1"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  item
                  xs={12}
                  md={11}
                  style={{ marginTop: "10px" }}
                >
                  {/* <Grid item md={6}>
                    <label htmlFor="userName">User Name</label>
                    {!vendorDetails?.userName && (
                      <span
                        style={{
                          color: "red",
                          padding: "0px 3px",
                          display: "inline",
                        }}
                      >
                        *
                      </span>
                    )}
                    <CustomTextfield
                      id="userName"
                      name="userName"
                      variant="outlined"
                      defaultValue={vendorDetails?.userName}
                      fullWidth
                      size="small"
                      type="text"
                      onChange={(e) => {
                        setVendorDetails((prev) => ({
                          ...prev,
                          userName: e.target.value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <label htmlFor="userDesignation">User Designation</label>
                    {!vendorDetails?.userDesignation && (
                      <span
                        style={{
                          color: "red",
                          padding: "0px 3px",
                          display: "inline",
                        }}
                      >
                        *
                      </span>
                    )}
                    <CustomTextfield
                      id="userDesignation"
                      name="userDesignation"
                      variant="outlined"
                      defaultValue={vendorDetails?.userDesignation}
                      fullWidth
                      size="small"
                      type="text"
                      onChange={(e) => {
                        setVendorDetails((prev) => ({
                          ...prev,
                          userDesignation: e.target.value,
                        }));
                      }}
                    />
                  </Grid> */}
                  <Grid item md={12}>
                    <label htmlFor="companyISIN">Company ISIN</label>
                    {!vendorDetails?.isin && (
                      <span
                        style={{
                          color: "red",
                          padding: "0px 3px",
                          display: "inline",
                        }}
                      >
                        *
                      </span>
                    )}
                    <Autocomplete
                      name="isin"
                      id="companyISIN"
                      options={brmData?.vendorsByIsin?.map((vendor) => vendor)}
                      getOptionLabel={(option) => option?.isin || ""}
                      renderOption={(option) => (
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>{option?.isin}</Typography>
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {option?.name ? (
                              <>
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: "20px", color: "#999" }}
                                >
                                  {option?.name}
                                </Typography>
                                {option?.logo?.url && (
                                  <img
                                    src={option?.logo?.url}
                                    alt={option?.name}
                                    style={{ height: 20, width: 20 }}
                                  />
                                )}
                              </>
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "20px", color: "#999" }}
                              >
                                Does not exist
                              </Typography>
                            )}
                          </Typography>
                        </Typography>
                      )}
                      size="small"
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          variant="outlined"
                          onChange={(e) => handleIsin(e.target.value)}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleVendorDetails(newValue);
                          setVendorDetails((prev) => ({
                            ...prev,
                            isin: newValue?.isin,
                          }));
                        } else {
                          setVendorDetails((prev) => ({
                            ...prev,
                            isin: "",
                          }));
                        }
                      }}
                      filterOptions={(options, state) =>
                        showSuggestions ? options : []
                      }
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        setShowSuggestions(newInputValue.length > 0);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Box
                    my={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "8px",
                    }}
                  >
                    <Box style={{ width: "100%" }}>
                      <label htmlFor="compName">Company Name</label>
                      {!vendorDetails?.vendorName && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                      <CustomTextfield
                        id="compName"
                        name="compName"
                        variant="outlined"
                        value={vendorDetails?.vendorName || ""}
                        disabled={existingVendor?.name ? true : false}
                        fullWidth
                        size="small"
                        // label="Vendor Name"
                        onChange={(e) => {
                          setVendorDetails((prev) => ({
                            ...prev,
                            vendorName: e.target.value,
                          }));
                        }}
                      />
                    </Box>
                    {/* <Box>
                      <label htmlFor="compType">Vendor Type</label>
                      {!vendorDetails?.vendorType && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                      <Box
                        id="compType"
                        style={{ display: "flex", gap: "8px", marginTop: 5 }}
                      >
                        {["New Vendor", "Existing Vendor"].map((type) => (
                          <Chip
                            key={type}
                            label={type}
                            disabled={existingVendor?.name}
                            color={
                              vendorDetails.vendorType === type
                                ? "primary"
                                : "default"
                            }
                            clickable
                            onClick={() => {
                              setVendorDetails((prev) => ({
                                ...prev,
                                vendorType: type,
                              }));
                            }}
                          />
                        ))}
                      </Box>
                    </Box> */}
                  </Box>
                  {/* Vendor Website */}
                  <Box style={{ width: "100%" }}>
                    <label htmlFor="compWebsite">Company Website</label>
                    {!vendorDetails?.vendorWebsite && (
                      <span
                        style={{
                          color: "red",
                          padding: "0px 3px",
                          display: "inline",
                        }}
                      >
                        *
                      </span>
                    )}
                    <CustomTextfield
                      id="compWebsite"
                      name="compWebsite"
                      type="text"
                      value={url || ""}
                      disabled={existingVendor?.name ? true : false}
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        handleWebsiteChange(e);
                      }}
                      helperText={showURLHelper && "Please enter a valid URL"}
                      error={showURLHelper}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box my={2} style={{ display: "flex" }}>
                    <Typography className={classes.headerText}>
                      Company Logo
                    </Typography>
                    {!companyLogoURI && (
                      <span
                        style={{
                          color: "red",
                          padding: "0px 3px",
                          display: "inline",
                        }}
                      >
                        *
                      </span>
                    )}
                  </Box>
                  <Box my={2}>
                    {!companyLogoURI ? (
                      <>
                        <input
                          id="company-logo"
                          type="file"
                          disabled={existingVendor?.name ? true : false}
                          onChange={(event) => handleUploadCompanyLogo(event)}
                          style={{ display: "none" }}
                          cursor="pointer"
                        />
                        <label htmlFor="company-logo">
                          <div className={classes.logoSelecter}>
                            <AddCircleOutlineIcon
                              name="addLogoIcon"
                              fontSize="large"
                              className={classes.addIcon}
                              disabled={existingVendor?.name ? true : false}
                            />
                          </div>
                        </label>
                      </>
                    ) : (
                      <div className={classes.companyLogo}>
                        <img
                          src={companyLogoURI}
                          alt="company-logo"
                          disabled={existingVendor?.name ? true : false}
                        />
                        <IconButton
                          onClick={handleClearCompanyLogo}
                          className={classes.ClearImage}
                          disabled={existingVendor?.name ? true : false}
                        >
                          <CancelIcon
                            style={{ color: "gray" }}
                            fontSize="small"
                            disabled={existingVendor?.name ? true : false}
                          />
                        </IconButton>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box my={2}>
                    <Typography className={classes.headerText}>
                      Sector & Industry
                      {!isSectorSelected() &&
                        !existingVendor?.sector?.sector?.sector && (
                          <span
                            style={{
                              color: "red",
                              padding: "0px 3px",
                              display: "inline",
                            }}
                          >
                            *
                          </span>
                        )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={classes.addSectorContainer}
                >
                  <CustomButton
                    disabled={existingVendor?.name ? true : false}
                    onClick={() => {
                      setIsSectorDialogOpen(true);
                    }}
                    variant="text"
                    color="primary"
                    style={{ textTransform: "capitalize" }}
                  >
                    Add Industry & Sector
                  </CustomButton>
                </Grid>

                {!existingVendor?.name ? (
                  <Grid item xs={12} md={8}>
                    <Box my={2}>
                      {sectors.length !== 0 ? (
                        sectors.map((sector) => (
                          <div style={{ marginBottom: 8 }}>
                            {sector.industry_data.some(
                              (inds) => inds.isSelected
                            ) && (
                              <>
                                <Typography
                                  className={classes.selectedSectorTitle}
                                >
                                  {sector.Sector}
                                </Typography>
                                {sector.industry_data.map(
                                  (industry) =>
                                    industry.isSelected && (
                                      <Chip
                                        className={classes.sectorChip}
                                        label={industry.Industry}
                                      />
                                    )
                                )}
                              </>
                            )}
                          </div>
                        ))
                      ) : (
                        <Chip
                          className={classes.sectorChip}
                          label="Non Selected"
                        />
                      )}
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={8}>
                    <Box my={2}>
                      <div style={{ marginBottom: 8 }}>
                        <Typography className={classes.selectedSectorTitle}>
                          {vendorDetails?.sector?.sector?.sector}
                        </Typography>

                        <Chip
                          className={classes.sectorChip}
                          label={vendorDetails?.sector?.industry?.industry}
                        />
                      </div>
                    </Box>
                  </Grid>
                )}

                <Grid item md={12} style={{ paddingBottom: 20 }}>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography className={classes.headerText}>
                      Revenue Data
                      {revenueData.length === 0 && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                    <CustomButton
                      disabled={existingVendor?.name ? true : false}
                      name="addBranch"
                      size="medium"
                      onClick={() => {
                        const initialPeer = {
                          revenue: "",
                          year: "",
                          currency: "",
                          figures: "",
                        };
                        const copy = [...revenueData];
                        copy.push(initialPeer);
                        setRevenueData(copy);
                        window.scrollTo({
                          top: 50,
                          behavior: "smooth",
                        });
                        // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Add Revenue
                    </CustomButton>
                  </Box>
                  {revenueData.map((revenue, i) => {
                    return (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={5}>
                          <CustomTextfield
                            disabled={existingVendor?.name ? true : false}
                            name="revenue"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Add Revenue"
                            value={revenue?.revenue}
                            onChange={(e) => {
                              const copy = [...revenueData];
                              copy[i].revenue = e.target.value;
                              setRevenueData(copy);
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          role="button"
                          onClick={() => handleTurnover(i, true)}
                        >
                          <CustomTextfield
                            disabled={existingVendor?.name ? true : false}
                            name="year"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Year"
                            onChange={(e) => {
                              const copy = [...revenueData];
                              copy[i].year = e.target.value;
                              setRevenueData(copy);
                            }}
                            value={revenue.year}
                          />
                        </Grid>
                        <Grid item xs={5} md={5}>
                          <Autocomplete
                            disabled={existingVendor?.name ? true : false}
                            id="country-select-demo"
                            size="small"
                            options={["USD", "AED", "INR"]}
                            classes={{
                              endAdornment: classes.icon,
                            }}
                            value={revenue.currency}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => option || ""}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                disabled={existingVendor?.name ? true : false}
                                label="Currency"
                                variant="outlined"
                                value={revenue.currency}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const copy = [...revenueData];
                                copy[i].currency = newValue;
                                setRevenueData(copy);

                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={5} md={6}>
                          <Autocomplete
                            disabled={existingVendor?.name ? true : false}
                            id="country-select-demo"
                            size="small"
                            // style={{ width: 300 }}
                            value={revenue.figures}
                            options={["Million", "Billion", "Crore"]}
                            classes={{
                              endAdornment: classes.icon,
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => option || ""}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                label="Figures"
                                variant="outlined"
                                value={revenue.figures}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const copy = [...revenueData];
                                copy[i].figures = newValue;
                                setRevenueData(copy);

                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                        {!existingVendor?.name && (
                          <Grid item xs={12} md={1}>
                            <Icon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const copy = [...revenueData];
                                copy.splice(i, 1);
                                setRevenueData(copy);
                              }}
                            >
                              remove
                            </Icon>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item md={12} style={{ paddingBottom: 20 }}>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography className={classes.headerText}>
                      Turnover Data
                      {/* {turnoverData.length === 0 && (
                        <span
                          style={{
                            color: "red",
                            padding: "0px 3px",
                            display: "inline",
                          }}
                        >
                          *
                        </span>
                      )} */}
                    </Typography>
                    <CustomButton
                      disabled={existingVendor?.name ? true : false}
                      name="addBranch"
                      size="medium"
                      onClick={() => {
                        const initialPeer = {
                          turnover: "",
                          year: "",
                          currency: "",
                          figures: "",
                        };
                        const copy = [...turnoverData];
                        copy.push(initialPeer);
                        setTurnoverData(copy);
                        window.scrollTo({
                          top: 50,
                          behavior: "smooth",
                        });
                        // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Add Turnover
                    </CustomButton>
                  </Box>
                  {turnoverData.map((turnover, i) => {
                    return (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={5}>
                          <CustomTextfield
                            disabled={existingVendor?.name ? true : false}
                            name="turnovers"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Add Turnover"
                            value={turnover.turnover}
                            onChange={(e) => {
                              const copy = [...turnoverData];
                              copy[i].turnover = e.target.value;
                              setTurnoverData(copy);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          role="button"
                          onClick={() => handleTurnover(i, false)}
                        >
                          <CustomTextfield
                            disabled={existingVendor?.name ? true : false}
                            name="NumOfEmployee"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="text"
                            label="Year"
                            onChange={(e) => {
                              const copy = [...turnoverData];
                              copy[i].year = e.target.value;
                              setTurnoverData(copy);
                            }}
                            value={turnover.year}
                          />
                        </Grid>
                        {!existingVendor?.name && (
                          <Grid item xs={12} md={1}>
                            <Icon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const copy = [...turnoverData];
                                copy.splice(i, 1);
                                setTurnoverData(copy);
                              }}
                            >
                              remove
                            </Icon>
                          </Grid>
                        )}
                        <Grid item xs={5} md={5}>
                          <Autocomplete
                            id="country-select-demo"
                            size="small"
                            options={["USD", "AED", "INR"]}
                            classes={{
                              endAdornment: classes.icon,
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => option || ""}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                disabled={existingVendor?.name ? true : false}
                                label="Currency"
                                variant="outlined"
                                value={turnoverData.currency}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const copy = [...turnoverData];
                                copy[i].currency = newValue;
                                setTurnoverData(copy);
                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={5} md={6}>
                          <Autocomplete
                            disabled={existingVendor?.name ? true : false}
                            id="country-select-demo"
                            size="small"
                            // style={{ width: 300 }}
                            options={["Million", "Billion", "Crore"]}
                            classes={{
                              endAdornment: classes.icon,
                            }}
                            // inputProps={{
                            //   classes: {
                            //     icon: classes.icon,
                            //     root:{}
                            //   },
                            // }}
                            autoHighlight
                            getOptionLabel={(option) => option || ""}
                            renderOption={(option) => (
                              <React.Fragment>{option}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                disabled={existingVendor?.name ? true : false}
                                label="Figures"
                                variant="outlined"
                                value={turnoverData.figures}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const copy = [...turnoverData];
                                copy[i].figures = newValue;
                                setTurnoverData(copy);
                                // setBranchCountry(newValue);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                {/* <Grid item xs={12} md={5}>
             
              </Grid> */}
                <Grid item xs={12} md={11}>
                  <Grid item>
                    <Box my={2}>
                      <label htmlFor="compOperation">Company Operations</label>
                      <CustomTextfield
                        disabled={existingVendor?.name ? true : false}
                        id="compOperation"
                        name="compOperation"
                        variant="outlined"
                        defaultValue={vendorDetails?.operation || ""}
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                        onChange={(e) => {
                          setVendorDetails((prev) => ({
                            ...prev,
                            operation: e.target.value,
                          }));
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* <Box my={2}>
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    // style={{ width: 300 }}
                    options={brmData.countries}
                    classes={{
                      option: classes.option,
                    }}
                    value={{ country_name: vendorDetails.country_name }}
                    autoHighlight
                    getOptionLabel={(option) => option.country_name}
                    renderOption={(option) => (
                      <React.Fragment>{option.country_name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Choose a country"
                        variant="outlined"
                        // value={{country_name:'Dominica'}}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setVendorDetails((prev) => ({
                          ...prev,
                          country_name: newValue?.country_name,
                          country_code: newValue?.country_code,
                          // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                        }));
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Box> */}
                  {/* <Box my={2}>
                  <Autocomplete
                    name="branchCountry"
                    id="countryCode"
                    options={brmData.countries}
                    getOptionLabel={(option) => option?.country_name}
                    renderOption={(option) => <>{option?.country_name}</>}
                    size="small"
                    renderInput={(params) => {
                      return (
                        <CustomTextfield
                          {...params}
                          label="Country"
                          variant="outlined"
                           value={vendorDetails.country_name}
                        />
                      );
                    }}
                    value={vendorDetails.country_name}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        console.log(newValue);
                        setVendorDetails((prev) => ({
                          ...prev,
                          country_name: newValue?.country_name,
                          country_code: newValue?.country_code,
                          // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                        }));
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Box> */}
                  {/* <Box my={2}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Country"
                    select
                    options={[1,2,3,]}
                    onChange={(e) => {
                      setVendorDetails((prev) => ({
                        ...prev,
                        isin: e.target.value,
                      }));
                    }}
                  />
                </Box> */}
                </Grid>
                {/* <Grid item xs={1} md={1}>
                {!vendorDetails?.country_name && (
                  <span
                    style={{
                      color: "red",
                      padding: "0px 3px",
                      display: "inline",
                    }}
                  >
                    *
                  </span>
                )}
              </Grid> */}
                {/* <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="ISIN Number"
                    onChange={(e) => {
                      setVendorDetails((prev) => ({
                        ...prev,
                        isin: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </Grid> */}
                {/* <Grid item xs={1} md={1}>
                {!vendorDetails?.isin && (
                  <span
                    style={{
                      color: "red",
                      padding: "0px 3px",
                      display: "inline",
                    }}
                  >
                    *
                  </span>
                )}
              </Grid> */}
                {/* <Grid item xs={12} md={11}>
                <Box
                  my={2}
                  onClick={() => setIsDatePickerDialogOpen(true)}
                  className={classes.box}
                >
                  {vendorDetails.yearOfAssessment ? (
                    <Typography variant="body1" name="yearOfAssesment">
                      {`${vendorDetails.yearOfAssessment.split("-")[0]} - ${
                        vendorDetails.yearOfAssessment.split("-")[1]
                      }`}
                    </Typography>
                  ) : (
                    <Typography
                      style={{ color: "#878282e6", fontFamily: "Roboto" }}
                    >
                      Year Of Assessment
                    </Typography>
                  )} */}

                {/* <Typography variant="body1" name="yearOfAssesment">
                    {basicInfo.yearOfAssessment[0].startDate !== "" &&
                    basicInfo.yearOfAssessment[0].endDate !== ""
                      ? `${moment(
                          new Date(basicInfo.yearOfAssessment[0].startDate)
                        ).format("DD MMM yy")} - ${moment(
                          new Date(basicInfo.yearOfAssessment[0].endDate)
                        ).format("DD MMM yy")}`
                      : "Year Of Assessment"}
                  </Typography> */}
                {/* </Box>
              </Grid> */}
                {/* <Grid item xs={1} md={1}>
                {!vendorDetails?.yearOfAssessment && (
                  <span
                    style={{
                      color: "red",
                      padding: "0px 3px",
                      display: "inline",
                    }}
                  >
                    *
                  </span>
                )}
              </Grid> */}

                <Grid item xs={12} md={11}>
                  <Box>
                    <label htmlFor="noOfEmp">Number Of Employees</label>
                    {!vendorDetails?.no_of_employees && (
                      <span
                        style={{
                          color: "red",
                          padding: "0px 3px",
                          display: "inline",
                        }}
                      >
                        *
                      </span>
                    )}
                    <Box
                      id="noOfEmp"
                      style={{ display: "flex", gap: "8px", marginTop: 5 }}
                    >
                      {[
                        "0-50",
                        "51-100",
                        "101-500",
                        "501-1000",
                        "1001-5000",
                        "Above 5000",
                      ].map((emp) => (
                        <Chip
                          disabled={existingVendor?.name ? true : false}
                          key={emp}
                          label={emp || null}
                          color={
                            vendorDetails.no_of_employees === emp
                              ? "primary"
                              : "default"
                          }
                          clickable
                          onClick={() => {
                            setVendorDetails((prev) => ({
                              ...prev,
                              no_of_employees: emp,
                            }));
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                  {/* <Box my={2}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Number of Employees"
                    onChange={(e) => {
                      setVendorDetails((prev) => ({
                        ...prev,
                        no_of_employees: e.target.value,
                      }));
                    }}
                  />
                </Box> */}
                </Grid>
                {/* <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="website"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Website"
                  />
                </Box>
              </Grid> */}

                {/* <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="contactEmail"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Contact Email"
                  />
                </Box>
              </Grid>  */}

                <Grid item xs={12} md={11}>
                  <Box my={2}>
                    <Typography className={classes.headerText}>
                      Company Address
                    </Typography>
                  </Box>

                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    item
                  >
                    <Grid item md={6}>
                      <CustomTextfield
                        disabled={existingVendor?.name ? true : false}
                        id="compStreet"
                        name="compStreet"
                        label="Street/location"
                        variant="outlined"
                        value={
                          (vendorDetails?.address &&
                            vendorDetails?.address?.street) ||
                          ""
                        }
                        fullWidth
                        size="small"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <RoomOutlinedIcon
                                fontSize="small"
                                color="disabled"
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setVendorDetails((prev) => ({
                            ...prev,
                            address: {
                              ...prev?.address,
                              street: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <CustomTextfield
                        disabled={existingVendor?.name ? true : false}
                        id="compLandmark"
                        name="compLandmark"
                        label="Landmark"
                        variant="outlined"
                        value={
                          (vendorDetails?.address &&
                            vendorDetails?.address?.landmark) ||
                          ""
                        }
                        fullWidth
                        size="small"
                        type="text"
                        onChange={(e) => {
                          setVendorDetails((prev) => ({
                            ...prev,
                            address: {
                              ...prev?.address,
                              landmark: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* country and state */}
                  <Grid
                    container
                    style={{ marginTop: 15 }}
                    justifyContent="space-between"
                    spacing={1}
                    item
                  >
                    <Grid item md={6}>
                      <Autocomplete
                        disabled={existingVendor?.name ? true : false}
                        name="compCountry"
                        id="countryCode"
                        options={brmData.countries.map((c) => c?.country_name)}
                        getOptionLabel={(option) => option || ""}
                        renderOption={(option) => option}
                        size="small"
                        value={
                          (vendorDetails?.address &&
                            vendorDetails?.address?.country) ||
                          ""
                        }
                        renderInput={(params) => (
                          <CustomTextfield
                            {...params}
                            label="Country"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            // setCompanyCountry(newValue);

                            setVendorDetails((prev) => ({
                              ...prev,
                              address: {
                                ...prev?.address,
                                country: newValue,
                              },
                            }));
                          }
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <CustomTextfield
                        disabled={existingVendor?.name ? true : false}
                        name="compState"
                        variant="outlined"
                        value={
                          (vendorDetails?.address &&
                            vendorDetails?.address?.state) ||
                          ""
                        }
                        fullWidth
                        size="small"
                        label="State"
                        onChange={(e) => {
                          setVendorDetails((prev) => ({
                            ...prev,
                            address: {
                              ...prev?.address,
                              state: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* <Box my={2}>
                  <CustomTextfield
                    name="compAddress"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Street/Location"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RoomOutlinedIcon fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            companyAddress: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    variant="outlined"
                    name="compLandmark"
                    fullWidth
                    size="small"
                    label="Landmark"
                    onChange={(e) => {
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            landMark: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box> */}
                  <Box my={2}>
                    <CustomTextfield
                      disabled={existingVendor?.name ? true : false}
                      name="compPincode"
                      variant="outlined"
                      value={
                        (vendorDetails?.address &&
                          vendorDetails?.address?.pinCode) ||
                        ""
                      }
                      fullWidth
                      size="small"
                      label="Pincode"
                      type="number"
                      onChange={(e) => {
                        setVendorDetails((prev) => ({
                          ...prev,
                          address: {
                            ...prev?.address,
                            pinCode: e.target.value,
                          },
                        }));
                      }}
                    />
                  </Box>
                  {/* <Box my={2}>
                  <CustomTextfield
                    name="compState"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="State"
                    onChange={(e) => {
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            state: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box my={2}>
                  <Autocomplete
                    name="compCountry"
                    id="countryCode"
                    options={brmData.countries.map((c) => c?.country_name)}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    size="small"
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Country"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        // setCompanyCountry(newValue);

                        setVendorDetails((prev) => ({
                          ...prev,
                          address: [
                            {
                              ...prev?.address[0],
                              country: newValue,
                            },
                          ],
                        }));
                      }
                    }}
                  />
                </Box> */}
                </Grid>

                <Grid item xs={12} md={12} style={{ paddingBottom: 10 }}>
                  {/* <CustomButton
                  className={classes.downloadButton}
                  style={{
                    float: "right",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                  disabled={!vendorDetails.isin}
                >
                  Create Portfolio & add Another
                </CustomButton> */}

                  <CustomButton
                    className={classes.downloadButton}
                    style={{
                      float: "right",
                      textTransform: "none",
                      fontSize: "13px",
                    }}
                    disabled={
                      !existingVendor?.name &&
                      (!vendorDetails.vendorName ||
                        !vendorDetails.vendorWebsite ||
                        // !vendorDetails.userName ||
                        // !vendorDetails.userDesignation ||
                        !vendorDetails.isin ||
                        !vendorDetails.no_of_employees ||
                        !compayLogo ||
                        // !vendorDetails.yearOfAssessment ||
                        !isSectorSelected() ||
                        !vendorDetails?.address?.country ||
                        revenueData[revenueData.length - 1]?.revenue === "" ||
                        revenueData[revenueData.length - 1]?.currency === "" ||
                        revenueData[revenueData.length - 1]?.figures === "" ||
                        revenueData[revenueData.length - 1]?.year === "" ||
                        revenueData?.length === 0)

                      // ||
                      // !revenueData.filter(
                      //   (d) =>
                      //     d.turnover !== "" &&
                      //     d.year !== "" &&
                      //     d.figures !== "" &&
                      //     d.currency !== ""
                      // ).length
                    }
                    onClick={() => {
                      handleAddVendor();
                      setVendorDetails({});
                      setCompanyLogoURI("");
                    }}
                    // onClick={handleCreateOrganization}
                  >
                    Create Company
                  </CustomButton>
                  <CustomButton
                    className={classes.downloadButton}
                    style={{
                      float: "right",
                      textTransform: "none",
                      fontSize: "13px",
                    }}
                    onClick={handleAddCompanyDialogClose}
                  >
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid xs={7}></Grid>
                <Grid item xs={9}>
                  {/* {LocationStore} */}
                </Grid>
              </Grid>
            ) : (
              // active step 2
              <Box
                id="step2"
                style={{
                  overscrollBehavior: "contain",
                  width: 1080,
                  height: "100vh",
                  padding: "0px 20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {/* Step 2 Title Box */}
                <Box
                  style={{
                    width: "100%",
                    height: "160px",
                    backgroundColor: "#EBF1F8",
                    display: "flex",
                    flexDirection: "column",
                    padding: "8px",
                    gap: "8px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#15314E",
                      fontWeight: 600,
                      fontSize: "20px",
                    }}
                  >
                    RT Details or real time details
                  </Typography>

                  <Typography
                    style={{
                      color: "#5C90C7",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    Refer to information or data that is updated in real-time.
                    For a vendor, real-time details might include current stock
                    levels, pricing, order status, or any other dynamic
                    information related to their products or services.
                  </Typography>
                </Box>

                {/* RT .01.02 table */}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#EBF1F8",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#15314E",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      RT .01.02
                    </Typography>
                  </Box>

                  {/* table 1 */}
                  <Table>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#EBF1F8" }}>
                        <TableCell style={tableCellStyle}>
                          LEI of the financial entity
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Name of the financial entity
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Country of the financial entity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Render a single empty row */}
                    <TableRow>
                      <TableCell style={tableCellStyle}>&nbsp;</TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                    </TableRow>
                  </Table>

                  {/* table 2 */}
                  <Table>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#EBF1F8" }}>
                        <TableCell style={tableCellStyle}>
                          Date of last update
                        </TableCell>
                        <TableCell style={tableCellStyle}>Currency</TableCell>
                        <TableCell style={tableCellStyle}>
                          Type of the entity maintaining the register of
                          information
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Render a single empty row */}
                    <TableRow>
                      <TableCell style={tableCellStyle}>&nbsp;</TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                    </TableRow>
                  </Table>
                </Box>

                {/* RT .02.01 table */}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#EBF1F8",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#15314E",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      RT .02.01
                    </Typography>
                  </Box>

                  {/* table 1 */}
                  <Table>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#EBF1F8" }}>
                        <TableCell style={tableCellStyle}>
                          Contractual arrangement reference number
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Type of contractual arrangement
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Overarching contractual arrangement reference number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Render a single empty row */}
                    <TableRow>
                      <TableCell style={tableCellStyle}>&nbsp;</TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                    </TableRow>
                  </Table>

                  {/* table 2 */}
                  <Table>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#EBF1F8" }}>
                        <TableCell style={tableCellStyle}>
                          Annual expense or estimated cost of the contractual
                          arrangement for the past year
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Budget of the contractual arrangement for the upcoming
                          year
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Approval from the management body
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Render a single empty row */}
                    <TableRow>
                      <TableCell style={tableCellStyle}>&nbsp;</TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                    </TableRow>
                  </Table>

                  {/* table 3 */}
                  <Table>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#EBF1F8" }}>
                        <TableCell style={tableCellStyle}>
                          Other code to identify the ICT third-party service
                          provider
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Country of issuance of the other code to identify the
                          ICT third-party service provider
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          Type of other code to identify the ICT third-party
                          service provider
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Render a single empty row */}
                    <TableRow>
                      <TableCell style={tableCellStyle}>&nbsp;</TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                      <TableCell style={tableCellStyle}></TableCell>
                    </TableRow>
                  </Table>
                </Box>

                <Grid item xs={12} md={12} style={{ padding: "10px" }}>
                  {/* <CustomButton
                  className={classes.downloadButton}
                  style={{
                    float: "right",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                  disabled={!vendorDetails.isin}
                >
                  Create Portfolio & add Another
                </CustomButton> */}

                  <CustomButton
                    className={classes.downloadButton}
                    style={{
                      // float: "right",
                      textTransform: "none",
                      fontSize: "13px",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                    disabled={
                      !existingVendor?.name &&
                      (!vendorDetails.vendorType ||
                        !vendorDetails.vendorName ||
                        !vendorDetails.vendorWebsite ||
                        // !vendorDetails.userName ||
                        // !vendorDetails.userDesignation ||
                        !vendorDetails.isin ||
                        !vendorDetails.no_of_employees ||
                        !compayLogo ||
                        // !vendorDetails.yearOfAssessment ||
                        !isSectorSelected() ||
                        !vendorDetails?.address?.country ||
                        revenueData[revenueData.length - 1]?.revenue === "" ||
                        revenueData[revenueData.length - 1]?.currency === "" ||
                        revenueData[revenueData.length - 1]?.figures === "" ||
                        revenueData[revenueData.length - 1]?.year === "" ||
                        revenueData?.length === 0)

                      // ||
                      // !revenueData.filter(
                      //   (d) =>
                      //     d.turnover !== "" &&
                      //     d.year !== "" &&
                      //     d.figures !== "" &&
                      //     d.currency !== ""
                      // ).length
                    }
                    // onClick={handleCreateOrganization}
                    onClick={() => {
                      handleAddVendor();
                      setActiveSteps(0);
                      setVendorDetails({});
                      setCompanyLogoURI("");
                    }}
                  >
                    Create Company
                  </CustomButton>
                  <CustomButton
                    className={classes.downloadButton}
                    style={{
                      float: "right",
                      textTransform: "none",
                      fontSize: "13px",
                    }}
                    onClick={() => setActiveSteps(0)}
                  >
                    Back
                  </CustomButton>
                </Grid>
              </Box>
            )}
          </div>
          {/* </CardContent>
      </Card> */}
        </div>
      </Drawer>
    );
  };

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    // console.log("new mount", vendorDetails.yearOfAssessment);
    vendorDetails.yearOfAssessment &&
      setStartYear(vendorDetails.yearOfAssessment?.split("-")[0]);
    vendorDetails.yearOfAssessment &&
      setEndYear(vendorDetails.yearOfAssessment?.split("-")[1]);
  }, [vendorDetails.yearOfAssessment]);

  useEffect(() => {
    const data = [];
    if (!vendorDetails.yearOfAssessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (vendorDetails.yearOfAssessment) {
      for (let i = 0; i < 9; i++) {
        data.push(vendorDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                item
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setVendorDetails((prev) => ({
                  ...prev,
                  yearOfAssessment: "",
                }));
                vendorDetails.yearOfAssessment &&
                  setStartYear(vendorDetails.yearOfAssessment.split("-")[0]);
                vendorDetails.yearOfAssessment &&
                  setEndYear(vendorDetails.yearOfAssessment.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setVendorDetails((prev) => ({
                  ...prev,
                  yearOfAssessment: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  useEffect(() => {
    if (
      brmData.organizationDetails &&
      brmData?.organizationDetails[0]?.sectors &&
      brmData.organizationDetails[0].sectors?.length > 0
    ) {
      let dummy = JSON.parse(JSON.stringify(dummyIndustries));
      let masterSectors = [...dummy];
      masterSectors.map(
        (masterSec, masterSecIdx) =>
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map((basicInd) =>
                masterInd._id === basicInd
                  ? (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = true)
                  : (masterSectors[masterSecIdx].industry_data[
                      masterIndIdx
                    ].isSelected = false)
              )
            )
          )
      );

      setSectors(masterSectors);
    } else {
      let dummy = JSON.parse(JSON.stringify(dummyIndustries));
      setSectors(dummy);
    }
  }, [
    brmData.sectorIndustries,
    isAddcompanyDrawer,
    brmData.organizationDetails,
  ]);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    // if(sectorIndex !== )
    // console.log(brmData.sectorIndustries)
    let sectorL = JSON.parse(JSON.stringify(sectors));

    // ---------------- select multiple industries ----------------
    ////////////////
    //  comment this code to select multiple sectors & industries
    // sectorL = sectorL.map((sect, i) => {
    //   if (sectorIndex !== i) {
    //     sect.industry_data.map((ind, j) => {
    //       if (sectorIndex === i && industryIndex === j) {
    //         ind.isSelected = true;
    //       } else {
    //         ind.isSelected = false;
    //       }
    //       return ind;
    //     });
    //   }
    //   return sect;
    // });
    ///////////////////

    // sectorL[sectorIndex].industry_data[industryIndex].isSelected =
    //   !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    // console.log('sectorL',sectorL);
    // -------------------------------------------------------------

    // ---------------- select single industry ----------------
    // Unselect all industries in all sectors
    sectorL = sectorL.map((sector) => ({
      ...sector,
      industry_data: sector.industry_data.map((industry) => ({
        ...industry,
        isSelected: false,
      })),
    }));

    // Select the clicked industry
    sectorL[sectorIndex].industry_data[industryIndex].isSelected = true;

    // Unselect all other sectors
    sectorL = sectorL.map((sector, index) => ({
      ...sector,
      isSelected: index === sectorIndex,
    }));
    // ----------------------------------------------------------------

    setSectors(sectorL);
    setIsSelectedSector(sectorIndex);
  };

  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={() => setIsSectorDialogOpen(false)}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    key={sector.Sector}
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                    name={sector.Sector}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        id={sector.Sector}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => {
                        return (
                          <div
                            key={industry.industry}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onChange={() => handleIndustryChange(index2, index)}
                          >
                            {/* {industry.isSelected} */}
                            <CustomCheckBox
                              key={industry.industry + index2}
                              checked={industry.isSelected ? true : false}
                              name={industry.Industry}
                            />
                            <Typography id={industry.Industry}>
                              {industry.Industry}
                            </Typography>
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div style={{ marginBottom: 8 }} key={sector._id}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <div key={sector.Sector}>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  key={industry.industry}
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setIsSectorDialogOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth="true" maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid2}>
            <Grid item xs={4}>
              <Typography className={classes.Typography3}>
                Share Report
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            // style={{}}
          >
            <Grid item md={12} className={classes.grid8}>
              <Paper elevation={0} className={classes.Paper}>
                <Autocomplete
                  size="small"
                  multiple
                  // value={emails}
                  options={
                    generalSettings.allUsers &&
                    generalSettings?.allUsers.map((user) => user.email)
                  }
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <>
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      </>
                    ));
                  }}
                  onChange={(event, newValue) => {
                    // setEmails(newValue);
                  }}
                  renderInput={(params) => (
                    <>
                      <CustomTextfield
                        {...params}
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Search By Emai ID or Name"
                        name="asgEmailStep3"
                      />
                    </>
                  )}
                />
              </Paper>
            </Grid>
            {true && (
              <>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    // onChange={stakeHolderContentChange}
                    helperText="hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.grid8}>
                  <Typography className={classes.Typography5}>
                    Description
                  </Typography>
                </Grid>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    name="content"
                    multiline="true"
                    onChange={(e) => {
                      // stakeHolderContentChange(e);
                    }}
                    placeholder="Describe the Report"
                    className={classes.CustomTextfield}
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={8} md={8} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShareDialogOpen(false);
                  // setEmails([]);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={4} md={4} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  history.push("/corporate_questions");
                  setShareDialogOpen(false);
                }}
              >
                Share Report
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };

  const handleUploadCompanyLogo = async (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];

    // const imgData = await upload_create_vendor_img(file);
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    return () => {
      setSectors([]);
    };
  }, []);
  const handleAddCompanyDialogClose = () => {
    setSectors(brmData.sectorIndustries);
    setIsAddCompanyDrawer(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const NUMBER_CFG = { count: 4, min: 1, max: 20 };
  var _seed = Date.now();
  const rand = (min, max) => {
    min = valueOrDefault(min, 0);
    max = valueOrDefault(max, 0);
    _seed = (_seed * 9301 + 49297) % 233280;
    return min + (_seed / 233280) * (max - min);
  };
  function numbers(config) {
    var cfg = config || {};
    var min = valueOrDefault(cfg.min, 0);
    var max = valueOrDefault(cfg.max, 100);
    var from = valueOrDefault(cfg.from, []);
    var count = valueOrDefault(cfg.count, 8);
    var decimals = valueOrDefault(cfg.decimals, 8);
    var continuity = valueOrDefault(cfg.continuity, 1);
    var dfactor = Math.pow(10, decimals) || 0;
    var data = [];
    var i, value;

    for (i = 0; i < count; ++i) {
      value = (from[i] || 0) + rand(min, max);
      if (rand() <= continuity) {
        data.push(Math.round(dfactor * value) / dfactor);
      } else {
        data.push(null);
      }
    }
    return data;
  }

  const CHART_COLORS = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
  };
  const labels = ["a", "b", "c", "d"];

  const options = {
    maintainAspectRatio: false,
    // aspectRatio: 1
    scales: {
      // r: {
      //   suggestedMin: 0,
      //   suggestedMax: 1,
      // },
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    chartArea: {
      backgroundColor: "blue",
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      // tooltip: false,
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
      beforeDraw: (chart, steps, options) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = "white";
        ctx.restore();
      },
    },
    elements: {
      arc: {
        backgroundColor: "white",
        hoverBackgroundColor: "25px",
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Environment",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.red,
        stack: "Stack 0",
      },
      {
        label: "Social ",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.blue,
        stack: "Stack 0",
      },
      {
        label: "Dataset 3",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.green,
        stack: "Stack 0",
      },
      {
        label: "Dataset 4",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.yellow,
        stack: "Stack 0",
      },
      {
        label: "Dataset 5",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.orange,
        stack: "Stack 0",
      },
    ],
  };

  const config = {
    type: "bar",
    data: data,
    options: {
      plugins: {
        title: {
          display: true,
          text: "Chart.js Bar Chart - Stacked",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        // y: {
        //   stacked: true
        // }
      },
    },
  };

  // const data = {
  //   labels: [
  //     "Workforce",
  //     "Community",
  //     "Human Rights",
  //     "Management",
  //     "Product responsiblity",
  //     "Resource use",
  //     "Stakeholders",ISIN
  //   ],
  //   datasets: [
  //     {
  //       label: "Company1",
  //       data: [0.88, 0.99, 0.3, 0.6, 0.7, 0.89, 0.9],
  //       // data: chartEsgData,
  //       backgroundColor: "#3374b933",
  //       borderColor: "#3374b9",
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // handle Create organization API

  const handleAddVendor = async () => {
    setIsAddCompanyDrawer(false);

    const filteredSector = sectors.map((e) => {
      let indData = [];
      e?.industry_data.map((ind) => {
        if (ind?.isSelected === true) {
          indData.push(ind._id);
        }
        return ind;
      });

      if (indData.length > 0)
        return {
          sector: e._id,
          industry: indData, // set industry to indData only for multiple industry selection
        };
      return false;
    });

    const formattedSector = filteredSector.filter((e) => e);
    const sectorObj = {
      sector:
        formattedSector[0]?.sector || vendorDetails?.sector?.sector?.sectorId,
      industry:
        formattedSector[0]?.industry[0] ||
        vendorDetails?.sector?.industry?.industryId,
    };

    await dispatch(
      gql_create_company(
        vendorDetails?.vendorName,
        vendorDetails?.vendorWebsite,
        compayLogo, // change to file when uploading to backend
        sectorObj,
        vendorDetails?.isin?.toUpperCase(),
        vendorDetails?.operation,
        vendorDetails?.no_of_employees,
        vendorDetails?.address,
        login?.token,
        revenueData,
        turnoverData
      )
    );

    // console.log(filteredSector);
    setExistingVendor({});
    setUrl("");
    setRevenueData([]);
    setTurnoverData([]);
    dispatch(gql_get_companies_list(8, 1));
  };

  function handleCreateOrganization() {
    // console.log("revenueData", revenueData);
    props.createVendorOrganization(
      login?.token,
      login.bankId,
      vendorDetails?.vendorName,
      vendorDetails?.isin,
      vendorDetails?.yearOfAssessment,
      compayLogo,
      sectors,
      vendorDetails?.operation,
      vendorDetails?.no_of_employees,
      vendorDetails?.address,
      brmData.sectorIndustries,
      rowsPerPage,
      revenueData.filter((d) => d.revenue !== "" && d.year !== ""),
      turnoverData.filter((d) => d.turnover !== "" && d.year !== ""),
      vendorDetails?.country_code
    );
    setIsAddCompanyDrawer(false);
    // setCompaniesData(viewOrganizations.result);
  }

  const handleViewDetailsOrganization = (selectedOrg) => {
    // props.viewVendorDetailsOrganization(props.login?.token, id);
    localStorage.setItem("organization_id", selectedOrg._id);
    dispatch(selectVendor([selectedOrg]));

    history.push("/borrower/company_details", {
      data: props.brmData.organizationDetails,
      organization: selectedOrg._id,
    });
  };

  const viewCompanies = (toMap) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);

      // Options to format the date
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      // Format the date using options
      const formattedDate = date.toLocaleDateString(undefined, options);

      return formattedDate;
    };
    return (
      toMap
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => (
          <div
            key={row._id}
            style={{
              display: "flex",
              borderBottom: "1px solid #EBF1F8",
              borderRadius: 5,
              padding: "5px 0px 5px 5px",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: isHover[row?._id] ? "#EBF1F8" : "#FEFEFE",
            }}
            onClick={() => handleViewDetailsOrganization(row)}
            onMouseEnter={() =>
              setHover((preValue) => ({ ...preValue, [row?._id]: true }))
            }
            onMouseLeave={() =>
              setHover((preValue) => ({ ...preValue, [row?._id]: false }))
            }
          >
            <div style={{ width: "40%", display: "flex" }}>
              <img alt="Doc" src={Doc} style={{ width: 18, height: 19 }} />
              {/* <Icon
                style={{ cursor: "pointer", color: "#15314E" }}
                onClick={() => handleViewDetailsOrganization(row._id)}
                fontSize="small"
              >
                descriptionicon
              </Icon> */}
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "0px 5px",
                }}
              >
                {row?.name}
              </Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "0px 5px",
                }}
              >
                {row?.updatedAt ? formatDate(row?.updatedAt) : "N/A"}
              </Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography
                style={{ diaplay: "inline-flex", alighItems: "center" }}
              >
                <Box
                  display="inline-flex"
                  alignItems="center"
                  // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                  p={1}
                  style={{
                    padding: "8px",
                  }}
                  borderRadius="8px"
                >
                  <Box
                    borderRadius="50%"
                    height={10}
                    width={10}
                    bgcolor={row?.status === null ? "#FFC250" : "#5BB37F"}
                  />
                  <Box
                    style={{
                      paddingLeft: "5px",
                      fontSize: "14px",
                      color: row?.status === null ? "#FFC250" : "#5BB37F",
                    }}
                  >
                    {row?.status === null ? "Incomplete" : "Completed"}
                  </Box>
                </Box>
              </Typography>
            </div>
            <div style={{ width: "18%" }} align="center">
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "0px 5px",
                }}
              >
                {row?.address?.country || "N/A"}
              </Typography>
            </div>
            <div style={{ width: "8%" }} align="center">
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "0px 5px",
                }}
              >
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleViewDetailsOrganization(row._id)}
                  fontSize="small"
                >
                  more_vert
                </Icon>
              </Typography>
            </div>
          </div>
        ))
    );
  };
  const { dahboardPaginationPage } = useSelector(
    (state) => state?.riskDashboard
  );

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(8);

  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [startingAfter, setStartingAfter] = useState(
    dahboardPaginationPage * rowsPerPage - rowsPerPage
  );

  useEffect(() => {
    // totalOrganizations
    dispatch(gql_get_companies_list(8, page));
    dispatch({
      type: SET_SEARCH_COMPANIES_BY_NAME,
      payload: { companyRes: [], query: "" },
    });
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // console.log(startingAfter);
  const handleChangeLocalPagination = (event, newPage) => {
    // rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // console.log("new ", page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setPage(newPage - 1);
  };

  // pagination function
  const handleChangePage = (event, value) => {
    // setStartingAfter(value * rowsPerPage - rowsPerPage);
    // dispatch(setDashboardPage(value));
    // return;

    setPage(value);
    console.log(value);
  };

  useEffect(() => {
    // login?.token &&
    //   props.viewVendorOrganization(
    //     login?.token ? login?.token : localStorage.getItem("user_token"),
    //     login.bankId ? login.bankId : localStorage.getItem("bank_id"),
    //     rowsPerPage,
    //     startingAfter
    //   );
  }, [login.token, startingAfter]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    // props?.getSectorIndustries(
    //   login?.token ? login?.token : localStorage.getItem("user_token")
    // );
    // props?.getCountries(
    //   login?.token ? login?.token : localStorage.getItem("user_token")
    // );
  }, []);

  // console.log(
  //   Math.ceil(brmData?.totalOrganizations / 8),
  //   brmData?.totalOrganizations
  // );

  const [companies, setCompanies] = useState([]);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  useEffect(() => {
    // brmData?.organizationsList &&
    //   brmData?.organizationsList.length > 0 &&
    //   brmData?.organizationsList.filter((comp) => {
    //     return comp.name === searchCompanyText;
    //   });
    // brmData?.organizationsList &&
    // brmData?.organizationsList.length > 0 &&
    // searchCompanyText
    //   ? setCompanies(
    //       brmData?.organizationsList.filter((comp) => {
    //         return comp.name
    //           ?.toLowerCase()
    //           .includes(searchCompanyText?.toLowerCase());
    //       })
    //     )
    //   : setCompanies([]);
  }, [searchCompanyText]);

  const fetchSearchCompanies = useCallback(
    debounce((searchQuery) => {
      if (searchQuery !== "") {
        dispatch(gql_get_companies_by_name(searchQuery));
      }

      if (searchQuery === "") {
        dispatch({
          type: SET_SEARCH_COMPANIES_BY_NAME,
          payload: { companyRes: [], query: "" },
        });
      }
    }, 500),
    []
  );

  const handleSearchCompanies = (query) => {
    fetchSearchCompanies(query);
  };

  useDebounce(
    () => {
      // props.viewVendorOrganization(
      //   login?.token ? login?.token : localStorage.getItem("user_token"),
      //   login.bankId ? login.bankId : localStorage.getItem("bank_id"),
      //   rowsPerPage,
      //   startingAfter,
      //   searchCompanyText
      // );
    },
    2000,
    [searchCompanyText]
  );
  return (
    <>
      {yearPickerDialog()}
      {isSectorDialogOpen && sectorIndustry(login, sectors)}
      {isAddcompanyDrawer && addCompanyDrawer()}
      {/* {isAddcompanyDrawer && (
        <VendorDrawer
          openDrawer={isAddcompanyDrawer}
          closeDrawer={handleAddCompanyDialogClose}
        />
      )} */}
      {shareDialogOpen && shareDialog()}
      <div>
        {/* un comment the condition to make it only for rm */}
        {/* {!props.isEvaluator && ( */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{ fontWeight: 600, fontSize: "16px", marginRight: "auto" }}
          >
            Portfolio
          </Typography>
          <div style={{ width: "30%", marginRight: 15 }}>
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search"
              onChange={(e) => {
                handleSearchCompanies(e.target.value);
                setSearchCompanyText(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchCompanyText}
            />
          </div>
          <div>
            <Button
              onClick={() => {
                setIsAddCompanyDrawer(true);
              }}
              className={classes.createButton}
              size="small"
            >
              <AddIcon
                className={classes.headerIcon}
                color="primary"
                variant="outlined"
              />
              Create Company
            </Button>
          </div>
          <Pagination
            style={{ paddingRight: 10 }}
            count={Math.ceil(brmData?.totalOrganizations / 8)}
            // siblingCount={0}
            defaultPage={1}
            onChange={handleChangePage}
            variant="outlined"
            color="primary"
            size="small"
            page={page}
            // shape="rounded"
          />
        </div>
      </div>
      <div>
        <Grid style={{ padding: "10px 60px", marginTop: "25px" }}>
          <div
            style={{
              display: "flex",
              borderRadius: 5,
              backgroundColor: "#EBF1F8",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Typography
                style={{
                  // fontSize: "14px",
                  // fontWeight: 500,
                  padding: "10px 10px",
                }}
              >
                Company Name
              </Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography> Last Modified</Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography> Status</Typography>
            </div>
            <div style={{ width: "18%" }} align="center">
              <Typography> Country</Typography>
            </div>
            <div style={{ width: "8%" }} align="center">
              <Typography> Actions</Typography>
            </div>
          </div>

          <div style={{ marginTop: "15px" }}>
            {brmData?.organizationsList &&
            brmData?.organizationsList?.length > 0 ? (
              viewCompanies(
                brmData?.companyQuery !== ""
                  ? brmData?.companiesByName
                  : brmData?.organizationsList
              )
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                No Vendors Found.
              </Typography>
            )}

            <Typography style={{ textAlign: "center", marginTop: "25px" }}>
              {brmData?.companyQuery !== "" &&
                brmData?.companiesByName?.length === 0 &&
                "No Company Found By This Name or ISIN"}
            </Typography>
          </div>
          {/* <div style={{ marginTop: "15px" }}>
            {companies.length > 0 ? (
              searchCompanyText ? (
                viewCompanies(companies)
              ) : (
                <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                  No Data Found.
                </Typography>
              )
            ) : !searchCompanyText &&
              brmData?.organizationsList &&
              brmData?.organizationsList.length > 0 ? (
              viewCompanies(brmData?.organizationsList)
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                No Companies Added.
              </Typography>
            )}
          </div> */}

          {/* {Array.isArray(brmData?.organizationsList) &&
          brmData?.organizationsList.lenth > 0 &&
          searchCompanyText !== "" ? (
            viewCompanies(brmData?.organizationsList)
          ) : companies.length > 0  ? (
            viewCompanies(companies)
          ) : (
            <Typography style={{ textAlign: "center", marginTop: "25px" }}>
              No Data Found.
            </Typography>
          )} */}
        </Grid>
      </div>
    </>
  );
}

export default VendorOrganizations;
