import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import RouterCont from "./containers/router/routerCont";
import { ApolloProvider } from "@apollo/client";

import { client } from "./redux/store/store";

const theme = createTheme({
  palette: {
    warning: { main: "#C45446" },
  },
  typography: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  overrides: {
    MuiTabs: {
      indicator: {
        color: "green",
        // border:"1px solid red",
        backgroundColor: "#32A05F",
      },
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <RouterCont />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
