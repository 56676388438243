// !------------- A children can be passed (no other things are needed) -------------!

import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Breadcrumbs, Typography, Box } from "@material-ui/core";

import { IoIosArrowForward } from "react-icons/io";

export const ActiveCrumbStyle = (isActive) => {
  return {
    color: isActive ? "#15314E" : "",
    fontWeight: isActive ? 600 : 400,
    fontSize: "16px",
    lineHeight: "24px",
    cursor: isActive ? "default" : "pointer",
    textTransform: "capitalize",
  };
};

const MainHeader = ({ children, mainCrumb }) => {
  const location = useLocation();
  const history = useHistory();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const startIndex = 1; // Skip the first segment

  const handleClick = (index) => {
    const routeTo = `/${pathnames.slice(0, index + startIndex + 1).join("/")}`;
    history.push(routeTo);
  };

  // Create an array to store Typography components
  const breadcrumbs = [];

  breadcrumbs.push(
    <Typography
      key="main"
      color="inherit"
      style={ActiveCrumbStyle(pathnames.length === 1)}
      onClick={() => {
        if (pathnames.length !== 1) history.push(`/${pathnames[0]}`);
      }}
    >
      {mainCrumb || "Survey"}
    </Typography>
  );

  pathnames.slice(startIndex).forEach((name, index) => {
    const isLast = index + 1 === pathnames.length - 1;
    breadcrumbs.push(
      <Typography
        key={index}
        color={"inherit"}
        style={ActiveCrumbStyle(isLast)}
        onClick={() => {
          if (!isLast) handleClick(index);
        }}
      >
        {name.replace(/_/g, " ")}
      </Typography>
    );
  });

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 20px",
      }}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<IoIosArrowForward style={{ color: "#A1BFDF" }} />}
      >
        {breadcrumbs} {/* Pass the array as children */}
      </Breadcrumbs>

      {/* other options */}
      {children}
    </Box>
  );
};

export default MainHeader;
