import { Box, Typography } from "@material-ui/core";

import CustomButton from "../../../../../../../../UI/button/button";
import upload from "../../../../../../../../../images/uploadGreen.png";
import download from "../../../../../../../../../images/downloadGreenNew.png";

const QuestionListHeader = ({ selectedSection }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Typography
        style={{
          color: "#15314E",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        {selectedSection?.title}
      </Typography>
      {!selectedSection?.default && (
        <Box>
          <CustomButton
            style={{
              textTransform: "none",
              marginRight: 10,
              borderRadius: "8px",
            }}
            size="small"
            color="secondary"
            variant="outlined"
          >
            <img
              src={upload}
              style={{ height: "20px", marginRight: "5px" }}
              alt="upload"
            />
            <Typography>Upload Sheet</Typography>
          </CustomButton>
          <CustomButton
            style={{
              textTransform: "none",
              marginRight: 10,
              borderRadius: "8px",
            }}
            size="small"
            color="secondary"
            variant="outlined"
          >
            <img
              src={download}
              style={{ height: "20px", marginRight: "5px" }}
              alt="upload"
            />

            <Typography>Download Sheet</Typography>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default QuestionListHeader;
