import React, { useEffect, useRef, useState } from "react";

import {
  Grid,
  InputAdornment,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { UserCard } from "./UserCard";
import CustomButton from "../../../../../UI/button/button";
import CustomTextfield from "../../../../../UI/textfield/textfield";
import noUser from "../../../../../../images/NoUser.png";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_all_stakeholders } from "../../../../../../redux/actions/vendorActions/vendorAction";
const brmRightStyles = makeStyles(() => ({
  cardContainerDefault: {
    width: "100%",
    height: "8.4rem",
    // height: "125px" || '8.4rem',
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },

  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },
}));

export function NoDataComponent({
  imgSrc,
  mainTitle,
  subTitle,
  buttonTex,
  handleButtonClick,
}) {
  const classes = brmRightStyles();
  return (
    <div
      className="scroll"
      style={{
        height: "59vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "70%",
        }}
      >
        <img
          className={classes.emptyImg}
          style={{ width: "40%" }}
          src={imgSrc}
          alt="analytics"
        />
        <Typography
          style={{ fontSize: "16px", fontWeight: 500, textAlign: "center" }}
          className={classes.text}
        >
          {mainTitle}
        </Typography>
        <Typography
          style={{ fontSize: "14px", fontWeight: 500, textAlign: "center" }}
          className={classes.subText}
        >
          {subTitle}
        </Typography>
        {buttonTex && (
          <Grid container justifyContent="center">
            <CustomButton
              className={classes.uploadBtn}
              variant="contained"
              color="primary"
              onClick={() => {
                handleButtonClick(true);
              }}
              size="small"
            >
              {buttonTex}
            </CustomButton>
          </Grid>
        )}
      </div>
    </div>
  );
}

function StakeholderList({ setAddNewUserDialogOpen }) {
  const classes = brmRightStyles();
  const [filtered, setFiltered] = useState([]);
  const text = useRef("");
  const dispatch = useDispatch();

  const { login, brmData } = useSelector((state) => state);
  // console.log(login);

  // console.log(brmData?.members);

  useEffect(() => {
    dispatch(gql_get_all_stakeholders(login?.companyId));
  }, []);

  const handleSearch = (e) => {
    const searchText = e.target.value.trim().toLowerCase();
    text.current = searchText;
    if (brmData?.members && brmData?.members.length > 0 && searchText) {
      const filter = brmData?.members.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchText) ||
          item.email.toLowerCase().includes(searchText)
        );
      });
      setFiltered(filter);
    } else if (brmData?.members && brmData?.members.length > 0 && !searchText) {
      setFiltered(brmData?.members);
    } else {
      setFiltered([]);
    }
  };
  return (
    <div style={{}} className={classes.mainDiv}>
      {brmData?.members && brmData?.members.length > 0 && (
        <div className="scroll" style={{ height: "59vh" }}>
          <CustomTextfield
            name="Search"
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            label="Search by employee name/ email/ Job/ Id"
            onChange={handleSearch}
            style={{ margin: "8px 0px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "#3374B9" }} />
                </InputAdornment>
              ),
            }}
            // value={searchCompanyText}
          />

          {text.current ? (
            filtered.length > 0 ? (
              filtered.map((member) => (
                <UserCard
                  hideCheckbox={true}
                  key={member.name}
                  member={member}
                  onClick={() => {}}
                />
              ))
            ) : (
              <Typography>No Users Found</Typography>
            )
          ) : (
            brmData?.members?.map((member) => (
              <UserCard
                hideCheckbox={true}
                key={member?._id}
                member={member}
                onClick={() => {}}
              />
            ))
          )}
        </div>
      )}
      {brmData?.members && brmData?.members.length === 0 && (
        <NoDataComponent
          imgSrc={noUser}
          mainTitle={"Currently no User has been uploaded to showcase"}
          subTitle={"Upload file type (.xls, .pdf, .doc) Max size of 5 Mb"}
          buttonTex={"Add User"}
          handleButtonClick={() => setAddNewUserDialogOpen(true)}
        />
      )}
    </div>
  );
}

export default StakeholderList;
