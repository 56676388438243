import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query Companies($pagination: PaginationInput!) {
    companies(pagination: $pagination) {
      count
      companies {
        _id
        name
        active
        updatedAt
        address {
          country
        }
        status
      }
    }
  }
`;

// export const GET_VENDOR = gql``;

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String!
    $website: String!
    $isin: String!
    $operation: String
    $employeesCount: String!
    $sector: SectorInput!
    $address: AddressInput!
    $revenue: [RevenueInput!]!
    $turnover: [TurnoverInput!]
  ) {
    createCompany(
      createCompanyInput: {
        name: $name
        website: $website
        isin: $isin
        operation: $operation
        employeesCount: $employeesCount
        sector: $sector
        address: $address
        revenue: $revenue
        turnover: $turnover
      }
    ) {
      _id
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateCompany(
    $id: String!
    $updateCompanyInput: UpdateCompanyInput!
  ) {
    updateCompany(id: $id, updateCompanyInput: $updateCompanyInput) {
      _id
      createdAt
      createdBy
      isin
      employeesCount
      logo {
        name
        type
        url
      }
      name
      updatedAt
      updatedBy
      rtDetails {
        annualExpenseOrEstimatedCostOfTheContractualArrangementForThePastYear
        approvalFromTheManagementBody
        budgetOfTheContractualArrangementForTheUpcomingYear
        contractualArrangementReferenceNumber
        countryOfTheFinancialEntity
        countryOfTheIssuanceOfTheOtherCodeToIdentifyTheICTThirdPartyServiceProvider
        currency
        dateOfLastUpdate
        leiOfTheFinancialEntity
        nameOfTheFinancialEntity
        otherCodeToIdentifyTheICTThirdPartyServiceProvider
        overarchingContractualArrangementReferenceNumber
        typeOfContractualArrangement
        typeOfEntityMaintainingRegisterOfInformation
        typeOfOtherCodeToIdentifyTheICTThirdPartyServiceProvider
      }
    }
  }
`;

export const GET_ALL_STAKEHOLDERS = gql`
  query StakeholderList($companyId: String!) {
    stakeholderList(companyId: $companyId) {
      _id
      email
      name
      designation
      category
    }
  }
`;

export const ADD_NEW_STAKEHOLDERS = gql`
  mutation AddStakeholder(
    $email: String!
    $name: String!
    $companyId: ID!
    $category: String!
    $designation: String!
  ) {
    addStakeholder(
      addStakeholderInput: {
        email: $email
        name: $name
        companyId: $companyId
        category: $category
        designation: $designation
      }
    ) {
      message
      success
    }
  }
`;

export const GET_VENDOR_BASIC_DETAILS = gql`
  query Company($companyId: String!) {
    company(id: $companyId) {
      _id
      isin
      address {
        _id
        country
        district
        landmark
        location
        pincode
        state
      }
      sector {
        sector {
          _id
          sector
        }
        industry {
          _id
          industry
        }
      }
      name
      operation
      website
      employeesCount
      logo {
        name
        type
        url
      }
      revenue {
        revenue
        currency
        year
        figures
      }
      turnover {
        turnover
        currency
        year
        figures
      }
    }
  }
`;

export const REQUEST_FILES_STAKEHOLDERS = gql`
  mutation RequestFiles(
    $stakeholders: [ID!]!
    $companyId: ID!
    $docs: [VendorDocumentInput!]!
  ) {
    requestFiles(
      requestFilesInput: {
        docs: $docs
        stakeholders: $stakeholders
        companyId: $companyId
      }
    ) {
      message
      success
    }
  }
`;

export const GET_BANK_ADMIN_SURVEYS_FOR_LANDING_PAGE = gql`
  query Surveys(
    $bankId: String!
    $companyId: String
    $pagination: PaginationInput!
  ) {
    surveys(bankId: $bankId, companyId: $companyId, pagination: $pagination) {
      count
      surveys {
        _id
        name
        publishedVersion
        status
        createdOn
        latestVersion
        isShared
        createdBy {
          name
        }
      }
    }
  }
`;

export const GET_BANK_ADMIN_SURVEYS = gql`
  query Surveys($bankId: String!, $companyId: String) {
    surveys(bankId: $bankId, companyId: $companyId) {
      count
      surveys {
        _id
        name
        publishedVersion
      }
    }
  }
`;
// export const GET_BANK_ADMIN_SURVEYS = gql`
//   query Surveys($bankId: String!, $version: Float, $vendorId: String) {
//     surveys(bankId: $bankId, vendorId: $vendorId) {
//       _id
//       name
//       publishedVersion
//       status
//       createdOn
//       latestVersion
//       isShared
//       createdBy {
//         name
//       }
//       sections(version: $version) {
//         noOfSections
//         sections {
//           _id
//           active
//           default
//           description
//           isPublished
//           order
//           publishedBy
//           publishedOn
//           title
//           version
//           questions {
//             _id
//             active
//             category
//             categorySuggestion
//             editedAt
//             editedBy
//             order
//             question
//             questionType
//             version
//             weightage
//             answers {
//               option
//               weightage
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_BANK_ADMIN_SINGLE_SURVEY = gql`
  query Survey($id: String!, $version: Float, $companyId: String) {
    survey(id: $id, companyId: $companyId) {
      _id
      createdOn
      default
      details
      latestVersion
      isShared
      modifiedBy
      modifiedOn
      name
      publishedVersion
      status
      sections(version: $version) {
        noOfSections
        sections {
          _id
          active
          default
          description
          isPublished
          noOfQuestions
          order
          publishedBy
          publishedOn
          title
          version
          questions {
            _id
            active
            category
            categorySuggestion
            editedAt
            editedBy
            order
            question
            questionType
            status
            version
            weightage
            answers {
              option
              weightage
              justification
              documentUpload
            }
            tableHeaders {
              title
              type
            }
            showComment
          }
        }
      }
    }
  }
`;

export const SHARE_BANK_ADMIN_SURVEY = gql`
  mutation ShareSurvey(
    $stakeholdersIds: [String!]!
    $bankId: String!
    $noOfSurveyReminders: Int!
    $surveyDate: String!
    $surveyId: String!
    $totalReshares: Int!
    $companyId: String!
    $frequencyTypeName: FrequencyType!
    $version: Int!
  ) {
    shareSurvey(
      shareSurveyInput: {
        stakeholdersIds: $stakeholdersIds
        bankId: $bankId
        noOfSurveyReminders: $noOfSurveyReminders
        surveyDate: $surveyDate
        surveyId: $surveyId
        totalReshares: $totalReshares
        companyId: $companyId
        frequencyTypeName: $frequencyTypeName
        version: $version
      }
    ) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_SURVEY = gql`
  mutation UpdateSurvey($updateSurveyInput: UpdateSurveyInput!) {
    updateSurvey(updateSurveyInput: $updateSurveyInput) {
      _id
    }
  }
`;

export const GQL_GET_VA_COMPLETED_SURVEY_FOR_BA = gql`
  mutation GetSharedSurveyCompanies($surveyId: String!, $version: Int!) {
    getSharedSurveyCompanies(
      getSharedSurveyCompanies: { surveyId: $surveyId, version: $version }
    ) {
      _id
      noOfStakeholders
      sharedOn
      company {
        name
        _id
      }
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES = gql`
  query VendorFiles($companyId: ID!) {
    vendorFiles(companyId: $companyId) {
      _id
      fileName
      fileType
      fileUrl
      requestId
      type
      uploadedBy {
        _id
        name
      }
      vendorId
      year
      createdAt
      status
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES_STATUS = gql`
  query VendorFiles($vendorId: ID!) {
    vendorFiles(vendorId: $vendorId) {
      _id
      status
    }
  }
`;

export const GQL_GET_COMPANY_UPLOADED_FILES_IN_SURVEY = gql`
  query SurveyDocs($companyId: String!) {
    surveyDocs(companyId: $companyId) {
      uploadedAt
      uploadedBy {
        name
        profileImg {
          name
          type
          url
        }
      }
      document {
        name
        type
        url
      }
    }
  }
`;

export const GET_VENDOR_NAME_FOR_VA = gql`
  query Company($companyId: String!) {
    company(id: $companyId) {
      _id
      isin
      name
      logo {
        name
        type
        url
      }
    }
  }
`;

export const GET_SINGLE_SURVEY_QUESTION_COUNT = gql`
  query Survey($id: String!, $version: Float, $companyId: String) {
    survey(id: $id, companyId: $companyId) {
      _id
      sections(version: $version) {
        sections {
          noOfQuestions
        }
      }
    }
  }
`;

export const GQL_GET_VENDORS_BY_ISIN = gql`
  query SearchCompanies($query: String!) {
    searchCompanies(query: $query) {
      _id
      isin
      name
      logo {
        name
        type
        url
      }
      address {
        _id
        country
        district
        landmark
        location
        phone
        pincode
        state
      }
      employeesCount
      operation
      revenue {
        currency
        figures
        revenue
        year
      }
      sector {
        industry {
          industry
          _id
          industryCode
        }
        sector {
          _id
          sector
          sectorCode
        }
      }
      turnover {
        currency
        figures
        turnover
        year
      }
      type
      website
    }
  }
`;

export const GQL_GET_SURVEYS_BY_NAME = gql`
  query SearchSurveys($bankId: String!, $query: String!) {
    searchSurveys(bankId: $bankId, query: $query) {
      _id
      createdOn
      name
      publishedVersion
      status
      createdBy {
        name
      }
    }
  }
`;

export const GQL_GET_COMPANIES_BY_NAME = gql`
  query SearchCompanies(
    $query: String!
    $filters: CompanySearchFilter
    $limit: Int
  ) {
    searchCompanies(query: $query, filters: $filters, limit: $limit) {
      _id
      active
      name
      updatedAt
      address {
        country
      }
    }
  }
`;
