import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Drawer,
} from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  getSurveyRespondedUser,
  getSurveyResponses,
  gql_get_va_completed_survey_for_ba,
  setSurveyResponse,
} from "../../../../../../redux/actions/riskdashboard/riskDashboardActions";

// component imports
import { responsesData } from "../tempData";
import NoData from "../../../../NoData/NoData";
import ResponseDrawer from "./ResponseComponents/ResponseDrawer";
import { SET_VA_COMPLETED_SURVEY_FOR_BA } from "../../../../../../constants/riskDashboardConstants";
import { gql_get_frequencies } from "../../../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { gql_get_single_survey_question_count } from "../../../../../../redux/actions/vendorActions/vendorAction";

const useStyles = makeStyles(() => ({
  // table styles
  tableContainer: {
    boxShadow: "none",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRows: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    cursor: "pointer",
  },
}));

// ----------------- Main Render -----------------
const ResponsesTab = () => {
  const classes = useStyles();

  // from redux
  const dispatch = useDispatch();

  // state for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { vaCompletedSurveyForBa, selectedSurveyData } = useSelector(
    (state) => state?.riskDashboard
  );

  const { bankId } = useSelector((state) => state?.login);

  const [vendor, setVendor] = useState({});

  // table headings
  const headings = [
    "Vendor Name",
    // "No. of Stakeholders",
    // "Response Count",
    "",
    "",
    "",
    "",
    "",
    "Shared On",
  ];

  useEffect(() => {
    if (selectedSurveyData?.publishedVersion !== null) {
      dispatch(
        gql_get_va_completed_survey_for_ba(
          selectedSurveyData?._id,
          selectedSurveyData?.publishedVersion
        )
      );

      dispatch(
        gql_get_single_survey_question_count(
          selectedSurveyData?._id,
          selectedSurveyData?.publishedVersion
        )
      );
    }

    return () => {
      dispatch({ type: SET_VA_COMPLETED_SURVEY_FOR_BA, payload: [] });
    };
  }, []);

  // --------------- inner functions ---------------
  // handling drawer open and close
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    // !drawerOpen &&
    //   dispatch(getSurveyRespondedUser(token, _id?.organization_id));
  };

  // Format date function
  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // -----------------------------------------------

  if (!vaCompletedSurveyForBa || vaCompletedSurveyForBa.length === 0) {
    return <NoData />;
  }

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headings.map((heading, index) => (
                <TableCell key={index} className={classes.tableHead}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vaCompletedSurveyForBa?.map((response, index) => (
              <TableRow
                className={classes.tableRows}
                key={index}
                onClick={() => {
                  // dispatch(setSurveyResponse(response));
                  handleDrawer();
                  setVendor(response?.company);
                }}
              >
                <TableCell>{response?.company?.name}</TableCell>
                {/* <TableCell>{response?.noOfStakeholders}</TableCell> */}
                {/* <TableCell>{10}</TableCell> */}
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{formatDate(response.sharedOn)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Response Drawer */}
      {drawerOpen && (
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawer}>
          <ResponseDrawer handleDrawer={handleDrawer} vendor={vendor} />
        </Drawer>
      )}
    </div>
  );
};
export default ResponsesTab;
