import React from "react";

import { Box, Paper, Typography } from "@material-ui/core";

import { IoEllipsisVertical } from "react-icons/io5";

function ChartsComponent({ title, children }) {
  return (
    <Paper
      elevation={3}
      style={{ padding: "10px", flex: 1, marginTop: "20px" }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ color: "#15314E", fontSize: "20px", fontWeight: 500 }}
        >
          {title}
        </Typography>
        <IoEllipsisVertical size={20} style={{ color: "#3374B9" }} />
      </Box>
      <Box style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
        {children}
      </Box>
    </Paper>
  );
}

export default ChartsComponent;
