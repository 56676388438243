import {
  Grid,
  Icon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import pdfImg from "../../../images/PDF.png";
import user from "../../../images/userIconBlue.png";
import doc from "../../../images/fileIcon.svg";
import clock from "../../../images/clock.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import EditOutlined from "@material-ui/icons/EditOutlined";

const PublicReports = React.memo(
  ({ handleFileRemove, publicDocuments, isFrom, ...props }) => {
    const dispatch = useDispatch();

    const [showPopper, setShowPopper] = useState(null);
    const [fileIndex, setFileIndex] = useState(null);
    const anchorRef = useRef(null);

    useEffect(() => {
      if (!anchorRef && anchorRef.current) {
        // Focus on the input element when showInput is true
        anchorRef.current.focus();
      }
    }, [showPopper]);

    function handleClick(e, index) {
      setFileIndex(index);
      setShowPopper(e.currentTarget);
    }

    function handleClose() {
      setShowPopper(null);
    }

    const shortenFileName = (fileName, maxLength) => {
      if (fileName?.length <= maxLength) {
        return fileName;
      }

      const headLength = Math.floor((maxLength - 3) / 2);
      const tailLength = Math.ceil((maxLength - 3) / 2);

      const head = fileName?.substring(0, headLength);
      const tail = fileName?.substring(fileName?.length - tailLength);

      return fileName === undefined ? "-" : `${head}...${tail}`;
    };

    const status = (value) => {
      if (value) {
        return "Completed";
      } else {
        return "Incomplete";
      }
    };
    // const status = (value) => {
    //   if (value === "UPLOADING") {
    //     return "Uploading";
    //   } else if (value === "COMPLETED") {
    //     return "Completed";
    //   } else {
    //     return "Failed";
    //   }
    // };

    const statusColor = (value, type) => {
      if (type === "value") {
        return !value ? "#8C7038" : "#154328";
      }
      if (type === "bg") {
        return !value ? "#FFEFD0" : "#BFE2CD";
      }
    };
    // const statusColor = (value, type) => {
    //   if (type === "value") {
    //     return value === "UPLOADING"
    //       ? "#8C7038"
    //       : value === "COMPLETED"
    //       ? "#154328"
    //       : "#52231D";
    //   }
    //   if (type === "bg") {
    //     return value === "UPLOADING"
    //       ? "#FFEFD0"
    //       : value === "COMPLETED"
    //       ? "#BFE2CD"
    //       : "#EDCAC6";
    //   }
    // };

    function openInNewTab(url) {
      window.open(url, "_blank").focus();
    }

    return (
      <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            marginTop: "10px",
            backgroundColor: "#EBF1F8",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <Grid item md={3} xs={3}>
            <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
              Publications
            </Typography>
          </Grid>
          {isFrom === "companyDocs" ? (
            <Grid item md={2} xs={2} align="center">
              <Typography style={{ fontSize: "13px" }}>
                Document Type
              </Typography>
            </Grid>
          ) : (
            <Grid item md={2} xs={2} align="center"></Grid>
          )}
          {isFrom !== "companyDocs" && (
            <Grid item md={2} xs={2} align="center"></Grid>
          )}
          <Grid item md={2} xs={2} align="center">
            <Typography style={{ fontSize: "13px" }}>Uploaded By</Typography>
          </Grid>
          <Grid item md={2} xs={2} align="center">
            <Typography style={{ fontSize: "13px" }}>Upload Time</Typography>
          </Grid>
          {isFrom === "companyDocs" && (
            <Grid item md={2} xs={2} align="center">
              <Typography style={{ fontSize: "13px" }}>Status</Typography>
            </Grid>
          )}
          <Grid item md={1} xs={1} align="center">
            <Typography style={{ fontSize: "13px" }}>Action</Typography>
          </Grid>
        </Grid>
        {/* {console.log(props)} */}
        {publicDocuments &&
          publicDocuments?.length > 0 &&
          publicDocuments?.map((file, index) => (
            <Grid
              container
              key={index}
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "10px",
                padding: "5px",
                borderBottom: "1px solid grey",
                borderRadius: "2px",
                alignItems: "center",
              }}
            >
              <Grid
                item
                md={3}
                xs={3}
                container
                style={{ flexWrap: "nowrap", alignItems: "center" }}
              >
                <img
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                  src={pdfImg}
                  alt="pdf"
                />
                <div>
                  <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                    {shortenFileName(
                      file?.file_name || file?.document?.name,
                      25
                    ) || "publication name"}
                  </Typography>
                  {isFrom === "companyDocs" && (
                    <Typography
                      style={{
                        fontSize: "12px",
                        marginLeft: "5px",
                        color: "grey",
                      }}
                    >
                      {file.year || "publication year"}
                    </Typography>
                  )}
                </div>
              </Grid>
              {isFrom === "companyDocs" ? (
                <Grid
                  item
                  md={2}
                  xs={2}
                  container
                  align="right"
                  style={{ flexWrap: "nowrap", justifyContent: "center" }}
                >
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={doc}
                    alt="doc"
                  />
                  <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                    {file?.type.split("_").join(" ").toUpperCase() ||
                      "doc type"}
                  </Typography>
                </Grid>
              ) : (
                <Grid item md={2} xs={2} container></Grid>
              )}

              {isFrom !== "companyDocs" && (
                <Grid item md={2} xs={2} container></Grid>
              )}
              <Grid
                item
                md={2}
                xs={2}
                // container
                align="center"
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "20px", height: "20px", marginRight: "0px" }}
                  src={user}
                  alt="pdf"
                />
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  {file?.user_name || file?.uploadedBy?.name || "username"}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
                container
                align="center"
                style={{ flexWrap: "nowrap", justifyContent: "center" }}
              >
                <img
                  style={{ width: "20px", height: "20px", marginRight: "5px" }}
                  src={clock}
                  alt="pdf"
                />
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  {moment(file?.uploaded_on || file?.uploadedAt).format("ll")}
                </Typography>
              </Grid>

              {isFrom === "companyDocs" && (
                <Grid item md={2} xs={2} align="center">
                  <Typography
                    style={{
                      // color: statusColor(
                      //   uploadedFilesStatus[index]?.status,
                      //   "value"
                      // ),
                      color: statusColor(file?.KPI_process_completed, "value"),
                      // backgroundColor: statusColor(
                      //   uploadedFilesStatus[index]?.status,
                      //   "bg"
                      // ),
                      backgroundColor: statusColor(
                        file?.KPI_process_completed,
                        "bg"
                      ),
                      borderRadius: 12,
                      fontSize: 12,
                      padding: "3px 8px",
                      width: "fit-content",
                      // margin: "5px 8px",
                    }}
                  >
                    {status(file?.KPI_process_completed)}
                  </Typography>
                </Grid>
              )}

              <Grid item md={1} xs={1} align="center">
                <Typography
                  // onClick={() => fileRemove(file)}
                  style={{ fontSize: "13px" }}
                  onClick={(e) => handleClick(e, index)}
                  ref={anchorRef}
                >
                  <Icon
                    style={{
                      cursor: "pointer",
                      color: "#3374b9",
                      fontSize: 20,
                    }}
                  >
                    more_vert
                  </Icon>
                </Typography>

                {showPopper && fileIndex === index && (
                  <div
                    style={{
                      padding: "0 10px",
                      color: "#3374b9",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    <Menu
                      id="simple-menu"
                      anchorEl={showPopper}
                      keepMounted
                      open={showPopper}
                      onClose={handleClose}
                      MenuListProps={{
                        style: {
                          paddingTop: 0,
                          paddingBottom: 0,
                          margin: 0,
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          openInNewTab(file?.file_url || file?.document?.url)
                        }
                        style={{
                          padding: "0 10px",
                          color: "#3374b9",
                          marginTop: 0,
                          marginBottom: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <ListItemText
                          primary="Download"
                          primaryTypographyProps={{
                            style: { fontSize: "14px" },
                          }}
                        />
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </Grid>
              {/* <Grid item md={1} xs={1} align="center">
      <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
    </Grid> */}
            </Grid>
          ))}
      </div>
    );
  }
);

export default PublicReports;
