import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";

// redux imports
import { useDispatch, useSelector } from "react-redux";

// component imports
import CustomTextfield from "../../../../../UI/textfield/textfield";
import CustomButton from "../../../../../UI/button/button";
import { gql_update_survey } from "../../../../../../redux/actions/vendorActions/vendorAction";

const BasicDetailsTab = () => {
  const dispatch = useDispatch();
  const surveyTypes = ["Controversy Survey", "Self Assessment Survey"];

  const [surveyDetails, setSurveyDetails] = useState({ name: "", details: "" });

  // from redux
  const { selectedSurveyData } = useSelector((state) => state.riskDashboard);
  const { bankId } = useSelector((state) => state.login);

  useEffect(() => {
    setSurveyDetails({
      name: selectedSurveyData?.name,
      details: selectedSurveyData?.details,
    });
  }, [selectedSurveyData]);

  const handleUpdateSurvey = () => {
    const updateSurveyInput = {
      ...surveyDetails,
      id: selectedSurveyData?._id,
      bankId,
    };
    dispatch(gql_update_survey(updateSurveyInput));
  };

  // --------------- inner functions ---------------
  // -----------------------------------------------

  return (
    <Box>
      {/* Basic Details Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#15314E",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Basic Details
        </Typography>
        <IconButton
          style={{
            border: "1px solid #32A05F",
            borderRadius: "8px",
            width: "36px",
            height: "36px",
          }}
        >
          <MoreVertIcon style={{ color: "#32A05F" }} />
        </IconButton>
      </Box>

      {/* Basic Details Fields */}
      <Box
        sx={{ p: 2 }}
        style={{ display: "flex", flexDirection: "column", height: "55vh" }}
      >
        <div style={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            style={{
              color: "#6C6C6C",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            Survey Name
          </Typography>
          <CustomTextfield
            id="surveyName"
            name="surveyName"
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) =>
              setSurveyDetails((prev) => ({ ...prev, name: e.target.value }))
            }
            value={surveyDetails?.name}
            disabled={selectedSurveyData?.name === "Default Survey"}
          />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            style={{
              color: "#6C6C6C",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            Describe About The Survey
          </Typography>
          <CustomTextfield
            id="surveyDescription"
            name="surveyDescription"
            variant="outlined"
            fullWidth
            multiline
            minRows={5}
            size="small"
            onChange={(e) =>
              setSurveyDetails((prev) => ({ ...prev, details: e.target.value }))
            }
            value={surveyDetails?.details}
            disabled={selectedSurveyData?.name === "Default Survey"}
          />
        </div>
      </Box>
      {selectedSurveyData?.name === "Default Survey" || (
        <Box
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            color="primary"
            variant="contained"
            style={{ width: "150px", marginRight: "10px" }}
            onClick={handleUpdateSurvey}
          >
            Save Changes
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default BasicDetailsTab;
