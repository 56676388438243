import { Tab, Tabs, MuiThemeProvider, Box } from "@material-ui/core";

import MainHeader from "../../screen/vendorAdminScreen/MainHeader";
import { appTheme } from "../riskAssessment/brmDetaails";
import { useState } from "react";
import AssignedTab from "./SelectedTaskComps/AssignedTab";
import CompletedTab from "./SelectedTaskComps/CompletedTab";

const SelectedTaskMain = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: 20, padding: "12px 0px" }}
    >
      {/* Header with Breadcrumbs */}
      <MainHeader mainCrumb={"My Task"} />

      {/* Main Section Tabs */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{ gap: 20, padding: "0px 20px" }}
      >
        <MuiThemeProvider theme={appTheme}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              disableRipple
              label={<span>Assigned</span>}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              disableRipple
              label={<span>Completed</span>}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </MuiThemeProvider>

        {/* Render content for tabs*/}
        <Box>
          {tabValue === 0 && <AssignedTab />}
          {tabValue === 1 && <CompletedTab />}
        </Box>
      </Box>

      {/* Table */}
    </Box>
  );
};
export default SelectedTaskMain;
