import { Fragment, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import SurveyStepper from "../../stepHeader/SurveyStepper";
import { Box, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CustomButton from "../../../../../UI/button/button";
import DrawerStep1 from "./DrawerStep1";
import DrawerStep2 from "./DrawerStep2";

import { useDispatch, useSelector } from "react-redux";
import { gql_assign_survey } from "../../../../../../redux/actions/vendorActions/vendorAction";

export default function AssignDrawer(props) {
  const {
    isDrawerOpen,
    handleCloseDrawer,
    optList,
    drawerFor,
    selectedSectionId,
    handleStatusChangeOnAssign,
  } = props;

  // from redux
  const dispatch = useDispatch();
  const { members } = useSelector((state) => state.brmData);
  const { selectedVASurvey } = useSelector((state) => state.vendorReducer); // take latestVersion

  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);

  const updatedMembers = members.map((member) => ({
    ...member,
    isCheck: false,
  }));

  // states
  const [activeStep, setActiveStep] = useState(0);
  const [list, setList] = useState([]); // contains list of objects for selected sections/questions
  const [contributors, setContributors] = useState([]);
  const [approvers, setApprover] = useState([]);
  const [users, setUsers] = useState(updatedMembers);

  // --------------- Constants ---------------
  const steps = [
    drawerFor === "sections" ? "Select Section" : "Select Question",
    "Select Stakeholder",
  ];

  // these list will contain the final selected items ids
  const selectedOptions = [];
  const selectedContributors = [];
  const selectedApprovers = [];

  list?.map((e) => e?.isCheck && selectedOptions.push(e?.id));
  contributors?.map((e) => selectedContributors.push(e?._id));
  approvers?.map((e) => selectedApprovers.push(e?._id));
  // --------------------------------------------

  // --------------- Inner Functions ---------------

  // step 1 functions
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const toggleSingleItemList = (itemId) => {
    const updatedList = list.map((item) => {
      return item.id === itemId ? { ...item, isCheck: !item.isCheck } : item;
    });

    setList(updatedList);
  };

  const toggleAllItems = (value) => {
    const updatedList = list.map((item) => ({
      ...item,
      isCheck: value,
    }));
    setList(updatedList);
  };

  // step 2 functions
  const handleContributers = () => {
    const updateList = users.filter((data) => data?.isCheck);
    setContributors([
      ...contributors,
      ...updateList.map((item) => ({
        ...item,
        isCheck: false,
      })),
    ]);
    setUsers((preValue) =>
      preValue.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleApprovers = () => {
    const updateList = users.filter((data) => data?.isCheck);
    setApprover([
      ...approvers,
      ...updateList.map((item) => ({
        ...item,
        isCheck: false,
      })),
    ]);
    setUsers((preValue) =>
      preValue.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleDeleteContributers = (id) => {
    const updateList = contributors.filter((data) => data?._id !== id);
    setContributors(updateList);
  };

  const handleDeleteApprovers = (id) => {
    const updateList = approvers.filter((data) => data?._id !== id);
    setApprover(updateList);
  };

  const toggleSingleItemUser = (itemId) => {
    const updatedList = users.map((item) =>
      item._id === itemId ? { ...item, isCheck: !item.isCheck } : item
    );

    setUsers(updatedList);
  };

  const assignSurveyInput = {
    bankId: selectedBankDetailsForVa?._id,
    approverIds: selectedApprovers,
    contributorIds: selectedContributors,
    assignedVersion: selectedVASurvey?.latestVersion,
    frequencyId: selectedVASurvey?.frequencyId,
    sectionIds:
      drawerFor === "sections" ? selectedOptions : [selectedSectionId],
    ...(drawerFor !== "sections" && { questionIds: selectedOptions }),
    surveyId: selectedVASurvey?.surveyId,
  };

  // console.log(assignSurveyInput);

  // function for handling complete list
  const handleCompleteList = async () => {
    // data to be sent to gql
    const assignSurveyInput = {
      bankId: selectedBankDetailsForVa?._id,
      approverIds: selectedApprovers,
      contributorIds: selectedContributors,
      assignedVersion: selectedVASurvey?.latestVersion,
      frequencyId: selectedVASurvey?.frequencyId,
      sectionIds:
        drawerFor === "sections" ? selectedOptions : [selectedSectionId],
      ...(drawerFor !== "sections" && { questionIds: selectedOptions }),
      surveyId: selectedVASurvey?.surveyId,
    };

    // dispatching action
    await dispatch(gql_assign_survey(assignSurveyInput));
    // close drawer
    handleCloseDrawer();

    handleStatusChangeOnAssign();
  };

  const handleReset = () => {
    setContributors([]);
    setApprover([]);
  };

  // ---------------------------------------------

  // --------------- useEffects() ---------------
  useEffect(() => {
    const updated = optList.map((survey, index) => ({
      id: survey?._id,
      name: drawerFor === "sections" ? survey?.title : survey?.question,
      noOfQuestions: survey?.noOfQuestions || 0,
      isCheck: false,
    }));
    setList(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------------------------------------------

  return (
    <div>
      <Fragment>
        <Drawer
          anchor={"right"}
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
        >
          <Box
            style={{
              padding: "10px",
              width: "70vw",
              height: "100%",
            }}
          >
            {/* header */}
            <Box
              display={"flex"}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                // padding: "5px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  width: "20%",
                }}
              >
                Assign {drawerFor === "sections" ? "Section" : "Questions"}
              </Typography>
              <Box style={{ width: "50%" }}>
                <SurveyStepper steps={steps} activeStep={activeStep} />
              </Box>
              <IconButton onClick={handleCloseDrawer}>
                <ClearIcon
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#3374B9",
                  }}
                />
              </IconButton>
            </Box>

            {/* instruction */}
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                // width: "20%",
                color: "#505050",
              }}
            >
              Empower your survey experience by selecting specific {drawerFor}{" "}
              tailored to your interests, ensuring a focused and relevant
              exploration of environmental, social, and governance aspects.
            </Typography>

            {/* table */}
            {activeStep === 0 && (
              <DrawerStep1
                listData={list}
                drawerFor={drawerFor}
                handleToggle={toggleSingleItemList}
              />
            )}
            {activeStep === 1 && (
              <DrawerStep2
                listData={users}
                handleToggle={toggleSingleItemUser}
                handleApprovers={handleApprovers}
                handleContributers={handleContributers}
                contributors={contributors}
                approvers={approvers}
                handleDeleteApprovers={handleDeleteApprovers}
                handleDeleteContributers={handleDeleteContributers}
              />
            )}

            {/* button */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "absolute",
                bottom: 5,
                right: 15,
                gap: 10,
              }}
            >
              {activeStep > 0 && (
                <CustomButton
                  variant="text"
                  color="primary"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Go Back
                </CustomButton>
              )}
              <CustomButton
                variant="text"
                color="primary"
                onClick={() => {
                  if (activeStep === 0) {
                    toggleAllItems(false);
                  } else {
                    handleReset();
                  }
                }}
              >
                Clear Selection
              </CustomButton>
              {activeStep === 0 && (
                <CustomButton
                  variant="text"
                  color="primary"
                  onClick={() => toggleAllItems(true)}
                >
                  Select All
                </CustomButton>
              )}
              <CustomButton
                variant="contained"
                color="primary"
                onClick={activeStep === 0 ? handleNext : handleCompleteList}
                disabled={
                  selectedOptions?.length === 0 ||
                  (activeStep === 1 &&
                    (contributors.length === 0 || approvers.length === 0))
                }
              >
                {activeStep === steps.length - 1 ? "Complete List" : "Next"}
              </CustomButton>
            </Box>
          </Box>
        </Drawer>
      </Fragment>
    </div>
  );
}
