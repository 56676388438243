import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { FiTrash } from "react-icons/fi";

import CustomButton from "../../../UI/button/button";
import FileUploaded from "../../../../images/FileUploded.png";
import {
  gql_get_requested_files,
  rest_upload_va_file_from_ba,
} from "../../../../redux/actions/vendorActions/vendorAction";
import { useDispatch, useSelector } from "react-redux";
import { rest_upload_requested_file } from "../../../../redux/actions/taskActions/TaskAction";
import { set_snack_bar } from "../../../../redux/actions/snackbar/snackbar_action";
import Textfield from "../../../UI/textfield/textfield";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

const DataRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedBankDetailsForVa, requestedFiles, vendorBasicDetails } =
    useSelector((state) => state.brmData);

  // console.log(vendorBasicDetails);
  const { token, name } = useSelector((state) => state.login);

  // State to store file names and upload status
  const [fileNames, setFileNames] = useState([]);

  const [isFileUploaded, setIsFileUploaded] = useState([]);

  const [fileUpload, setFileUpload] = useState([]);

  const [sourceUrl, setSourceUrl] = useState([]);
  const [xbrlUrl, setXbrlUrl] = useState("");

  // console.log(sourceUrl);

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (requestedFiles?.length > 0) {
      setFileUpload(() => {
        return Array.from({ length: requestedFiles?.length }, () => null);
      });

      setIsFileUploaded(() => {
        return Array.from({ length: requestedFiles?.length }, () => false);
      });

      setFileNames(() => {
        return Array.from({ length: requestedFiles?.length }, () => "");
      });

      setSourceUrl(() => {
        return Array.from({ length: requestedFiles?.length }, () => "");
      });
    }
  }, [requestedFiles]);

  // useEffect(() => {
  //   if (requestedFiles?.length === 0) {
  //     setIsSubmitted(true);
  //   }

  //   console.log(requestedFiles?.length);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [requestedFiles]);

  // Function to handle file upload
  const handleFileUpload = (index) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const newFileNames = [...fileNames];
      newFileNames[index] = file.name;
      setFileNames(newFileNames);

      const newIsFileUploaded = [...isFileUploaded];
      newIsFileUploaded[index] = true;
      setIsFileUploaded(newIsFileUploaded);

      const newFile = [...fileUpload];
      newFile[index] = file;
      setFileUpload(newFile);
    }
  };

  // Function to handle file delete
  const handleFileDelete = (index) => (event) => {
    const newFileNames = [...fileNames];
    newFileNames[index] = "";
    setFileNames(newFileNames);

    const newIsFileUploaded = [...isFileUploaded];
    newIsFileUploaded[index] = false;
    setIsFileUploaded(newIsFileUploaded);

    const newFile = [...fileUpload];
    newFile[index] = null;
    setFileUpload(newFile);
  };

  useEffect(() => {
    const { _id: bankId } = selectedBankDetailsForVa;

    dispatch(gql_get_requested_files(bankId));
  }, []);

  const shortenFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) {
      return fileName;
    }

    const headLength = Math.floor((maxLength - 3) / 2);
    const tailLength = Math.ceil((maxLength - 3) / 2);

    const head = fileName?.substring(0, headLength);
    const tail = fileName?.substring(fileName?.length - tailLength);

    return fileName === undefined ? "-" : `${head}...${tail}`;
  };

  const handleFileSubmit = async (index, type, year, requestId) => {
    // console.log(fileUpload[index], id);

    const dateSplit = year?.split("-");
    const startDate = dateSplit[0];
    const endDate = dateSplit[dateSplit.length - 1];

    const whichFileToBeSent = type === "XBRL" ? xbrlUrl : fileUpload[index];

    // console.log(startDate, endDate);

    if (
      (fileUpload[index] || fileUpload[index] !== null || xbrlUrl !== "") &&
      sourceUrl[index] !== ""
    ) {
      await dispatch(
        rest_upload_va_file_from_ba(
          token,
          vendorBasicDetails?.isin,
          year,
          whichFileToBeSent,
          startDate,
          endDate,
          type,
          vendorBasicDetails?._id,
          sourceUrl[index],
          name,
          requestId,
          "vendorAdmin"
        )
      );

      const { _id: bankId } = selectedBankDetailsForVa;

      dispatch(gql_get_requested_files(bankId));
    } else if (type === "XBRL" && xbrlUrl === "") {
      dispatch(set_snack_bar(true, "Please provide an XBRL Url!"));
    } else if (type !== "XBRL" && fileUpload[index] === null) {
      dispatch(set_snack_bar(true, "Please attach a file!"));
    } else if (sourceUrl[index] === "") {
      dispatch(set_snack_bar(true, "Please provide a source Url!"));
    }
  };

  // const

  return (
    <Box width={"80%"} height={"75vh"} style={{ position: "relative" }}>
      {requestedFiles && requestedFiles?.length !== 0 ? (
        <>
          {/* Header Box */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              style={{
                color: "#15314E",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Data Request
            </Typography>
            {/* <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ width: "10em", textTransform: "capitalize" }}
              onClick={() => setIsSubmitted(true)}
            >
              Submit
            </CustomButton> */}
          </Box>

          <Box>
            <BlueBackgroundCard
              heading={
                "Please ensure your files are correct before submission as redoing is not possible*"
              }
              // subHeading="Please ensure your files are correct before submission as redoing is not possible"
            />
          </Box>

          {/* Table Box */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type Of Document</TableCell>
                <TableCell>Financial Year</TableCell>
                <TableCell>Upload File / URL</TableCell>
                <TableCell>Source Url</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestedFiles?.map((e, index) => (
                <TableRow key={index}>
                  <TableCell>{e.type}</TableCell>
                  <TableCell>{e.year}</TableCell>
                  <TableCell>
                    {e.type === "XBRL" ? (
                      <Textfield
                        size={"small"}
                        variant={"outlined"}
                        placeholder="XBRL URL"
                        disabled={e?.isCompleted}
                        style={{ width: "150px" }}
                        onChange={(e) => setXbrlUrl(e.target.value)}
                      />
                    ) : (
                      <>
                        <input
                          type="file"
                          onChange={handleFileUpload(index)}
                          style={{ display: "none" }}
                          id={`file-upload-${index}`}
                        />
                        <label htmlFor={`file-upload-${index}`}>
                          <CustomButton
                            component="span"
                            variant="text"
                            color="primary"
                            style={{ textTransform: "capitalize" }}
                            disabled={isFileUploaded[index] || e?.isCompleted}
                          >
                            {isFileUploaded[index] || e.isCompleted
                              ? "Uploaded"
                              : "Upload"}
                          </CustomButton>
                        </label>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Textfield
                      size={"small"}
                      variant={"outlined"}
                      placeholder="Source URL"
                      style={{ width: "150px" }}
                      disabled={e?.isCompleted}
                      onChange={(e) => {
                        const newSource = [...sourceUrl];
                        newSource[index] = e.target.value;
                        setSourceUrl(newSource);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {e.type === "XBRL" && e?.uploadedFile?.url !== ""
                      ? shortenFileName(e?.uploadedFile?.url, 20)
                      : e.type !== "XBRL" && e?.uploadedFile?.name !== ""
                      ? shortenFileName(e?.uploadedFile?.name, 20)
                      : shortenFileName(fileNames[index], 20) || "-"}
                  </TableCell>
                  <TableCell>
                    {isFileUploaded[index] && (
                      <IconButton onClick={handleFileDelete(index)}>
                        <FiTrash color="#C45446" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <CustomButton
                      variant={"contained"}
                      color={"primary"}
                      style={{ width: "10em", textTransform: "capitalize" }}
                      onClick={() =>
                        handleFileSubmit(index, e?.type, e?.year, e._id)
                      }
                      disabled={e.isCompleted}
                    >
                      {e?.isCompleted ? "Submitted" : "Submit"}
                    </CustomButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ gap: 10 }}
        >
          <img
            src={FileUploaded}
            alt="FileUploaded"
            style={{ aspectRatio: "1:1", width: "35%" }}
          />
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            This Section has been Saved.
          </Typography>
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            If you wish to redo the survey please use the redo button or go back
            to the task section.
          </Typography>

          <Box
            style={{
              position: "absolute",
              right: 30,
              bottom: 10,
              display: "flex",
              gap: 10,
            }}
          >
            {/* <CustomButton
              variant="text"
              color="primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => setIsSubmitted(false)}
            >
              Redo Survey
            </CustomButton> */}
            <CustomButton
              variant="contained"
              color="primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => history.goBack()}
            >
              Go To Task Menu
            </CustomButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DataRequest;
