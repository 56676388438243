export const SET_ESG_SCORE = "SET_ESG_SCORE";
export const SET_ESG_INSIGNTS = "SET_ESG_INSIGNTS";
export const SET_CONTROVERSIES_DETAILS = "SET_CONTROVERSIES_DETAILS";
export const SET_ARTICLES = "SET_ARTICLES";
export const SET_SELF_ASSESSMENT_SCORE = "SET_SELF_ASSESSMENT_SCORE";
export const SET_CONTROVERSIES_SCORE = "SET_CONTROVERSIES_SCORE";
export const SET_FREQUENCIES = "SET_FREQUENCIES";
export const SET_COMPLETED_QUESTIONS = "SET_COMPLETED_QUESTIONS";
export const SET_TPRM_SCORE = "SET_TPRM_SCORE";
export const DEFAULT_VENDOR_USER_TOKEN = "DEFAULT_VENDOR_USER_TOKEN";
export const SET_CONTRO_INSIGHTS = "SET_CONTRO_INSIGHTS";
