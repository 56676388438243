import React, { memo, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import Tooltip from "@material-ui/core/Tooltip";
import Popper from "@material-ui/core/Popper";
import colorCalculator from "../mapUtils/colorCalculator";

// country data
import geoUrl from "./countries.json";
import { styled } from "@material-ui/core";

const MapChart = ({
  setTooltipContent,
  setTooltipPosition,
  countries,
  tooltipContent,
  tooltipPosition,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <div id="map">
      <ComposableMap
        projection="geoMercator"
        data-tip=""
        onMouseDown={(e) => e.preventDefault()}
        projectionConfig={{
          scale: 100,
          center: [0, 40],
        }}
        height={450}
        style={{ width: "100%", height: "auto" }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryData = countries?.find(
                (data) => data.key === geo.properties.name
              );

              const interest = countryData ? `${countryData.doc_count}%` : "0%";
              const fill = colorCalculator(interest);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fill}
                  onMouseEnter={(event) => {
                    setTooltipContent(`${geo.properties.name} - ${interest}`);
                    setTooltipPosition(event);
                    handleTooltipOpen();
                  }}
                  onMouseMove={(event) => {
                    setTooltipPosition(event);
                  }}
                  onMouseLeave={() => {
                    handleTooltipClose();
                  }}
                  style={{
                    hover: {
                      stroke: "#3374B9",
                      outline: "none",
                    },
                    pressed: {
                      stroke: "#3374B9",
                      outline: "none",
                    },
                    default: {
                      outline: "none",
                    },
                    backgroundColor: "#3374B9",
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>

      <Popper
        open={tooltipOpen}
        anchorEl={{
          getBoundingClientRect: () => ({
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            right: tooltipPosition.x,
            bottom: tooltipPosition.y,
            width: 0,
            height: 0,
          }),
        }}
        placement="top"
        disablePortal={true}
        modifiers={{
          offset: {
            enabled: true,
            offset: "0, 10",
          },
        }}
      >
        <StyledTooltip
          title={tooltipContent}
          open={tooltipOpen}
          placement="top"
          arrow
          onClose={handleTooltipClose}
        >
          <div></div>
        </StyledTooltip>
      </Popper>
    </div>
  );
};

export default memo(MapChart);

const StyledTooltip = styled(
  React.forwardRef(({ ...props }, ref) => <Tooltip {...props} ref={ref} />)
)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#00000095",
    color: "#ffffff",
    fontSize: "0.8rem",
  },
}));
