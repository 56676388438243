import { useEffect } from "react";
import ApproverQuestions from "./ApproverComps/ApproverQuestions";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_task_questions_for_approver } from "../../../../../../redux/actions/taskActions/TaskAction";

const ApproverMain = ({ activeSection }) => {
  const dispatch = useDispatch();

  const { taskQuestions, selectedTask } = useSelector(
    (state) => state.taskReducer
  );

  const { vendorBasicDetails } = useSelector((state) => state.brmData);

  const { isApproved } = useSelector((state) => state.threadReducer);

  useEffect(() => {
    if (activeSection?._id && (isApproved || !isApproved)) {
      dispatch(
        gql_get_task_questions_for_approver(
          selectedTask?.frequencyId,
          activeSection?._id,
          vendorBasicDetails?._id
        )
      );
    }
  }, [activeSection?._id, isApproved]);

  return (
    <>
      <ApproverQuestions
        taskQuestions={taskQuestions}
        frequencyId={selectedTask?.frequencyId}
        activeSectionId={activeSection?._id}
      />
    </>
  );
};
export default ApproverMain;
