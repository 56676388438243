import { useHistory } from "react-router-dom";

import { Box, TableRow, TableCell, IconButton } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import GroupDoc from "../../../images/GroupDoc.png";
import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../UI/dynamicTable/DynamicTable";
import { data, headings } from "./tempData";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_va_banks_list,
  gql_get_vendor_name_for_va,
} from "../../../redux/actions/vendorActions/vendorAction";
import {
  SET_SELECTED_BANK_DETAILS_FOR_VA,
  SET_VA_BANKS_LIST,
} from "../../../constants/brmConstants";

// -------- main render ---------
const MainTable = () => {
  const classes = useTableStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { vaBanksList } = useSelector((state) => state?.brmData);
  const { companyId } = useSelector((state) => state?.login);

  useEffect(() => {
    dispatch(gql_get_va_banks_list("Shared"));
    dispatch(gql_get_vendor_name_for_va(companyId));

    return () => {
      dispatch({ type: SET_VA_BANKS_LIST, payload: [] });
    };
  }, []);

  const setSelectedBankDetailsForVa = (bank) => {
    return { type: SET_SELECTED_BANK_DETAILS_FOR_VA, payload: bank };
  };

  async function handleClick(rowData) {
    await dispatch(setSelectedBankDetailsForVa(rowData));
    history.push({
      pathname: `/borrower_admin/${rowData.name.replace(/\s/g, "_")}`,
    });
  }

  return (
    <Box p={2.5} pt={4}>
      {/* Table */}
      <DynamicTable headings={headings}>
        {vaBanksList.map((rowData) => (
          <TableRow
            key={rowData?._id}
            className={classes.tableRowHover}
            onClick={() => handleClick(rowData)}
          >
            <TableCell style={textStyle("main")}>{rowData?.name}</TableCell>
            <TableCell style={textStyle()}>{rowData?.count}</TableCell>
            <TableCell style={textStyle()}>
              {rowData?.headOfficeAddress?.state}
            </TableCell>
            <TableCell>
              <Box
                style={statusStyle(rowData?.active ? "pending" : "complete")}
              >
                <DotIcon style={{ widht: "10px", height: "10px" }} />
                {rowData?.active ? "In Progress" : "Completed"}
              </Box>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // console.log(data[rowIndex]);
                }}
              >
                <img src={GroupDoc} alt="DocIcon" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default MainTable;
