import { useState } from "react";
import { Box, Divider, Typography, IconButton } from "@material-ui/core";

// icons
import CopyIcon from "../../../../../../../../../images/copy.png";
import TrashIcon from "../../../../../../../../../images/trashRed.png";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_add_section_question,
  gql_delete_questions,
} from "../../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";

import CustomButton from "../../../../../../../../UI/button/button";
import AddRuleDrawer from "./AddRuleDrawer";

const QuestionsActions = ({
  isDefault,
  selectedQues,
  handleQuestionSave,
  selectedSection,
  setSelectedQues,
  usingQues,
}) => {
  // from redux
  const dispatch = useDispatch();

  // states
  const [openRuleDrawer, setOpenRuleDrawer] = useState(false);
  const { selectedSurveyData } = useSelector((state) => state.riskDashboard);

  // -------------- Inner Functions --------------

  // console.log(selectedQues);

  const handleDuplicateQuestion = () => {
    // Dispatch action to add a new question
    const createQuestionInput = {
      answers: selectedQues?.answers,
      order: 1,
      question: selectedQues?.question,
      questionType: selectedQues?.questionType,
      sectionId: selectedSection?._id,
      version: selectedQues?.version,
      weightage: selectedQues?.weightage,
    };

    dispatch(
      gql_add_section_question(
        createQuestionInput,
        selectedSurveyData?._id,
        "duplicate"
      )
    );
  };

  const handleQuestionAction = (type) => {
    // save question is being handled inside of handleQuestionAction fn()

    switch (type) {
      case "delete":
        dispatch(gql_delete_questions(selectedQues?._id, selectedSection?._id));
        break;

      case "duplicate":
        handleDuplicateQuestion();
        break;

      default:
        break;
    }
  };

  const toggleRuleDrawer = () => {
    setOpenRuleDrawer(!openRuleDrawer);
  };
  // ---------------------------------------------

  return (
    <Box>
      <Divider
        variant="middle"
        style={{ marginTop: "10px", border: "1px solid #C0D4E9" }}
      />
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <CustomButton
          style={{ textTransform: "none", marginRight: 10 }}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            handleQuestionSave(selectedQues, true);
          }}
        >
          <Typography>Save</Typography>
        </CustomButton>

        {!isDefault && (
          <CustomButton
            style={{ textTransform: "none", marginRight: 10 }}
            size="small"
            color="primary"
            variant="text"
            onClick={toggleRuleDrawer}
          >
            <Typography>Add Rule</Typography>
          </CustomButton>
        )}
        {!isDefault && (
          <IconButton onClick={() => handleQuestionAction("duplicate")}>
            <img src={CopyIcon} style={{ height: "20px" }} alt="upload" />
          </IconButton>
        )}
        {!isDefault && (
          <IconButton onClick={() => handleQuestionAction("delete")}>
            <img src={TrashIcon} style={{ height: "20px" }} alt="upload" />
          </IconButton>
        )}
        <CustomButton
          style={{ textTransform: "none", marginRight: 10 }}
          size="small"
          color="primary"
          variant="text"
          onClick={() => {
            handleQuestionSave(null, false);
            // setSelectedQues(null);
          }}
        >
          <Typography>Cancel</Typography>
        </CustomButton>
      </Box>

      {openRuleDrawer && (
        <AddRuleDrawer
          open={openRuleDrawer}
          handleClose={toggleRuleDrawer}
          setSelectedQues={setSelectedQues}
          selectedQues={selectedQues}
          usingQues={usingQues}
          selectedSection={selectedSection}
        />
      )}
    </Box>
  );
};
export default QuestionsActions;
