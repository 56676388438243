import { useCallback, useEffect, useState } from "react";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Pagination from "@material-ui/lab/Pagination";

// from redux
import { useDispatch, useSelector } from "react-redux";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

import CustomTextfield from "../../../UI/textfield/textfield";
import { setSurveyPage } from "../../../../redux/actions/riskdashboard/riskDashboardActions";
import { SET_SEARCH_SURVEYS_BY_NAME } from "../../../../constants/brmConstants";
import { gql_get_surveys_by_name } from "../../../../redux/actions/vendorActions/vendorAction";
import debounce from "lodash/debounce";

// styles
const useStyles = makeStyles(() => ({
  createButton: {
    textTransform: "none",
    display: "flex",
    float: "right",
    padding: "8px 12px 8px 12px",
    color: "#fff",
    borderRadius: "8px",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  headerIcon: { color: "#fff", paddingRight: "5px" },
}));

// ----------------- Main Render -----------------
const RiskSurveyTop = ({ handleDrawer }) => {
  // from redux
  const { surveyData, surveyPaginationPage, totalSurveys } = useSelector(
    (state) => state.riskDashboard
  );
  const { bankId } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  // pagination states
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [startingAfter, setStartingAfter] = useState(
    surveyPaginationPage * rowsPerPage - rowsPerPage
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState();

  // pagination function
  const handleChangePage = (event, value) => {
    // setStartingAfter(value * rowsPerPage - rowsPerPage);
    dispatch(setSurveyPage(value));
    setCurrentPage(value); // Update the current page
  };

  const fetchSearchedSurveys = useCallback(
    debounce((query) => {
      if (query !== "") {
        dispatch(gql_get_surveys_by_name(bankId, query));
      }
      if (!query) {
        dispatch({
          type: SET_SEARCH_SURVEYS_BY_NAME,
          payload: { query: "", surveyRes: [] },
        });
      }
    }, 500),
    []
  );

  const handleSearchSurveys = (query) => {
    fetchSearchedSurveys(query);
  };

  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 5,
      }}
    >
      <Typography
        style={{
          fontWeight: 600,
          fontSize: "16px",
          marginRight: "auto",
          color: "#15314E",
        }}
      >
        Survey Section
      </Typography>

      {/* Search Component */}
      <div style={{ width: "30%", marginRight: 15 }}>
        <CustomTextfield
          name="Search"
          variant="outlined"
          fullWidth
          size="small"
          type="text"
          label="Search"
          value={search}
          onChange={(e) => {
            handleSearchSurveys(e.target.value);
            setSearch(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          //   value={}
        />
      </div>

      {/* Create Survey Button */}
      <Button
        onClick={() => {
          handleDrawer();
        }}
        className={classes.createButton}
        size="small"
      >
        <AddIcon
          className={classes.headerIcon}
          color="primary"
          variant="outlined"
        />
        Create Survey
      </Button>

      {/* Pagination */}
      <Pagination
        style={{ marginLeft: 10 }}
        count={Math.ceil(totalSurveys / 5)}
        page={currentPage}
        // siblingCount={2}
        onChange={handleChangePage}
        variant="outlined"
        color="primary"
        size="small"
      />
    </Box>
  );
};

export default RiskSurveyTop;
