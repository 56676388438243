import { SET_VENDOR_DASHBOARD_DATA } from "../../../constants/brmConstants";

const initial_state = {
  vendorCount: 0,
};

const vendorDashboardReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_VENDOR_DASHBOARD_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default vendorDashboardReducer;
