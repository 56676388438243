import {
  SET_BRM_MAIN_TAB_NO,
  SET_BRM_SUB_TAB_NO,
  SET_MEMBERS,
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_DETAILS,
  SET_SECTOR_INDUSTRIES,
  SET_SELECTED_SECTOR_INDUSTRIES,
  SET_DECLARATION_TAB_NO,
  SET_CONTROVERSY_TAB_NO,
  SET_PROFILE_TAB_NO,
  SET_COUNTRIES,
  SET_ADD_VENDOR,
  SET_COMPANY_DOCUMENTS,
  SET_UPDATE_ORGS_LIST,
  SET_ADD_STAKEHOLDER,
  SET_VENDOR_BASIC_DETAILS,
  SET_SHOW_SURVEYS,
  SET_SHOW_SELECTED_SURVEY,
  SET_BANK_SHARED_SURVEY,
  SET_SELECTED_BANK_DETAILS_FOR_VA,
  SET_VA_BANKS_LIST,
  SET_REQUESTED_FILES,
  SET_VENDORS_BY_ISIN,
  SET_SEARCH_COMPANIES_BY_NAME,
  SET_SEARCH_SURVEYS_BY_NAME,
} from "../../../constants/brmConstants";
import { SET_SURVEY_QUESTIONS } from "../../../constants/corporateSurveyConstants";
import {
  dummyIndustries,
  vendorCountries,
} from "../../../utils/riskUtils/riskUtils";
const dummy = JSON.parse(JSON.stringify(dummyIndustries));
const initial_state = {
  brmMainTabNo: 0,
  brmSubTabNo: 0,
  declarationTabNo: 0,
  controversyTabNo: 0,
  profileTabNo: 0,
  sectorIndustries: dummy || [],
  organizationDetails: [], //vendorDetails
  vendorBasicDetails: [],
  vendorsByIsin: [],
  companiesByName: [],
  companyQuery: "",
  members: [],
  // organizationsList: vendorOrgs || "",
  organizationsList: [],
  showSurveys: [],
  showSelectedSurvey: {},
  surveyQuestions: [],

  surveysByName: [],
  surveyQuery: "",

  vaBanksList: [],
  bankSharedSurvey: [],
  selectedBankDetailsForVa: {},

  requestedFiles: [],

  totalOrganizations: 0,
  countries: vendorCountries || [],

  companyDocuments: [],
};

export default function brmReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_BRM_MAIN_TAB_NO:
      return (state = { ...state, brmMainTabNo: action.payload });
    case SET_COUNTRIES:
      return (state = { ...state, countries: action.payload });
    case SET_BRM_SUB_TAB_NO:
      return (state = { ...state, brmSubTabNo: action.payload });
    case SET_DECLARATION_TAB_NO:
      return (state = { ...state, declarationTabNo: action.payload });
    case SET_CONTROVERSY_TAB_NO:
      return (state = { ...state, controversyTabNo: action.payload });
    case SET_PROFILE_TAB_NO:
      return (state = { ...state, profileTabNo: action.payload });
    case SET_SECTOR_INDUSTRIES:
      return (state = { ...state, sectorIndustries: action.payload });
    case SET_SELECTED_SECTOR_INDUSTRIES:
      return (state = { ...state, selectedSectorIndustries: action.payload });
    case SET_ORGANIZATION_DETAILS:
      return (state = { ...state, organizationDetails: action.payload });
    case SET_MEMBERS:
      return (state = { ...state, members: action.payload });
    case SET_ADD_STAKEHOLDER:
      return (state = {
        ...state,
        members: [action.payload, ...state.members],
      });
    case SET_ORGANIZATIONS_LIST:
      return (state = {
        ...state,
        organizationsList: action.payload,
        totalOrganizations: action.total,
      });
    case SET_UPDATE_ORGS_LIST:
      return (state = { ...state, organizationsList: action.payload });

    case SET_VENDOR_BASIC_DETAILS:
      return { ...state, vendorBasicDetails: action.payload };

    case SET_VENDORS_BY_ISIN:
      return { ...state, vendorsByIsin: action.payload };

    case SET_SEARCH_COMPANIES_BY_NAME:
      return (state = {
        ...state,
        companiesByName: action.payload?.companyRes,
        companyQuery: action.payload?.query,
      });

    case SET_ADD_VENDOR:
      return {
        ...state,
        organizationsList: [action.payload, ...state.organizationsList],
      };
    case SET_SURVEY_QUESTIONS:
      return (state = { ...state, surveyQuestions: action.payload });

    case SET_SHOW_SURVEYS:
      return (state = { ...state, showSurveys: action.payload });

    case SET_SEARCH_SURVEYS_BY_NAME:
      return {
        ...state,
        surveysByName: action.payload?.surveyRes,
        surveyQuery: action.payload?.query,
      };

    case SET_SHOW_SELECTED_SURVEY:
      return (state = { ...state, showSelectedSurvey: action.payload });

    case SET_COMPANY_DOCUMENTS:
      return {
        ...state,
        companyDocuments: [action.payload, ...state.companyDocuments],
      };

    case SET_VA_BANKS_LIST:
      return {
        ...state,
        vaBanksList: action.payload,
      };

    case SET_SELECTED_BANK_DETAILS_FOR_VA:
      return { ...state, selectedBankDetailsForVa: action.payload };

    case SET_BANK_SHARED_SURVEY:
      return { ...state, bankSharedSurvey: action.payload };

    case SET_REQUESTED_FILES:
      return { ...state, requestedFiles: action.payload };

    default:
      return state;
  }
}
