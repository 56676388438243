import React from "react";


import { Box, Typography } from "@material-ui/core";

function WidgetComponent({ title, icon, children }) {
  return (
    <Box style={{border:"2px solid #EBF1F8", backgroundColor: "#F6F6F6", flex:1, padding:"10px", borderRadius:"8px" }}>
      <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"5px"}}>
        <Box>{children}</Box>
        <Box>{icon}</Box>
      </Box>

      <Box>
        <Typography
          style={{ color: "#505050", fontSize: "16px", fontWeight: 400 }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default WidgetComponent;
