import { useEffect, useState, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";

// icons
import AddIcon from "@material-ui/icons/Add";

import QuestionListHeader from "./comps/QuestionListHeader";
import NoData from "../../../../../../NoData/NoData";
import CustomButton from "../../../../../../../UI/button/button";
import QuestionsLayout from "./QuestionsLayout";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_add_section_question,
  gql_get_section_questions,
  setQuestions,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";

const RightSection = ({ selectedSection }) => {
  // from redux
  const dispatch = useDispatch();
  const { selectedSurveyData, sectionWiseQuestion } = useSelector(
    (state) => state.riskDashboard
  );

  // console.log(selectedSection);

  // states
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [loadedUpTo, setLoadedUpTo] = useState(0); // Track index up to which questions are loaded
  const containerRef = useRef(null);

  //------------------- Inner Functions -------------------

  const handleAddNewQuestion = () => {
    // Dispatch action to add a new question
    const createQuestionInput = {
      answers: [
        { option: "Very dissatisfied", weightage: 1 },
        { option: "Dissatisfied", weightage: 2 },
        { option: "Somewhat dissatisfied", weightage: 3 },
        { option: "Neither satisfied nor dissatisfied", weightage: 4 },
        { option: "Somewhat satisfied", weightage: 5 },
        { option: "Satisfied", weightage: 6 },
        { option: "Very satisfied", weightage: 7 },
      ],
      order: 1,
      question: "Please provide a question",
      questionType: "SATISFACTION",
      sectionId: selectedSection?._id,
      version: selectedSurveyData?.latestVersion,
      weightage: 2,
    };

    dispatch(
      gql_add_section_question(
        createQuestionInput,
        selectedSurveyData?._id,
        "add"
      )
    );
  };

  const loadMoreQuestions = () => {
    // Calculate the index to slice from sectionWiseQuestion
    const startIndex = loadedUpTo;
    const endIndex = startIndex + 10;

    // Check if startIndex exceeds the length of sectionWiseQuestion
    if (startIndex >= sectionWiseQuestion.length) {
      return; // No more questions to load
    }

    // Slice questions from sectionWiseQuestion based on calculated indexes
    const newQuestions = sectionWiseQuestion.slice(startIndex, endIndex);

    // Update displayedQuestions state by appending newQuestions
    setDisplayedQuestions((prevQuestions) => [
      ...prevQuestions,
      ...newQuestions,
    ]);

    // Update loadedUpTo state
    setLoadedUpTo(endIndex);
  };

  // ------------------------------------------------------

  // ------------------- useEffects() -------------------

  useEffect(() => {
    // Load initial questions
    if (selectedSection?._id) {
      dispatch(gql_get_section_questions(selectedSection?._id));
    }
    return () => {
      setDisplayedQuestions([]);
      dispatch(setQuestions([]));
      setLoadedUpTo(0); // Reset loadedUpTo when component unmounts
    };
  }, [selectedSection?._id]);

  useEffect(() => {
    // Set displayedQuestions to the first 10 items when sectionWiseQuestion updates
    if (sectionWiseQuestion.length > 0) {
      setDisplayedQuestions(sectionWiseQuestion.slice(0, 10));
      setLoadedUpTo(10); // Set loadedUpTo to 10 when sectionWiseQuestion updates
    }
  }, [sectionWiseQuestion]);

  useEffect(() => {
    // Detect when we scroll to the bottom
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >=
          containerRef.current.scrollHeight
      ) {
        loadMoreQuestions();
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [displayedQuestions, loadedUpTo]);

  // -----------------------------------------------------

  return (
    <Grid
      item
      md={10}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "60vh",
        overflow: "scroll",
        padding: "5px 15px",
      }}
      ref={containerRef}
    >
      <QuestionListHeader selectedSection={selectedSection} />
      {!selectedSection?._id ? (
        // when section is not selected
        <NoData message={"Please select a section"} />
      ) : sectionWiseQuestion?.length > 0 ? (
        sectionWiseQuestion?.map((question) => (
          <QuestionsLayout
            key={question?._id}
            question={question}
            isDefault={selectedSection?.default}
            selectedSection={selectedSection}
            setDisplayedQuestions={setDisplayedQuestions}
          />
        ))
      ) : (
        // when questions are not available
        <NoData message={"No Available Questions"} />
      )}
      {!selectedSection?.default && selectedSection && (
        <CustomButton
          style={{
            textTransform: "none",
            marginRight: 10,
            alignSelf: "center",
            borderRadius: "8px",
          }}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleAddNewQuestion}
        >
          <AddIcon />
          <Typography>Add Question</Typography>
        </CustomButton>
      )}
    </Grid>
  );
};

export default RightSection;
